// Define the English content for the common components

import { PAGE_ALERTS_EN } from '../pageAlerts/pageAlert_en';

export const COMMON_CONTENT_EN = {
  alerts: {
    emailAddress: 'Please enter an email address.',
    invalidemailAddress: 'Please enter an valid email address.',
    emailSent: 'Email sent!',
    emailSending: 'Email Sending',
    emailFailed: 'There was a problem and the email was not sent.  Please try again later.',
    lblErrorMbrMsg: 'Please select a member to assign this PCP',
    lblErrorPcpMsg: 'Please select a PCP ID.',
    lblErrorChangeReasonPcpMsg: 'Please select a reason.',
    lblErrorNoPcpAssignedMsg: 'Unable to remove Primary Care Physician, as the selected member does not have a PCP listed',
    coverageDetailErrorMsg: 'Selected member dosent have any coverage. So please select another member',
    cahmoDetailsPageError: 'This care provider is in-network for the plan you selected, but the plan is only offered in certain areas of California.',
    cahmoSavPageNavigationMsg: 'Find out if this plan is offered in your area.',
    serviceAreaValidationAlert: {
      msgText: "Important: The plan you selected isn't available in that location.",
      anotherPlan: {
        id: 'anotherPlan',
        msgText: 'Select Another Plan.'
      }
    }
  },
  assignPcpCmpContent: {
    title: 'Assign as Primary Care Physician',
    stepNavStatus: '{STEP} of {TOTAL}',
    stepNavStatusAria: 'Step {STEP} of {TOTAL}',
    info1: 'Choosing a PCP is an important personal decision. And when you find the right PCP, that Doctor (or other health professional) can help make a difference in your health.',
    newPCP: 'New PCP',
    selectPcpId_multi: 'Select a PCP ID',
    selectPcpId_mono: 'PCP ID',
    lblToSelectMultiplePcp: "This doctor has more than one PCP ID. Please contact the doctor's office if you need help choosing which PCP ID to select.",
    lblPcpID: 'PCP ID: ',
    next: 'Next',
    previous: 'Previous',
    assignPcp: 'Assign as PCP',
    partof: ', part of ',
    cancel: 'Cancel',
    complete: 'Complete',
    selectPlan: 'Select Plan',
    info2_B: "We see you've already selected a health plan for the next year. Please let us know if you would like this new PCP to be assigned for this year's plan, next year's plan, or both.",
    info2_A: 'The following plan is auto selected to assign this new PCP.',
    startingDate: 'Starting {DATE}',
    selectMember: 'Select Member',
    info3: 'Which member is this new PCP for?',
    selectReason: 'Select a Reason',
    info4: 'Please select a reason for this update. Choose "Other" if the reason is not listed.',
    info5: 'Review the information below. If you need to edit this information, please use the "Previous" button.',
    member: 'Member',
    plan: 'Plan',
    reasonForChange: 'Reason for Change',
    activeStatus: '(Active)',
    futureStatus: '(Starting {DATE})',
    planName_A: '{PLAN_NAME} (Active)',
    planName_F: '{PLAN_NAME} (Starting {DATE})',
    both: 'Both',
    pcpNotReqAlertMsg_A:
      "{PLAN_NAME} doesn't include the ability to assign a PCP. Your PCP assignment will be made for your current plan only. If you have any questions, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
    pcpNotReqAlertMsg_F:
      "{PLAN_NAME} doesn't include the ability to assign a PCP. Your PCP assignment will be made for your future plan only. If you have any questions, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
    errorMsg: "Sorry, looks like something isn't working. Please give us some time to fix it, then try again. If the problem is still there, please contact  Technical Support.",
    relationship: {
      subscriber: '(Subscriber)',
      dependent: '(Dependent)',
      spouse: '(Spouse)'
    },
    memWarning_singular: 'This member is not on the plan selected. If you need to change the plan, use the "Previous" button.',
    memWarning_plural: 'These members are not on the plan selected. If you need to change the plan, use the "Previous" button.',
    currentPcp: 'Current PCP:',
    selectAReason: 'Reason for Update',
    chooseOne: 'Choose one',
    validateApiErrors: {
      default:
        "Our system is telling us that {DOCTOR NAME} can't be assigned as the PCP for {MEMBER NAME}. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>"
    },
    otherReasonCd: {
      default: '99',
      NASCO: '249'
    },
    slideoutMsgCmpContent: {
      close: 'Close',
      title: "We're Having a Problem Assigning This Primary Care Physician",
      default: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
      errNoPcpsInAffiliation: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
      errPcpMbrEffDtPastContractEndDt:
        "We won't be able to assign {DOCTOR NAME} as the PCP for {MEMBER NAME} because your plan ends on {TERMINATION DATE}. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
      errMbrAgeNotInRange:
        "Our system is telling us that {DOCTOR NAME} doesn't treat patients in {MEMBER NAME}'s age group. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
      title_errPcpMbrEffDtPastContractEndDt: "Can't assign PCP before plan ends"
    },
    modifyApiContent: {
      successWithEffectiveDate: [
        {
          id: 'pcp-success-with-effective-date',
          orderSequence: '1',
          displayText:
            "We'll assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please note, this change won't take effect until {EFFECTIVE DATE}. If you need this change before then, please {linkLabel}.",
          ariaLabel:
            "We'll assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please note, this change won't take effect until {EFFECTIVE DATE}. If you need this change before then, please contact us",
          isAnchorText: true,
          inAppNavigation: true,
          mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
          navigationUrl: '/member/contact-us',
          linkLabel: 'contact us',
          targetAttribute: '_blank',
          linkDataAnalyticsTag: 'contactUsSuccessPcpFindCare'
        }
      ],
      successWithoutEffectiveDate: [
        {
          id: 'pcp-success-without-effective-date',
          orderSequence: '1',
          displayText:
            "We'll assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please note, it may take up to two weeks to update our systems with this change. If you need this change before then, please {linkLabel}.",
          ariaLabel:
            "We'll assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please note, it may take up to two weeks to update our systems with this change. If you need this change before then, please contact us",
          isAnchorText: true,
          inAppNavigation: true,
          mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
          navigationUrl: '/member/contact-us',
          linkLabel: 'contact us',
          targetAttribute: '_blank',
          linkDataAnalyticsTag: 'contactUsSuccessPcpFindCare'
        }
      ],
      defaultFailureMsg: [
        {
          id: 'pcp-default-message',
          orderSequence: '1',
          displayText: "There was a problem and we couldn't assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please try again later. Error Code: {ERROR CODE}",
          ariaLabel: "There was a problem and we couldn't assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please try again later.",
          isAnchorText: false,
          inAppNavigation: false,
          mobileInAppNavigationUrl: '',
          navigationUrl: '',
          linkLabel: '',
          targetAttribute: ''
        }
      ],
      failureMsgs: [
        {
          codes:
            'ERR1001,ERR1004,ERR1006,ERR2011,ERR2012,ERR2013,ERR2014,ERR2016,ERR2017,ERR2018,ERR2019,ERR3004,ERR3005,ERR3006,ERR3007,ERR3029,ERR3030,ERR3032,ERR3036,ERR3037,ERR3046,ERR3047,ERR3050,ERR3051,ERR3052,ERR3053,ERR3054,ERR3055,ERR3056,ERR3057,ERR3058,ERR3059,ERR3062,ERR3063,ERR3067,ERR3068,ERR3069,ERR3070,ERR3071,ERR3072,ERR3073,ERR3074,ERR3075,ERR3076,ERR3077,ERR3079,ERR3080,ERR3081,ERR3082,ERR3083,ERR3084,ERR3085,ERR3087,ERR3088,ERR3089,ERR3091,ERR3092,ERR3101,ERR3103,ERR3104,ERR3105,ERR3106,ERR3107,ERR3108,ERR3109,ERR4001,ERR4002,ERR4003,ERR4004,ERR4005,ERR4007,ERR4008,ERR4009,ERR4010,ERR4011,ERR4021,00001003,00001023,00001004,00001022,3001,3002,00001024,ERR3110,ERR3112,ERR5003,ERR5001',
          message: [
            {
              id: 'errorCodeSet1',
              orderSequence: '1',
              displayText: "There was a problem and we couldn't change the Primary Care Physician for {MEMBER NAME}. Please {linkLabel} for help. Error Code: {ERROR CODE}",
              ariaLabel: "There was a problem and we couldn't change the Primary Care Physician for {MEMBER NAME}. Please contact Member Services for help.",
              isAnchorText: true,
              inAppNavigation: true,
              mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
              navigationUrl: '/member/contact-us',
              linkLabel: 'contact Member Services',
              targetAttribute: '_blank',
              linkDataAnalyticsTag: 'contactUsFailurePcpFindCare'
            }
          ]
        },
        {
          codes:
            'ERR1002,ERR1003,ERR1005,ERR2001,ERR2002,ERR2003,ERR2004,ERR2005,ERR2006,ERR2007,ERR2008,ERR2009,ERR2010,ERR2014,ERR2015,ERR2016,ERR2020,ERR2022,ERR3001,ERR3002,ERR3003,ERR3008,ERR3009,ERR3010,ERR3011,ERR3012,ERR3013,ERR3014,ERR3015,ERR3016,ERR3017,ERR3018,ERR3019,ERR3020,ERR3022,ERR3023,ERR3024,ERR3025,ERR3026,ERR3027,ERR3028,ERR3031,ERR3033,ERR3034,ERR3035,ERR3038,ERR3039,ERR3040,ERR3041,ERR3042,ERR3043,ERR3044,ERR3045,ERR3048,ERR3049,ERR3050,ERR3051,ERR3060,ERR3061,ERR3064,ERR3065,ERR3066,ERR3086,ERR3090,ERR3093,ERR3094,ERR3095,ERR3096,ERR3097,ERR3098,ERR3099,ERR3100,ERR3102,ERR4012,ERR4013,ERR4014,ERR4015,ERR4016,ERR4017,ERR4018,ERR4019,ERR4020,5029,00001025,00001026,00001027',
          message: [
            {
              id: 'errorCodeSet2',
              orderSequence: '1',
              displayText: "We couldn't assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please select another physician or {linkLabel} for help. Error Code: {ERROR CODE}",
              ariaLabel: "We couldn't assign {DOCTOR NAME} as the Primary Care Physician for {MEMBER NAME}. Please select another physician or contact Member Services for help.",
              isAnchorText: true,
              inAppNavigation: true,
              mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
              navigationUrl: '/member/contact-us',
              linkLabel: 'contact Member Services',
              targetAttribute: '_blank',
              linkDataAnalyticsTag: 'contactUsFailurePcpFindCare'
            }
          ]
        },
        {
          codes: 'ERR2021,ERR3021,1001,503,504',
          message: [
            {
              id: 'errorCodeSet3',
              orderSequence: '1',
              displayText:
                "There was a problem and we couldn't change the Primary Care Physician for {MEMBER NAME}. This should be a temporary problem, so please try again later. Error Code: {ERROR CODE}",
              ariaLabel: "There was a problem and we couldn't change the Primary Care Physician for {MEMBER NAME}. This should be a temporary problem, so please try again later.",
              isAnchorText: false,
              inAppNavigation: false,
              mobileInAppNavigationUrl: '',
              navigationUrl: '',
              linkLabel: '',
              targetAttribute: ''
            }
          ]
        }
      ]
    },
    pcpReasonsList: {
      default: [
        {
          label: 'Member changed address',
          value: '12'
        },
        {
          label: 'Provider too far',
          value: '13'
        },
        {
          label: 'Provider moved',
          value: '34'
        },
        {
          label: 'Provider only treats children 0-12',
          value: '26'
        },
        {
          label: 'Provider terminated with plan',
          value: '23'
        },
        {
          label: 'Open Enrollment',
          value: '20'
        },
        {
          label: 'Dissatisfied with quality of care',
          value: '14'
        },
        {
          label: 'Dissatisfied with the provider',
          value: '16'
        },
        {
          label: 'Dissatisfied with the office staff',
          value: '15'
        },
        {
          label: 'Patient age inappropriate (too old, too young)',
          value: '18'
        },
        {
          label: 'Long wait',
          value: '31'
        },
        {
          label: 'Long wait for referral to specialist',
          value: '33'
        },
        {
          label: 'Never seen/Did not accept assigned provider',
          value: '11'
        },
        {
          label: 'Provider no longer available',
          value: '19'
        },
        {
          label: 'Provider/Office requesting the member to be changed',
          value: '21'
        },
        {
          label: 'Provider not accepting new members at this time',
          value: '22'
        },
        {
          label: 'Provider is deceased',
          value: '25'
        },
        {
          label: 'Member request',
          value: '30'
        },
        {
          label: 'Provider only treats females over the age 12',
          value: '27'
        },
        {
          label: 'Other',
          value: '99'
        }
      ],
      NASCO: [
        {
          label: 'Provider specialty no longer considered a PCP',
          value: '238'
        },
        {
          label: 'Physician deceased',
          value: '239'
        },
        {
          label: 'PCP left network',
          value: '247'
        },
        {
          label: 'PCP found closer to home',
          value: '248'
        },
        {
          label: 'Age inappropriate',
          value: '250'
        },
        {
          label: 'Dissatisfaction with PCP',
          value: '251'
        },
        {
          label: 'Other',
          value: '249'
        }
      ],
      CS90: [
        {
          label: 'Dissatisfaction with PCP',
          value: 'DP'
        },
        {
          label: 'PCP misdiagnose quality',
          value: 'PF'
        },
        {
          label: 'Dissatisfaction with service',
          value: 'DS'
        },
        {
          label: 'PCP manner/attitude',
          value: 'PG'
        },
        {
          label: 'Hours not convenient',
          value: 'HV'
        },
        {
          label: 'Office staff manner/attitude',
          value: 'PH'
        },
        {
          label: 'Language barrier',
          value: 'LB'
        },
        {
          label: 'PCP office lacks cleanliness',
          value: 'PI'
        },
        {
          label: 'Locations of office',
          value: 'LO'
        },
        {
          label: 'PCP refuse to provide care',
          value: 'PJ'
        },
        {
          label: 'PCP left area',
          value: 'MO'
        },
        {
          label: 'PCP bills member, not carrier',
          value: 'PK'
        },
        {
          label: 'PCP not accepting new patients',
          value: 'NA'
        },
        {
          label: 'PCP found closer to home',
          value: 'PO'
        },
        {
          label: 'No PCP on-calls',
          value: 'NC'
        },
        {
          label: 'PCP recommended by friend',
          value: 'PQ'
        },
        {
          label: 'Office waiting time',
          value: 'OW'
        },
        {
          label: 'PCP refused to refer',
          value: 'PV'
        },
        {
          label: 'PCP unable to schedule appointments',
          value: 'PC'
        },
        {
          label: 'Quality of care',
          value: 'QC'
        },
        {
          label: 'PCP not available after hours',
          value: 'PE'
        },
        {
          label: 'Inability to treat member',
          value: 'UT'
        },
        {
          label: 'PCP left network',
          value: 'PN'
        },
        {
          label: 'Prefer another physician',
          value: 'DP'
        }
      ],
      MWMEDSYS: [
        {
          label: 'Age Restrictions',
          value: 'AGER'
        },
        {
          label: 'Provider Personality Conflict',
          value: 'CONF'
        },
        {
          label: 'Deceased',
          value: 'DECE'
        },
        {
          label: 'Doctor Terminated Participation',
          value: 'DRTR'
        },
        {
          label: 'Office Environment',
          value: 'ENVR'
        },
        {
          label: 'Provider Not Accepting New Patients',
          value: 'FULL'
        },
        {
          label: 'Incorrect PCP',
          value: 'INCO'
        },
        {
          label: 'Provider Left Partnership',
          value: 'LEFT'
        },
        {
          label: 'Member Request',
          value: 'MBRQ'
        },
        {
          label: 'Quality of Non-Medical Care',
          value: 'MEQA'
        },
        {
          label: 'Mileage Access Issues ',
          value: 'MILE'
        },
        {
          label: 'Member Moved Out of Area',
          value: 'MOOA'
        },
        {
          label: 'Quality of Medical Care',
          value: 'NOQA'
        },
        {
          label: "Wouldn't Refer",
          value: 'NORF'
        },
        {
          label: 'PCP Not Selected',
          value: 'NSEL'
        },
        {
          label: 'Provider Moved Out of Area',
          value: 'POOA'
        },
        {
          label: 'Provider Retired',
          value: 'PRET'
        },
        {
          label: 'Provider Request',
          value: 'PRRQ'
        },
        {
          label: "Member's Reason Not Available",
          value: 'UNKN'
        }
      ]
    },
    btnDataAnalytics: {
      planRadioBtn_A: 'currentYrSelectPlanPcpFindCare',
      planRadioBtn_F: 'futureYrSelectPlanPcpFindCare',
      planRadioBtn_B: 'bothSelectPlanPcpFindCare',
      selectPlan_nextBtn: 'nextSelectPlanPcpFindCare',
      selectMember_nextBtn: 'nextSelectMemPcpFindCare',
      selectMember_prevBtn: 'previousSelectMemPcpFindCare',
      selectPcpId_nextBtn: 'nextSelectPcpFindCare',
      selectPcpId_prevBtn: 'previousSelectPcpFindCare',
      selectReason_nextBtn: 'nextSelectReasonPcpFindCare',
      selectReason_prevBtn: 'previousSelectReasonPcpFindCare',
      reviewSelections_prevBtn: 'previousReviewAssignPcpFindCare'
    }
  },
  caHmoPanelComponent: {
    title: 'Primary Care Physician Outside of Your Medical Group',
    close: 'Close',
    cancel: 'Cancel',
    continue: 'Continue',
    disclaimer: 'If you want us to assign a PCP who is not in your current medical group, please keep in mind that you can only change medical groups when:',
    conditions: [
      {
        option: 'You work or live in the area where your plan offers coverage and,'
      },
      {
        option: "You're not receiving care continuously or in a series of treatments (also known as a course of treatment). Examples include:",
        examples: [
          'Short-term (acute), inpatient care in a hospital or nursing facility.',
          'Radiation or chemotherapy.',
          'Preparing for a transplant.',
          'Pregnancy in the third trimester (pregnancy has reached the 27th week).'
        ]
      }
    ],
    contactUs: 'If you have any questions, please <a target="_blank" href="/member/contact-us" data-analytics="contactSerPcpOutModFindCare" >contact Member Services</a>.'
  },
  labels: {
    btnCancel: 'Cancel',
    btnClose: 'Close',
    btnSent: 'Send',
    btnOk: 'OK',
    printPdf: 'Print page details',
    emailpdf: 'Email page details',
    emaillbl: "Enter Recipient's e-mail Address:",
    defaultEmailText: 'Enter email address',
    closeModal: ' button, select to close the modal',
    lblNotAcceptingNewPatients: 'Not Accepting New Patients',
    lblNotAcceptingNewPatientsDesc:
      "Our system is telling us that {providerName} is not accepting new patients. If you are an existing patient, or if you think this information is wrong, please contact <a target='_blank' href='/member/contact-us' style='text-decoration:none'> Member Services</a>.",
    lblNotAcceptingNewPatientsDesc2: 'You can filter your search results to ',
    lblNotAcceptingNewPatientsDesc3: 'only show care providers who are accepting new patients.',
    sendEmailModal: ' button, select to send the email',
    myPlan: 'My Plan',
    dismiss: 'Dismiss alert',
    zipcodeLabel: 'Select Zipcode',
    countyLabel: 'Select County'
  },
  serviceAreaValidationComponent: {
    labels: {
      back: 'Back to Find Care',
      continue: 'Continue',
      checkboxMsg: 'I understand that this plan is not available in this location, but I wish to continue my search anyway.',
      information: 'We Need More Information',
      informationSummary:
        "The plan you selected is only Offered in certain areas of California.Please add more location information so we can determine if this plan is offered in the area you're searching.",
      zipcode: 'Zipcode',
      county: 'County',
      zipcodeLabel: 'Select Zipcode',
      countyLabel: 'Select County'
    },
    alerts: {
      serviceAreaValidationAlert: {
        msgText: "Important: The plan you selected isn't available in that location.",
        anotherPlan: {
          id: 'anotherPlan',
          msgText: 'Select Another Plan.'
        }
      }
    }
  },
  freeTextSearchResults: {
    acceptsNewPatients: 'Accepts New patients',
    close: 'Close',
    femaleDoctor: 'Female Doctor',
    maleDoctor: 'Male Doctor',
    name: 'name ',
    npi: 'NPI',
    serveAsPcp: 'Serve as PCP',
    similarSearches: 'Similar Searches',
    viewSimilarSearches: 'View similar searches',
    whoSpeaks: 'Speaks ',
    yourSearch: 'Your search: ',
    feedback: {
      betaVersionExperience: 'What was your favorite aspect of your experience using the beta version?',
      cancel: 'Cancel',
      feedback: 'Feedback for Search Beta',
      feedbackDetails: 'Please tell us what you think about the search beta.',
      feedbackPlaceholder: 'Enter your answer here.',
      improveYourExperience: 'How do you think we could improve your experience?',
      intendedTaskSuccessfully: 'Were you able to complete your intended task successfully?',
      no: 'No',
      remainingCharacters: '{charlength} characters left',
      send: 'Send',
      submitFeedback: 'Beta Feedback',
      yes: 'Yes'
    }
  },
  pageHeader: {
    englishSpanishToggleLabel: 'Español',
    chineseLinkLabel: '寻找护理',
    fadPageTitle: 'Find Care',
    viewAllLabel: 'View All',
    closeModal: 'button, select to close the modal',
    btnCancel: 'Cancel',
    btnContinue: 'Continue',
    btnSave: 'Save',
    lookUpInfo: 'Enter 3 or more characters to start a search. Use the up and down arrow keys to view search suggestions. ',
    btnClose: 'Close',
    btnOk: 'OK',
    tooltipInfo: "These family members aren't covered by the selected plan. Choose “Change Plan” to edit your plan selection.",
    freeTextSearch: {
      wishToTryBeta: 'Try Search Beta',
      returnToClassicSearch: 'Switch Back',
      searchTextHint: 'What kind of care are you looking for?',
      suggestionFailure: 'There was a problem. Please try again.',
      noSuggestionCriteriaFound: 'Please add more detail to your search.',
      searchExample: "<strong>Search Example</strong>: I'm looking for a female doctor to treat my migraines",
      moreExamples: {
        moreExamplesLink: 'More Examples',
        heading: 'Search Examples',
        examplesList: [
          {
            heading: 'Get as detailed as you want:',
            examples: [
              "I'm looking for a doctor.",
              "I'm looking for a female doctor.",
              "I'm looking for a female doctor to treat my migraines.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish and is accepting new patients.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish, is accepting new patients, and is able to serve as a PCP.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish, is accepting new patients, and is able to serve as a PCP, and is in Richmond, Virginia"
            ]
          },
          {
            heading: 'Other search examples:',
            examples: ['Orthopedic', 'Doctor John W.Smith', 'PCP', '<i>(Enter a 10-digit NPI number)</i> 1234567890', 'Doctor Miller in Los Angeles, CA']
          },
          {
            heading: 'Add a search location:',
            examples: ['Richmond, VA', 'Henrico County', 'Virginia', '23173']
          }
        ]
      },
      assistanceHelpText: 'Select to add a suggestion to your search.',

      search: 'Search',
      accessibilityLabelforSearch: 'Use keyboard down arrow to navigate through suggestions and use enter key or space bar to select a suggestion. Or, press enter key now to search'
    },
    searchCriteria: {
      labels: {
        lookUpInfo: 'Enter 3 or more characters to start a search. Use the up and down arrow keys to view search suggestions. ',
        planUnknown: 'Plan/Network Unknown',
        searchAll: 'Search All',
        searchSpecialtyName: 'Search by doctor (name or specialty), hospital, procedure, and more',
        careTeamFor: 'Care Team for {NAME}',
        selectedMemberPlan: '{PLAN}',
        selectedPlan: '{PLAN}',
        findingCarePrefix: 'Finding Care in ID Number Prefix {{PREFIX}}',
        noResultsMsg: 'Nothing was found for <span class="ant-font-b">{TERM}</span>.” (Tip: If you can’t find a care provider by name, this could mean they’re not in your plan’s network.)',
        notAnExactMatch:
          'We couldn\'t find an exact match for "<span class="ant-font-b">{TERM}</span>." Here are the closest matches. (Tip: If you can’t find a care provider by name, this could mean they’re not in your plan’s network.)',
        defaultSearchMsg:
          'We couldn\'t find an exact match for "<span class="ant-font-b">{TERM}</span>", Here are the closest matches.(Tip: If you can\'t find a care provider by name, this could mean they\'re not in your plan\'s network.)',
        search: 'Search by doctor (name or specialty),',
        specialties: 'Specialties',
        specialtySuggestion: 'Specialty',
        providerName: 'Provider Name',
        aoe: 'Area Of Expertise',
        procedures: 'By Procedure',
        officeService: 'Office Service',
        searchBy: 'Search by',
        searchSpecialtyProcedureName: 'Search by doctor (name or specialty), hospital, procedure, and more',
        searchLookUpBy: 'Search for care by {CATEGORY}',
        procedure: 'Procedure',
        specialty: 'specialty',
        county: 'County',
        serving: 'Serving',
        cities: 'Cities',
        counties: 'Counties',
        providersInNetwork: "Care Providers in Your Plan's Network",
        nationalProviderIdentifier: 'National Provider Identifier (NPI)',
        licenseNumber: 'License Number',
        name: 'name',
        showNotInNtwrkProvsLink: 'Search out-of-network care providers for {searchTerm}',
        showMore: 'Show More',
        showLess: 'Show Less',
        seeAll: 'View All',
        showMoreInfo: 'show more link will list all the matches ',
        showLessInfo: 'show less link will list the top 5 ',
        ariaLabelForViewAllProviders: 'Click on View All for more Provider Names',
        needHelp: 'Need Help?',
        liveChat: 'Chat with a Live Agent',
        noResultsAlert: '<span class="ant-font-b">Nothing found for "{TERM}</span>". Need help?',
        ariaLabelForProviderSearch: 'select enter for procedure based provider search',
        ariaLabelForSpecialtySearch: 'select enter for specialty based provider search',
        ariaLabelForAreaOfExpertiseSearch: 'select enter for area of expertise based provider search',
        ariaLabelForOfficeServiceSearch: 'select enter for office service based provider search',
        ariaLabelForProviderDetails: 'select enter for provider details',
        miles: 'miles away',
        medicalCode: 'Medical Code',
        billingCode: 'Billing Code',
        medicalCodeToolTipDesc:
          'Medical codes are used to help determine claim and payment amounts. Keep in mind that a medical procedure or service may include multiple codes. Searching by procedure instead of medical code will give you the best overall cost estimate.',
        planSelected: 'I want to search this plan/network:',
        OONInfo1: 'Not finding who you’re looking for? Try searching outside your plan network for ',
        OONInfo2: 'out-of-network providers.',
        primaryCarePhysician: 'Primary Care Physician (PCP)',
        pcpDescription: 'Doctors, nurse practitioners, and others who can serve as your PCP.',
        primaryCareTerms: ['PCP', 'PRIMARY'],
        bhCrisisMsg: {
          text: 'Help is available. Talk with a crisis counselor 24/7 by dialing <strong>988</strong> or use the ',
          LifeLineText: 'Suicide and Crisis Lifeline Chat. ',
          chatLink: 'https://chat.988lifeline.org/'
        },
        bhNonCrisisMsg: { text: 'Need help finding behavioral health services? ', chatLink: 'https://chat.988lifeline.org/', chatLinkText: ' Use Search Assistant' },
        clearSearch: 'clear search',
        nonBhCrisisMsg: { text: 'Please contact 911, emergency services, or urgent care.' },
        getCareNow: 'Get Care Now',
        getCareNowCard: 'View your options when you need care quickly.'
      },
      cptDataAnalytics: {
        medDropdownMenuSearch: 'medCodeHomeFindCare',
        expandMedCodeHomeFindCare: 'expandMedCodeHomeFindCare',
        collapseMedCodeHomeFindCare: 'collapseMedCodeHomeFindCare',
        showAllMedCodeHomeFindCare: 'showAllMedCodeHomeFindCare',
        showMoreMedCodeHomeFindCare: 'showMoreDropSearchHomeFindCare',
        showLessMedCodeHomeFindCare: 'showLessMedCodeHomeFindCare',
        infoIconMedCodeHomeFindCare: 'infoIconMedCodeHomeFindCare'
      },
      alerts: {
        zipcodeEmptyError: 'Please enter a location.',
        zipcodeError: 'We did not find that location. Please try again.',
        stateZipCodeError: 'State And Zip Code MissMatch',
        invalidError: 'Please enter a valid name.',
        networkUnknown:
          'Sorry, we are not able to retrieve information for one or more of your plan coverages. Please select from one of the available plan coverages being shown in order to complete your search.',
        networkUnknownMsg: "We're having a problem loading your plan/network information.",
        planSelectionMsg: 'Please search by selecting your plan.',
        memberPlanError: "We're having a problem loading your plan/network information. You can either try again later or ",
        memberPlanErrorLink: 'search by selecting your plan',
        primeGroupSearchMsg:
          'These search results are showing care providers in your medical group. If you want to search outside of your medical group, clear the "Medical Group or IPA" filter. You\'ll need a referral from your Primary Care Physician (PCP) before receiving non-emergency services.',
        clinicalPrograms: {
          TP_ATSM_SPCTRM_DSRD: {
            id: 'lnk-atsm-spctrm-dsrd',
            dataAnalytics: 'autismProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Receive support and resources for your family.',
            programName: 'Autism Spectrum Disorder Program'
          },
          TP_BH_HLTH_RSRC_CNTR: {
            id: 'lnk-bh-hlth-rsrc-cntr',
            dataAnalytics: 'behavioralProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: '24/7 support for emotional health issues.',
            programName: 'Behavioral Health Resource Management'
          },
          TP_CSMNGT: {
            id: 'lnk-csmngt',
            dataAnalytics: 'caseProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Care coordination and support after an illness.',
            programName: 'Case Management'
          },
          TP_CNDCR: {
            id: 'lnk-cndcr',
            dataAnalytics: 'conditionProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Support and tools to help manage your condition.',
            programName: 'ConditionCare'
          },
          TP_FTRMM: {
            id: 'lnk-ftrmm',
            dataAnalytics: 'futureProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Resources to help you have a healthy pregnancy.',
            programName: 'Future Moms'
          },
          TP_LHO_LC: {
            id: 'lnk-lho-lc',
            dataAnalytics: 'lactationProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Breastfeeding support from the comfort of home.',
            programName: 'Online Lactation Consultation'
          },
          TP_247NRSLN: {
            id: 'lnk-nrsln',
            dataAnalytics: 'nurselineProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Consult with a registered nurse around the clock.',
            programName: '24/7 Nurseline'
          },
          TP_SSPR_SSO: {
            id: 'lnk-smrtshopper',
            dataAnalytics: 'smartShopperProgramAlertFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'It pays to shop around for your care.',
            programName: 'Smart Shopper'
          },
          TP_BPG: {
            id: 'lnk-bpg',
            dataAnalytics: 'bpgProgramAlertFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Online program to help reduce back and joint pain.',
            programName: 'Back and Joint Pain Guide'
          },
          TP_WBC: {
            id: 'lnk-wbc',
            dataAnalytics: 'wellBeingProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Your coach is here to guide, support, and inspire.',
            programName: 'Well Being Coach'
          },
          TP_DPP: {
            id: 'lnk-dpp',
            dataAnalytics: 'diabetesPreventLinkResultsFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: "Lark's virtual coaching greatly reduces your risk.",
            programName: 'Diabetes Prevention Program'
          }
        },
        infoListDisclaimers: {
          TP_HCS: [
            {
              id: 'hcs-label-text',
              displayText: 'Hearing Care Solutions manages some parts of your hearing benefit.',
              orderSequence: '1',
              ariaLabel: 'Hearing Care Solutions manages some parts of your hearing benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'hcs-link-text',
              displayText: 'Go to the {linkLabel} to find a hearing professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Hearing Care Solutions website to find a hearing professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://hearingcaresolutions.com/anthem/',
              linkLabel: 'Hearing Care Solutions website'
            }
          ],
          TP_DENTAQUEST: [
            {
              id: 'dentaquest-label-text',
              displayText: 'DentaQuest manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'dentaquest-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the DentaQuest website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_OHDENTAQUEST: [
            {
              id: 'ohdentaquest-label-text',
              displayText: 'DentaQuest manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'ohdentaquest-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the DentaQuest website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://dentaquest.com/state-plans/regions/ohio/member-page/find-a-dentist/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_LIBERTY: [
            {
              id: 'liberty-label-text',
              displayText: 'Liberty Dental manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'Liberty Dental manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'liberty-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Liberty Dental website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'Liberty Dental website'
            }
          ],
          TP_MAGELLAN_PHARMACY: [
            {
              id: 'pharmacy-label-text',
              displayText: 'Magellan manages some parts of your Pharmacy benefit.',
              orderSequence: '1',
              ariaLabel: 'Magellan manages some parts of your Pharmacy benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'pharmacy-link-text',
              displayText: 'Go to the {linkLabel} to find a pharmacy in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the pharmcy website to find a pharmacy in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.lamcopbmpharmacy.com',
              linkLabel: 'Magellan website'
            }
          ],
          TP_TN_DENTAQUEST: [
            {
              id: 'tndentaquest-label-text',
              displayText: 'Some of your 2022 dental benefits are managed by DentaQuest. ',
              orderSequence: '1',
              ariaLabel: 'Some of your 2022 dental benefits are managed by DentaQuest.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tndentaquest-link-text',
              displayText: 'To find a dental professional in your 2022 dental plan, go to the {linkLabel}',
              orderSequence: '2',
              ariaLabel: 'To find a dental professional in your 2022 dental plan, go to the DentaQuest website',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_TN_LIBERTY: [
            {
              id: 'tnliberty-label-text',
              displayText: 'Some of your 2023 dental benefits will be managed by Liberty Dental. ',
              orderSequence: '1',
              ariaLabel: 'Some of your 2023 dental benefits will be managed by Liberty Dental.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tnliberty-link-text',
              displayText: 'To find a dental professional in your 2023 dental plan, go to the {linkLabel}.',
              orderSequence: '2',
              ariaLabel: 'To find a dental professional in your 2023 dental plan, go to the Liberty Dental website.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'Liberty Dental website'
            }
          ],
          TP_VSP: [
            {
              id: 'vsp-label-text',
              displayText: 'VSP manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'VSP manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'vsp-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the VSP website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.vsp.com/find-eye-doctors.html?id=medicaid',
              linkLabel: 'VSP website'
            }
          ],
          TP_EYEQUEST: [
            {
              id: 'eyequest-label-text',
              displayText: 'EyeQuest manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'EyeQuest manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'eyequest-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the EyeQuest website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
              linkLabel: 'EyeQuest website'
            }
          ],
          TP_SUPERIOR: [
            {
              id: 'superior-label-text',
              displayText: 'Superior manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'Superior manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'superior-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Superior website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://superiorvision.com/',
              linkLabel: 'Superior website'
            }
          ],
          TP_GA_Termination_Providers: [
            {
              id: 'termination-Providers-link-text',
              displayText: 'Please review this list of {linkLabel} during the upcoming plan year.',
              orderSequence: '1',
              ariaLabel: 'Please review this list of care providers who may leave your plan network during the upcoming plan year.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: '/assets/documents/gaterminatingproviders.pdf',
              linkLabel: 'care providers who may leave your plan network',
              linkDataAnalyticsTag: 'providersLeftNetworkAlertFindCare',
              mobileLinkDataAnalyticsTag: 'PROVIDERS_LEFT_NETWORK_ALERT_FIND_CARE'
            }
          ],
          TP_SUPPRESSED_MEDSUPP: [
            {
              id: 'suppressed-medsupp-alert-label-text',
              displayText: 'To find a medical care provider that accepts Medicare Assignment (one that accepts the Medicare-approved amount as full payment) go to {linkLabel}.',
              orderSequence: '1',
              ariaLabel: 'To find a medical care provider that accepts Medicare Assignment (one that accepts the Medicare-approved amount as full payment) go to medicare.gov/care-compare.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.medicare.gov/care-compare/',
              linkLabel: 'medicare.gov/care-compare'
            }
          ],
          TP_PrimeGroupSearchMsg: [
            {
              id: 'PrimeGroupSearchMsg-label-text',
              displayText:
                "These search results show care providers in your medical group. If you want to search outside your medical group, select &quot;No Preference&quot; in the Your Medical Group filter. You'll need a referral from your Primary Care Physician before receiving non-emergency services."
            }
          ]
        },
        costForInitialVisit: {
          msgText: "The estimated costs we're showing are for an initial visit. To view detailed benefit information for this procedure, including any service limits, go to {BENEFITS_LINK}.",
          linkText: 'Find Benefits',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'Click here to navigate to Find Benefits'
        },
        costForMoreThanOneVisit: {
          msgText: "The estimated costs we're showing are for visits 1-{VISITS_VALUE}.  To view coverage information for this procedure, including service limits, go to {BENEFITS_LINK}.",
          linkText: 'Find Benefits',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'Click here to navigate to Find Benefits'
        },

        yourMedicalGroupSearchMsg:
          'These search results show care providers in your medical group.  If you want to search outside your medical group, select "No Preference" in the Your Medical Group filter. You\'ll need a referral from your Primary Care Physician before receiving non-emergency services.',
        yourMedicalHealthSystemMsg:
          'These search results show care providers in your medical group. To search outside your medical group, choose another option in the "Your Health System" filter. However, if you seek non-emergency care outside your medical group, you will need a referral from your PCP.'
      },
      homeHealthCareContent: {
        url: 'https://www.mynexuscare.com/providerdirectory',
        publicHeader: 'Sorry, we can’t complete your search',
        publicContent: 'For immediate assistance finding Home Health Care, please call the Member Services number on your member ID card.',
        secureHeader: 'Home Health Care Services',
        secureContent: 'We partner with myNEXUS to help manage your Home Health Care services. To find a Home Health Care service provider, use the myNEXUS Provider Finder.',
        Ok: 'Ok',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      },
      homeHealthCareSecureContentInfo: {
        MY_NEXUS_SECURE_CONTENT: [
          {
            id: 'carelon-label-text',
            orderSequence: '1',
            displayText: 'We partner with Carelon Post Acute Solutions to help manage home healthcare services. ',
            ariaLabel: 'We partner with Carelon Post Acute Solutions to help manage home healthcare services.',
            isAnchorText: false,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: '',
            linkLabel: '',
            targetAttribute: ''
          },
          {
            id: 'carelon-care-link-text',
            orderSequence: '2',
            displayText: 'Learn more by {linkLabel} or calling 844-411-9622. ',
            ariaLabel: 'Learn more by visiting their website or calling 844-411-9622.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://mynexuscare.com',
            linkLabel: 'visiting their website',
            targetAttribute: '_blank'
          },
          {
            id: 'carelon-provider-link-text',
            orderSequence: '3',
            displayText: '{linkLabel}.',
            ariaLabel: 'Find home healthcare services.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://mynexuscare.com/providerdirectory',
            linkLabel: 'Find home healthcare services',
            targetAttribute: '_blank'
          }
        ]
      },
      transportationContent: {
        header: 'Transportation',
        prefixBasedHeader: 'Transportation (Non-Emergency)',
        evidenceCoverageText: "Please refer to your plan's Evidence of Coverage for detailed benefit information.",
        medicalAppointmentText: 'Please call the following number(s) to schedule your transportation for medical appointments:',
        prefixBasedMedicalAppointmentText: 'Call the following number to schedule transportation for medical appointments:',
        countiesText: 'Counties of service: ',
        reservationsText: 'Reservations: ',
        rideAssistText: 'Ride Assist: ',
        ttyText: 'TTY: ',
        contactUsSecure: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/member/contact-us">Member Services.</a>',
        contactUsPublic: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/contact-us">Member Services.</a>',
        close: 'Close',
        print: 'Print',
        tryAgain: 'Try Again',
        goBack: 'Go Back',
        ariaClose: 'Close button, click here to close the transportation modal',
        ariaPrint: 'Print, click here to print the transportation details'
      },
      precareSearchErrorMessages: {
        genericMessage: {
          header: 'Unable to Complete Your Search',
          message: [
            {
              id: 'deeplink_negative_msg',
              orderSequence: '1',
              displayText: "For some reason, we're unable to complete your search. Please {linkLabel} if you have questions about your benefits and coverage.",
              isAnchorText: true,
              inAppNavigation: true,
              mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
              navigationUrl: '/member/contact-us',
              linkLabel: 'contact us',
              targetAttribute: '_self',
              ariaLabel: "For some reason, we're unable to complete your search. Please contact us if you have questions about your benefits and coverage."
            }
          ],
          close: 'Close',
          closeAriaLabel: 'Close button, select to close the modal'
        }
      }
    },
    updateLocationWrapper: {
      locationButton: 'Update Location',
      searchLocation: 'Search Location',
      currentLocation: {
        formHeader: 'Current Location',
        alertMsg: "Note: If you're using a VPN, your current location will be the same as the VPN you're connected to.",
        formFields: {
          city: {
            label: 'City, State',
            placeholder: 'Enter City'
          },
          zipcode: {
            label: 'Zip Code',
            placeholder: 'Enter ZIP code'
          }
        }
      },
      otherAddress: {
        formHeader: 'Other Address',
        formFields: {
          streetAddress: {
            label: 'Street Address',
            placeholder: 'Enter street address'
          },
          streetAddress2: {
            label: 'Street Address Line 2 (Optional)',
            placeholder: 'Enter street address'
          },
          zipcodeOnly: {
            label: 'Zip Code',
            placeholder: 'Enter ZIP code'
          },
          cityOrCounty: {
            label: 'City, State',
            placeholder: 'Enter City'
          }
        }
      },
      homeAddress: {
        label: 'Home Address'
      },
      zipcode: {
        label: 'Zip Code, City or County',
        placeholder: 'City, County, or ZIP'
      },
      continue: 'Continue to Find Care',
      cancel: 'Cancel',
      errorMessage: {
        zipCodeError: 'We didn’t find Zip code. Please try again.',
        emptyZipError: 'Please enter a zip code, city, or county.',
        emptyZipOnlyError: 'Please enter a ZIP code.',
        addressError: 'Please enter the valid address line.',
        pcpError: 'This plan is not available for the address entered.',
        currentLocationError: "Geo Code search can't be done for this location.",
        currentLocationNotEnabled: 'To continue this search Geo location has to been enabled.',
        addessMismatch: "That ZIP code isn't located in that state.Please check the state and ZIP code and try again.",
        emptyCityOrCountyError: 'Please enter city, or state.',
        cityOrCountyError: 'Please enter a valid city or state.'
      },
      addressTypes: {
        homeAddressDisclaimer: 'Search based on your home address',
        workAddressDisclaimer: 'Search based on your work address',
        zipCodeDisclaimer: 'Search based on zipcode',
        changeLocation: 'Search by address',
        hintText: '',
        addressSection: {
          home: {
            code: 'HOME',
            label: 'Home Address'
          },
          work: {
            code: 'WORK',
            label: 'Work Address'
          },
          enter: {
            code: 'enter',
            label: 'enter Address'
          },
          update: {
            code: 'update',
            label: 'Update Home or Work Address'
          }
        },
        customSection: {
          heading: 'Find Other Types of Care',
          subHeading: 'For all other types of searches, enter  ZIP.',
          customZip: {
            label: 'Enter Address',
            code: 'CUSTOMZIP'
          }
        }
      }
    },
    carePlanWrapper: {
      planNetworks: 'Plan Networks',
      changePlanPublic: 'Change Plan',
      planStart: ' (Starting {DATE})',
      changePlanSecure: 'Change Plan or Member',
      changePlanPublicMsg: 'Change Plan Networks',
      changeMember: 'Change Member',
      selectedMemberPlans: "Finding Care in All of <span>{NAME}</span>'s",
      careTeamPlans: 'Viewing Care Team for <span>{NAME}</span> in',
      selectedMemberPlan: '{PLAN}',
      selectedPlan: '{PLAN}',
      customZip: {
        label: 'Enter Address',
        code: 'CUSTOMZIP'
      },
      planNetworksToutHeader: 'The following plan networks are displayed in the search results.',
      closeModal: 'button, select to close the modal',
      planLabelMsg: 'Which plan Network?',
      careLabel: 'What type of care are you searching for?',
      memberLabel: 'Who is this care for?',
      planNetworkLabel: 'Your {{NETWORK}} Plan Network',
      alerts: {
        coverageDetailErrorMsg: 'Selected member dosent have any coverage. So please select another member'
      },
      changePlanLabel: {
        choosePlan: 'Choose a Plan',
        choosePlanTitle: 'Some family members may have different plans.Selecting a different plan will change which members you can search for.',
        includes: 'Includes:',
        eligibleMembers: 'Eligible Members:'
      }
    }
  },
  searchHeader: {
    headerTitle: 'Find Care',
    headerTitleSuffix: 'for',
    changePlan: 'Change Plan',
    selectFamilyMember: 'Select a Family Member',
    contractChangeSuccess: 'Success! Your plan selection has changed.',
    startOver: 'Start Over'
  },
  pageTitle: {
    searchCriteria: 'Find Care Criteria',
    searchResults: 'Find Care Results',
    details: 'Find Care Provider Details'
  },
  actionItems: {
    actionItemButton: 'select to {ACTION}'
  },
  hideQuickLinkShopForProcedure: {
    iAmInterested: {
      name: "I'm Interested",
      header: 'Thanks For Letting Us Know',
      description: "We'll use your feedback to improve our system and show you more of what might be helpful. Please let us know why.",
      dataAnalytics: {
        sendFeedbackIntModalFindCare: 'sendFeedbackIntModalFindCare',
        cancelFeedbackIntModalFindCare: 'cancelFeedbackIntModalFindCare',
        backFeedbackIntModalFindCare: 'backFeedbackIntModalFindCare',
        interestedOptionFindCare: 'interestedOptionFindCare',
        interestedOptionShopProcFindCare: 'interestedOptionShopProcFindCare'
      }
    },
    notInterested: {
      name: 'Not Interested',
      header: "Tell Us Why You're Not Interested",
      description:
        "We'll use your feedback to improve our system and show you more of what might be helpful. Depending on your feedback, we'll remove this button from your Find Care experience for a period of time.  If you need to search for something that's been removed, please use the Find Care search bar.",
      dataAnalytics: {
        sendFeedbackNotIntModalFindCare: 'sendFeedbackNotIntModalFindCare',
        cancelFeedbackNotIntModalFindCare: 'cancelFeedbackNotIntModalFindCare',
        backFeedbackNotIntModalFindCare: 'backFeedbackNotIntModalFindCare',
        notInterestedOptionFindCare: 'notInterestedOptionFindCare',
        notInterestedOptionShopProcFindCare: 'notInterestedOptionShopProcFindCare'
      }
    },
    cancel: 'Cancel',
    sendFeedback: 'Send Feedback',
    other: 'Other',
    closeModal: ' button, select to close the modal',
    otherTextBoxPlaceholder: 'Enter reason'
  },
  newLandingComponent: {
    labels: {
      TP_PROMOTED_PROCEDURE: "{MESSAGE}<br><a href='javascript:void(0);' target='{TARGET}' aria-label='{ARIA-LABEL}' data-analytics='{DATA-ANALYTICS}' class='{CATEGORY}'>{LABEL}</a>"
    }
  },
  locationFormComponent: {
    formHeader: 'Address for Primary Care Search',
    formHeaderMessage:
      "When searching for a primary care provider, we'll need to use your home or work address as a starting point. (You'll be able to search for other types of care based on any location.)",
    homeAddress: 'Home Address',
    workAddress: 'Work Address (optional)',
    streetAddress: { label: 'Street Address', placeholder: 'Enter street address' },
    streetAddress2: { label: 'Street Address Line 2 (optional)', placeholder: 'Enter street address' },
    city: { label: 'City', placeholder: 'Enter city' },
    state: { label: 'State', placeholder: 'Select state' },
    zipcode: { label: 'ZIP Code', placeholder: 'Enter ZIP code' },
    continue: 'Continue to Find Care',
    cancel: 'Cancel',
    errorMessage: { zipCodeError: 'Please Check the Address', pcpError: 'This plan is not available for the address entered.' }
  },
  appErrorMsg: {
    sessionExpired: 'Sorry, your session might have expired. Please refresh the page and try again.',
    defaultErrorMsg: "Sorry, we're experiencing technical difficulties at this time. Please try again later.",
    ErrorMsg: "We're having trouble finding that address. Please recheck the address you entered and try again."
  },
  leavingDisclaimerComponent: {
    closeBtnText: 'Close',
    continueBtnText: 'Continue',
    header: 'You are leaving {BRAND}.com',
    content1:
      "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
    content2:
      'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.',
    btnClose: 'Return to {BRAND}.com',
    btnContinue: 'Continue',
    externalSite: 'button, select to navigate to external site',
    closeModal: 'button, select to close the modal',
    VEP: {
      header: 'Looking for a doctor or hospital in the Anthem Alliance EPO Network ?',
      content1: 'Click the medical providers button to  search for medical providers.',
      content2: 'Click the behavioral health providers button to search for behavioral health providers.',
      content3:
        'For behavioral health services, your product is supported by the Mercy Managed Behavioral Health (MMBH) network. By selecting “behavioral health”, you will be leaving Anthem’s site and linking to MMBH’s website; and you acknowledge that Anthem does not control, guarantee, or approve the information available on the external site or the security or transmission between you and the external site',
      btnClose: 'Medical Providers',
      btnContinue: 'Behavioral Health Providers'
    }
  },
  medicareSupplementContent: {
    heading: 'Important Information for Medicare Supplement Insurance customers',
    description:
      'To find providers that accept Medicare Assignment (those providers that accept the Medicare-approved amount as full payment), you will need to visit the Physician Compare tool at Medicare.gov.',
    btnCancel: 'Cancel',
    btnContinue: 'Continue',
    redirect: 'Go to medicare Provider Finder',
    url: 'https://www.medicare.gov/'
  },
  pageAlerts: {
    ...PAGE_ALERTS_EN
  },
  pageAlertLinks: {
    eyeQuestVision: 'EyeQuest manages some parts of your vision benefit. Use the Search button to go to the EyeQuest website to find a vision professional in your network.',
    eyeQuestVisionUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
    superiorVision: 'Superior manages some parts of your vision benefit. Use the Search button to go to the Superior website to find a vision professional in your network.',
    superiorVisionUrl: 'https://www.superiorvision.com',
    eyeMedVision: 'EyeMed manages some parts of your vision benefit. Go to the EyeMed website to find a vision professional in your network.',
    eyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/ahkplus/?networkDDDisabled=true',
    oheyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/anthemohmd',
    marchVision: 'March Vision Care manages some parts of your vision benefit. Go to the March Vision Care website to find a vision professional in your network.',
    marchVisionUrl: 'https://www.marchvisioncare.com/',
    avesisUrl: 'https://www.fap.avesis.com/amerigroupga/provider/search',
    marylandUrl: 'https://mdmwp.sciondental.com/MWP/Landing',
    navitusPdfUrl: 'https://www.navitus.com/getmedia/25f6cef8-46f9-4d0b-9bd5-7ae8a4a13150/Navitus-Pharmacy-Network-Listing-National-and-Chains.pdf',
    kyPortalUrl: 'http://kyportal.medimpact.com/',
    HCS_URL: 'https://hearingcaresolutions.com/anthem/',
    LIBERTY_DENTAL_URL: 'https://client.libertydentalplan.com/anthem/FindADentist',
    DENTAL_QUEST_URL: 'http://www.dentaquest.com/members/',
    DENTAL_VSP_URL: 'https://www.vsp.com/find-eye-doctors.html?id:medicaid',
    VEP_BEH_HEALTH: 'http://www.mbh-eap.com/pd/'
  },
  pageAlertComponentLabels: {
    notice: 'Notices',
    view: 'View',
    importantNotices: 'Important Notices',
    close: 'Close'
  },
  pageAlertCloseTag: {
    PA_COUPE_ALERT: 'closeIconCoupeAlertHomeFindCare',
    PA_BARIATRIC_REPLACEMENT_ALERT: 'closeLanternAlertHomeFindCare',
    PA_BARIATRIC_ALERT: 'closeLanternAlertHomeFindCare'
  },
  modals: {
    MD_TRANSPORTATION: {
      title: 'Transportation',
      content: {
        header: 'Transportation',
        prefixBasedHeader: 'Transportation (Non-Emergency)',
        evidenceCoverageText: "Please refer to your plan's Evidence of Coverage for detailed benefit information.",
        medicalAppointmentText: 'Please call the following number(s) to schedule your transportation for medical appointments:',
        prefixBasedMedicalAppointmentText: 'Call the following number to schedule transportation for medical appointments:',
        countiesText: 'Counties of service: ',
        reservationsText: 'Reservations: ',
        rideAssistText: 'Ride Assist: ',
        ttyText: 'TTY: ',
        contactUsSecure: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/member/contact-us">Member Services.</a>',
        contactUsPublic: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/contact-us">Member Services.</a>',
        close: 'Close',
        print: 'Print',
        tryAgain: 'Try Again',
        goBack: 'Go Back',
        ariaClose: 'Close button, click here to close the transportation modal',
        ariaPrint: 'Print, click here to print the transportation details'
      }
    },
    MD_MY_NEXUS_SECURE: {
      title: 'Home Health Care Services',
      content: {
        body: '<span id="carelon-label-text" class="pf-pre-wrap pf-inline-span"><span id="carelon-label-text" aria-label="We partner with Carelon Post Acute Solutions to help manage home healthcare services.">We partner with Carelon Post Acute Solutions to help manage home healthcare services. </span><span id="carelon-care-link-text" aria-label="Learn more by visiting their website or calling 844-411-9622.">Learn more by <a target="_blank" aria-label=" visiting their website" id="carelon-care-link-text-anchor" href="https://www.careloninsights.com/medical-benefits-management/post-acute-care">visiting their website</a> or calling 844-411-9622. </span><span id="carelon-provider-link-text" aria-label="Find home healthcare services."><a target="_blank" aria-label=" Find home healthcare services" id="carelon-provider-link-text-anchor" href="https://providers.carelonmedicalbenefitsmanagement.com/postacute/finder/">Find home healthcare services</a>.</span></span>',
        Ok: 'Okay',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      }
    },
    MD_MY_NEXUS_PUBLIC: {
      title: 'Sorry, we can’t complete your search',
      content: {
        body: '<span>For immediate assistance finding Home Health Care, please call the Member Services number on your member ID card.</span>',
        Ok: 'Okay',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      }
    }
  },
  sidePanels: {
    MD_MEDICARE_SUPPLEMENT: {
      title: 'Important Information for Medicare Supplement Insurance customers',
      content: {
        closeBtnText: 'button, click here to close the modal',
        redirectBtnText: 'button, click here will redirect to',
        description:
          'To find providers that accept Medicare Assignment (those providers that accept the Medicare-approved amount as full payment), you will need to visit the Physician Compare tool at Medicare.gov.',
        btnCancel: 'Cancel',
        btnContinue: 'Continue',
        redirect: 'Go to medicare Provider Finder',
        url: 'https://www.medicare.gov/',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.'
      }
    },
    MD_LEAVING_SITE: {
      title: 'You are leaving {BRAND}.com',
      content: {
        closeBtnText: 'Return to {BRAND}.com',
        continueBtnText: 'Continue',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.',
        btnClose: 'Return to {BRAND}.com',
        btnContinue: 'Continue',
        externalSite: 'button, select to navigate to external site',
        closeModal: 'button, select to close the modal'
      }
    },
    MD_LEAVING_SITE_VEP: {
      title: 'Looking for a doctor or hospital in the Anthem Alliance EPO Network ?',
      content: {
        discalimer1: 'Click the medical providers button to  search for medical providers.',
        disclaimer2: 'Click the behavioral health providers button to search for behavioral health providers.',
        disclaimer3:
          'For behavioral health services, your product is supported by the Mercy Managed Behavioral Health (MMBH) network. By selecting “behavioral health”, you will be leaving Anthem’s site and linking to MMBH’s website; and you acknowledge that Anthem does not control, guarantee, or approve the information available on the external site or the security or transmission between you and the external site',
        closeBtnText: 'Medical Providers',
        continueBtnText: 'Behavioral Health Providers'
      }
    },
    OON_INFORMATION: {
      titles: {
        OONTitle: 'Out-of-Network Care Providers',
        info2Title: 'What does “out-of-network” mean?',
        info3Title: 'Is it more expensive to see a care provider outside of my plan’s network?',
        info4Title: 'What does “in-network” mean?'
      },
      content: {
        info1:
          'Except where otherwise required by applicable law, not all plans provide coverage for services provided by out-of-network providers. When a plan does provide coverage, it’s often limited and may come with higher costs for you. Knowing if a provider is out-of-network can help you make informed decisions about your care.',
        info2:
          "Out-of-network refers to care providers (doctor’s, hospitals, etc.) who are not in your plan's network. These providers don’t have a contract to be in the network supporting your plan. They might charge you more because they don't have an agreed-upon rate with your plan. When using out-of-network providers, you typically have to file your own claims and request preapprovals.",
        info3:
          "Yes, usually you’ll pay more if you go to an out-of-network provider. If your plan has out-of-network coverage, your plan will pay a part of the cost for the service. You will have to pay your share and any extra charges. If your plan doesn't cover out-of-network care, you may have to pay 100% of the costs for non-emergency care.",
        info4:
          'In-network refers to care providers who are part of your plan’s network.  These providers have contract with your plan. They agree to provide services to plan members at a set rate. Because of this agreement, you pay less when you receive services from an in-network provider. In-network providers also file your claims for you and help you request preapprovals.',
        info5: 'If a care provider doesn’t display in Find Care as “in-network,” you should assume the provider is not in your plan’s network.'
      },
      close: 'Close'
    }
  }
};

//  Define a type based on the structure of the English content
export type CommonContent = typeof COMMON_CONTENT_EN;
