import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GenericAlertNavigationService implements OnDestroy {
  alertNavigation = new Subject<string>();


  ngOnDestroy() {
    this.alertNavigation.unsubscribe();
  }

  pageAlertNavigation(pageAlertID: string) {
    this.alertNavigation.next(pageAlertID);
  }


  alertNavigationSubjectListener() {
    return this.alertNavigation;
  }

  onClickOnPageAlert(pageAlertID : string) {
    if (pageAlertID === 'PA_NAVIGATE_PLAN_SELECTION') {
      this.pageAlertNavigation(pageAlertID)
   }
  }
}
