import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntentScenario } from '../../../../../common/enums/intent';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { SydneyChatbotEventChannelUtil } from '../../../../../fad/utilities/sydneyChatbotEventChannelUtil';
import { IProviderList } from '../../../../../findcare/result/interfaces/iProvider';
import { NAVIGATION_ALERT_IDS } from '../../../contents/common/alertTypeConfig';
import { FindCarePageType } from '../../../enums/findCarePages';
import { IAlertMessage } from '../../../interfaces/iPageAlert';
import { BaseComponent } from '../../core/baseCmp';
import { GenericAlertNavigationService } from '../modal/services/genericAlertNavigationSvc';
import { PageAlertHandler } from './service/pageAlertHandler';

@Component({
  moduleId: module.id,
  selector: 'app-fc-page-alert-detail-cmp',
  templateUrl: './pageAlertDetailCmp.html'
})
export class pageAlertDetailComponent extends BaseComponent implements OnInit {
  detailsPageAlerts: IAlertMessage[] = null;
  resultPageAlerts: IAlertMessage[] = null;
  @Output()
  pageTitle: string = null;
  @Input() providersInfo: IProviderList;
  @Input() pageName: FindCarePageType;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private genericAlertNavigationService? : GenericAlertNavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.pageTitle = this.content?.common?.pageAlertComponentLabels?.notice;
    this.detailsPageAlerts = this._appSession.pageAlerts[this.pageName];
  }

  getMessage(alertMessage: IAlertMessage) {
    return PageAlertHandler.replaceLiteralwithContent(alertMessage.id, this.providersInfo, this._appSession, this.content);
      }

  getIconStyle(type: string): string {
    return PageAlertHandler.getIconStyle(type);
  }

  @HostListener('click', ['$event']) onSearchClick(event) {
    if (event?.target?.className === 'navigateToChatBtn') {
      this.closeModal.emit();
      SydneyChatbotEventChannelUtil.publishEventForIntent(IntentScenario.BH_NON_CRISIS, this._appSession.userIntent, this._appSession, this._eventHandler);
    }
    if (
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      typeof event.target?.['id'] !== 'undefined' &&
      NAVIGATION_ALERT_IDS[event?.target?.['id']]
    ) {
      this.genericAlertNavigationService.onClickOnPageAlert(event?.target?.['id'])
    }
  }
}
