import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isEmpty } from 'lodash';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IAlertMessage } from '../../../../app/common/interfaces/iMessage';
import { OONDetailsComponent } from '../../../common/components/common-alert/oonDetailsCmp';
import { GeoLocationComponent } from '../../../common/components/geoLocation/geoLocationCmp';
import { GeoLocationService } from '../../../common/components/geoLocation/services/geoLocationService';
import { LocationComponent } from '../../../common/components/location/locationCmp';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { IntentScenario } from '../../../common/enums/intent';
import { IWindow } from '../../../common/interfaces/iWindow';
import { HighlightPipe } from '../../../common/pipes/highlightPipe';
import { IntentService } from '../../../common/services/intentSvc';
import { SearchSvc } from '../../../common/services/searchSvc';
import { AppConfig } from '../../../common/values/appConfig';
import { EycProcedureCriteria } from '../../../fad/search-results/interfaces/iSearchRequest';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import { ISelectedProvider } from '../../provider-details/models/iSelectedProvider';
import { IRuleParameters } from '../../rules/interfaces/iRulesParameters';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { FEATURE_SEARCH_TYPE, OON_ADOBE_TAGS } from '../../search-results/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { SydneyChatbotEventChannelUtil } from '../../utilities/sydneyChatbotEventChannelUtil';
import { WcsUtil } from '../../utilities/wcsUtil';
import { IZipCode } from '../interfaces/iAddressForm';
import { IAreaOfExpertise } from '../interfaces/iAreaOfExpertise';
import { ICptCode, ICptLookUpList } from '../interfaces/iCptLookup';
import { IOfficeService } from '../interfaces/iOfficeService';
import { IProcedureLookup } from '../interfaces/iProcedureLookup';
import { IProvider, IProviderLookup } from '../interfaces/iProviderLookup';
import { IProviderMetadataRequest } from '../interfaces/iProviderMetadataRequest';
import { IProviderMetadataResponse } from '../interfaces/iProviderMetadataResponse';
import { ISmartSearchLookupRequest } from '../interfaces/iSmartSearchLookupRequest';
import { ISmartSearchLookupResponse } from '../interfaces/iSmartSearchLookupResponse';
import { ISpecialtyLookup } from '../interfaces/iSpecialtyLookup';
import { CptLookupService } from '../services/cptUtilitySvc';
import { ProviderLookupServiceV1 } from '../services/providerLookupServiceV1';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { SmartSearchService } from '../services/smartSearchSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, PROVIDER_TYPE_OF_CARE, THREE, TRANSPORTATION_PREFIX } from '../values/providerSearchConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AppExternalCommands, Locale, MODAL_TYPE, PLANTYPE_OON, SEARCH_OPTIONS } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { IAdobe, IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { IEventDetail } from './../../../common/interfaces/iEventDetail';
import { IGenericModalTemplate } from './../../../common/interfaces/iMessage';
import { DataHelper } from './../../../common/services/dataHelper';
import { DataService } from './../../../common/services/dataService';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AbstractSearchStrategy } from './../../../common/services/strategySvc';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { SAVNavigationService } from './../../../fad/search-providers/services/savNavigationSvc';
import { EycSearchParam } from './../../../fad/search-results/interfaces/iSearchRequest';
import { ProviderDetailsNavigationService } from './../../provider-details/services/providerDetailsNavigationSvc';
import { IProviderType } from './../interfaces/iProviderType';
import { ISearchParameters } from './../interfaces/iSearchParameters';
import { IntegratedSearchService } from './../services/integratedSearchSvc';
declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-integrated-search-cmp',
  templateUrl: '../views/pfIntegratedSearchCmp.html',
  providers: []
})
export class PFIntegratedSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Input()
  showChangeLocation: boolean;
  @ViewChild('openAddressSelection')
  changePlanSidePanel: TemplateRef<HTMLElement>;
  @Output()
  search: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  openSwitchLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  reloadSAV: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('transportationModal')
  transportationModal: ModalComponent;
  @ViewChild('homeHealthCareModal')
  homeHealthCareModal: ModalComponent;
  @ViewChild('medicareSupplementModal')
  medicareSupplementModal: ModalComponent;
  @ViewChild('searchTermIdentifier')
  searchTermInput: ElementRef;
  @ViewChild('genericModalCmp')
  genericModal: ModalComponent;
  @ViewChild('location')
  locationCmp: LocationComponent;
  @ViewChild('locationV1')
  locationCmpV1: GeoLocationComponent;
  @ViewChild('oonLearnMoreSearch')
  oonLearnMoreSlider: OONDetailsComponent;
  zipcodeProperties = {
    disableZipcode: false,
    showMarker: true,
    disabledClass: false,
    showError: true
  } as IZipCode;
  appConfig: AppConfig;
  lookupResultTypeListOrder: string[]; // Order of lookup result types
  locale: Locale;
  searchWatermark: string = '';
  planUnknownError: boolean = false;
  modalType: any = MODAL_TYPE;
  searchCriteria: ISearchParameters;
  showSpecialtyLoader: boolean = true;
  showAoeLoader: boolean = true;
  showOfcSrvcLoader: boolean = true;
  showProcedureLoader: boolean = true;
  showSmartSearchLoader: boolean = true;
  showProviderLoader: boolean = true;
  showNpiProviderLoader: boolean = true;
  showLicenseProviderLoader: boolean = true;
  showFlyOut: boolean = false;
  specialtyMore: boolean = false;
  aoeMore: boolean = false;
  ofcSrvcMore: boolean = false;
  procedureMore: boolean = false;
  providerMore: boolean = false;
  providerNpiMore: boolean = false;
  providerLicenseMore: boolean = false;
  myNexusHeader: string;
  myNexusContent: string;
  myNexusClose: string;
  searchTerm: string = '';
  searchStrategy: AbstractSearchStrategy | undefined;
  procedureList: any = [];
  cptList: any = [];
  specialtyList: any = [];
  aoeList: any = [];
  ofcSrvcList: any = [];
  providerList: any = [];
  providerNpiList: any = [];
  providerLicenseList: any = [];
  procedureTop5List: any = [];
  specialtyTop5List: any = [];
  aoeTop5List: any = [];
  ofcSrvcTop5List: any = [];
  providerTop5List: any = [];
  providerNpiTop5List: any = [];
  providerLicenseTop5List: any = [];
  hasEYC: boolean = false;
  isMemberSecure: boolean = false;
  isSecureState: boolean = false;
  optionSelected: string = SEARCH_OPTIONS.SEARCH_ALL;
  optionTypeSelected: string = SEARCH_OPTIONS.SEARCH_ALL;
  selected: string = '';
  inputMinLength: number;
  inputMaxLength: number;
  isRedBorderShow: boolean;
  hasCostTransparency: boolean;
  showMedicalCode: boolean = false;
  showMedicalCodeMoreLess: boolean = false;
  visibleMedicalCodeCount: number = THREE;
  THREE: number = THREE;
  executionCount: number = 0;
  enableOONlink: boolean = false;
  genericModalTemplate: IGenericModalTemplate = { message: '' };
  smartSearchRequestId: string = '';
  intentScenario = IntentScenario;
  userIntent: IntentScenario = IntentScenario.IGNORE;
  searchOptions: any = [
    {
      label: this.content?.searchCriteriaComponent?.labels?.searchAll,
      value: SEARCH_OPTIONS.SEARCH_ALL,
      analytics: 'options-search-all'
    },
    {
      label: this.content?.searchCriteriaComponent?.labels?.procedures,
      value: SEARCH_OPTIONS.PROCEDURES,
      analytics: 'options-procedures'
    },
    {
      label: this.content?.searchCriteriaComponent?.labels?.specialties,
      value: SEARCH_OPTIONS.SPECIALTIES,
      analytics: 'options-specialities'
    },
    {
      label: this.content?.searchCriteriaComponent?.labels?.aoe,
      value: SEARCH_OPTIONS.AOE,
      analytics: 'options-aoe'
    },
    {
      label: this.content?.searchCriteriaComponent?.labels?.officeService,
      value: SEARCH_OPTIONS.OFFICE_SERVICE,
      analytics: 'options-officeservice'
    },
    {
      label: this.content?.searchCriteriaComponent?.labels?.providerName,
      value: SEARCH_OPTIONS.PROVIDER_NAME,
      analytics: 'options-providername'
    }
  ];

  findOtherOption = {
    label: this.content?.addressTypes?.customSection?.CUSTOMZIP?.label,
    value: this.content?.addressTypes?.customSection?.CUSTOMZIP?.label,
    id: 'rdb-mbr-id-zipcode'
  };

  searchOptionsGroup = {
    isOptional: false,
    alignVertical: true,
    id: 'rbtn-search-option',
    name: 'rbtnSearchOptionGrp',
    label: this.content?.searchCriteriaComponent?.labels?.searchBy,
    options: []
  };
  sidePanelRef: ModalRef<any, any>;
  medicalCodeCriteria: {
    billingType: string;
    medicalCode: string;
    medicalModifierOptionCode?: string;
    medicalPosCode?: string;
  } = {
    billingType: '',
    medicalCode: ''
  };
  adobeTags = OON_ADOBE_TAGS;
  showPCPSearch = false;
  labelsContent = this.content?.searchCriteriaComponent?.labels;
  private _ruleChangeSubscription: Subscription;
  private _transportationLocation: any;

  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  private reloadSAVPageObservable: Subscription;
  private loadResultObservable: Subscription;
  private reloadSearchObservable: Subscription;

  constructor(
    private searchService: SearchSvc,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _smartSearchService: SmartSearchService,
    private _cptLookupSvc: CptLookupService,
    private _providerSummarySvc: ProviderSummaryService,
    private _highlightPipe: HighlightPipe,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    private _dataService: DataService,
    private _elementRef: ElementRef,
    @Inject('Window')
    private _window: IWindow,
    private _integratedSearchService: IntegratedSearchService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _sidePanel: SidePanel,
    private _savNavigationService: SAVNavigationService,
    private _providerLookupSvc: ProviderLookupServiceV1,
    private _geoLocationService: GeoLocationService,
    private _intentService: IntentService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    this._ruleChangeSubscription = this._integratedSearchService.onRuleChange.subscribe((rules: IRulesResponse) => {
      this.invokeActions(rules);
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.appConfig = this._appSession.appConfig;
    this.lookupResultTypeListOrder = this.appConfig?.lookupResultSortOrder;
    this.inputMinLength = this.appConfig?.features.lookUp.inputLength;
    this.inputMaxLength = this.appConfig?.features.lookUp.inputMaxLength;

    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale : Locale.ENGLISH;

    if (this._appSession) {
      this._appSession.integratedSearch = true;
      this.isSecureState = this._appSession.isSecureState;
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
      this.searchTerm = this._appSession.searchTerm ? this._appSession.searchTerm : '';
      if (this._appSession.searchParams && this._appSession.searchParams.zip) {
        this.searchCriteria.zip = this._appSession.searchParams.zip;
      }
    }
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_SEARCH_BAR_LOAD];
    eventDetail.message = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.MSG;
    eventDetail.target = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.CMD;
    //set the deeplink error modal
    this.setDeeplinkErrorModal();
    this._pfAppCommandEvent.emit(eventDetail);
    this.enableOONlink = this._appSession.planRule?.isOONProviderEnabled;
    this.reloadSAVPageObservable = this._geoLocationService.getReloadSAVPageObservable().subscribe((data) => {
      this.reloadSAVPage();
    });
    this.loadResultObservable = this._geoLocationService.getLoadResultObservable().subscribe((data) => {
      this.loadProviderSummary();
    });
    this.reloadSearchObservable = this._geoLocationService.getReloadSearchObservable().subscribe((data) => {
      this.reloadProviderSummary();
    });
  }

  ngAfterViewInit() {
    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        // Time in milliseconds between key events
        debounceTime(300),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // get value
        map((event) => {
          return event;
        })
      )
      .subscribe((event: KeyboardEvent) => {
        this.onKeyUpEvent(event);
      });

    if (this._appSession.isDeeplinkErrorMsg && !this._dataHelper.isEmptyString(this.genericModalTemplate.message)) {
      this.openModal(MODAL_TYPE.GENERIC_MODAL);
      this._appSession.isDeeplinkErrorMsg = false;
    }
  }

  onKeyUpEvent(event: KeyboardEvent) {
    this.showSpecialtyLoader = true;
    this.showAoeLoader = true;
    this.showOfcSrvcLoader = true;
    this.showProcedureLoader = true;
    this.showSmartSearchLoader = true;
    this.showProviderLoader = true;
    this.showNpiProviderLoader = true;
    this.showLicenseProviderLoader = true;
    this.isRedBorderShow = false;
    const _searchTerm = this.searchTerm.trim();

    if ((event.key !== 'Enter' || this.isAllListEmpty()) && _searchTerm.length > 0 && _searchTerm.length < this.inputMaxLength && this.isLocationValid()) {
      if (this.searchTerm && _searchTerm !== this.searchTerm.trim()) {
        return;
      } else {
        this.initateSearchTermActions(_searchTerm);
      }
    } else if (event.key === 'Enter') {
      for (let lookupResultType of this.lookupResultTypeListOrder) {
        if (lookupResultType === 'specialtyList' && this.specialtyList && this.specialtyList.length > 0) {
          this.onSpecialtySelect(this.specialtyList[0]);
          break;
        } else if (lookupResultType === 'procedureList' && this.procedureList && this.procedureList.length > 0) {
          this.onProcedureSelect(this.procedureList[0]);
          break;
        } else if (lookupResultType === 'providerList' && this.providerList && this.providerList.length > 0) {
          this.showProvidersMore(FEATURE_SEARCH_TYPE.NAME);
          break;
        } else if (lookupResultType === 'providerLicenseList' && this.providerLicenseList && this.providerLicenseList.length > 0) {
          this.onProviderSelect(this.providerLicenseList[0]);
          break;
        } else if (lookupResultType === 'providerNpiList' && this.providerNpiList && this.providerNpiList.length > 0) {
          this.onProviderSelect(this.providerNpiList[0]);
          break;
        } else if (lookupResultType === 'aoeList' && this.aoeList && this.aoeList.length > 0) {
          this.onAOESelect(this.aoeList[0]);
          break;
        } else if (lookupResultType === 'ofcSrvcList' && this.ofcSrvcList && this.ofcSrvcList.length > 0) {
          this.onOfcSrvcSelect(this.ofcSrvcList[0]);
          break;
        } else if (lookupResultType === 'cptList' && this.cptList && this.cptList.length > 0) {
          this.onMedicalCodeSelect(this.cptList[0]);
          break;
        } else {
          if (!this._appSession.isEyc) {
            const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
            if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
              this.resetSearchCriteria();
              this.resetAppSessionParams();
              this.reloadProviderSummary();
            }
          }
        }
      }
    }
    if ((!this.locationCmp?.locationTxt && !this.isNewLocationFlowEnable) || (!this.locationCmpV1?.locationTxt && this.isNewLocationFlowEnable)) {
      this.isRedBorderShow = true;
    }
    !this.isNewLocationFlowEnable ? this.locationCmp?.showRedColorBorder(this.isRedBorderShow) : this.locationCmpV1?.showRedColorBorder(this.isRedBorderShow);
  }

  ngOnChanges(args: any) {
    if (this.showChangeLocation) {
      this.zipcodeProperties.disableZipcode = false;
      this.zipcodeProperties.disabledClass = false;
      this.zipcodeProperties.showMarker = true;
      this.zipcodeProperties.showError = true;
    }
    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    }
    this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS));
    this.hasCostTransparency = this._appSession.metaData.appContract?.hasCostTransparency && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_SEARCH_CPT, this._appSession);
    this.searchOptionsGroup.options = this.bindSearchOptions();
    if (this._appSession.searchOption) {
      this.optionTypeSelected = this._appSession.searchOption;
    }
    this.optionTypeSelected = !this.hasEYC && this.optionTypeSelected === SEARCH_OPTIONS.PROCEDURES ? SEARCH_OPTIONS.SEARCH_ALL : this.optionTypeSelected;
    this.selected = this.selectedOption();

    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  ngOnDestroy() {
    if (typeof this._ruleChangeSubscription !== 'undefined' && this._ruleChangeSubscription !== null) {
      this._ruleChangeSubscription.unsubscribe();
    }
    if (this.reloadSAVPageObservable) {
      this.reloadSAVPageObservable.unsubscribe();
    }
    if (this.loadResultObservable) {
      this.loadResultObservable.unsubscribe();
    }
    if (this.reloadSearchObservable) {
      this.reloadSearchObservable.unsubscribe();
    }

    if (this.isFtsFeatureLive) {
      this.clearAll();
      this.resetSearchCriteria();
      this.resetAppSessionParams();
    }
  }

  bindSearchOptions() {
    const _searchOptions = [];
    for (const option of this.searchOptions || []) {
      if (option.value === SEARCH_OPTIONS.PROCEDURES) {
        if (this.hasEYC) {
          _searchOptions.push(option);
        }
      } else {
        _searchOptions.push(option);
      }
    }
    return _searchOptions;
  }

  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }

  initateSearchTermActions(_searchTerm: string) {
    this._transportationLocation = null;
    this.clearAll();

    if (_searchTerm.length >= this.inputMinLength) {
      const _isNumricSearch: boolean = this.isNumber(_searchTerm);
      const contractUid: string = this._appSession && this._appSession.metaData && this._appSession.metaData.appContract ? this._appSession.metaData.appContract.contractUid : '';
      // procedure search if term not numeric
      if (
        (this.hasEYC || this.isColdStateEYC) &&
        !_isNumricSearch &&
        !this.showSmartSearch &&
        (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL || this.optionSelected === SEARCH_OPTIONS.PROCEDURES)
      ) {
        this._providerUtilityService.getProcedureLookupList({ searchTerm: encodeURI(_searchTerm), locale: this.locale, contractUid }).then(
          (result: any) => {
            if (result && result.procedures && result.procedures.length > 0) {
              this.procedureList = result.procedures;
              this.procedureTop5List = result.procedures.slice(0, this.appConfig?.features.lookUp.minCount);
            } else {
              this.procedureList = [];
              this.procedureTop5List = [];
            }
            this.showProcedureLoader = false;
          },
          (error: any) => {
            try {
              this.showProcedureLoader = false;
              this.onCategoryError('PROCEDURES_LOOKUP', error);
            } catch (err) {}
          }
        );
      } else {
        this.showProcedureLoader = false;
      }

      //smartSearch
      if (!_isNumricSearch && this.showSmartSearch && this.isLocationValid()) {
        const ruleRequest = this.bindSmartSearchRequest(_searchTerm);
        this.getSmartSearchList(ruleRequest);
        this.getIntent();
      } else {
        this.showSmartSearchLoader = false;
      }

      //cpt search for secure state follow only
      if (this.isMemberSecure && this.hasCostTransparency && this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL) {
        this._cptLookupSvc.getCptLookupList({ searchTerm: encodeURI(_searchTerm), locale: this.locale, contractUid }).then(
          (result: ICptLookUpList) => {
            if (result?.cptCodes?.length) {
              this.cptList = result.cptCodes;
            } else {
              this.cptList = [];
            }
          },
          (error: any) => {
            try {
              this.onCategoryError('CPT_LOOKUP', error);
            } catch (e) {}
          }
        );
      }
      // specialty search if term is not numeric
      if (!_isNumricSearch && !this.showSmartSearch && (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL || this.optionSelected === SEARCH_OPTIONS.SPECIALTIES)) {
        let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
        _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession, false);
        const _ruleRequest: IRuleParameters = CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession);
        if (_ruleRequest) {
          _ruleRequest.locale = this.locale;
          _ruleRequest.searchTerm = encodeURI(_searchTerm);
          _ruleRequest.providerTypeList = _searchCriteria.providerTypeCodeList;
          _ruleRequest.useTaxonomy = true;
        }
        this._providerUtilityService
          .getSpecialtyLookupList(_ruleRequest)
          .then((result: any) => {
            if (result && result.specialties && result.specialties.length > 0) {
              this.specialtyList = result.specialties;
              this.specialtyTop5List = result.specialties.slice(0, this.appConfig?.features.lookUp.minCount);
              if (result.transportationLocation) {
                this._transportationLocation = result.transportationLocation;
              }
            } else {
              this.specialtyList = [];
              this.specialtyTop5List = [];
            }
            this.showSpecialtyLoader = false;
          })
          .catch((error: any) => {
            this.showSpecialtyLoader = false;
            this.onCategoryError('SPECIALTIES_LOOKUP', error);
          });
      } else {
        this.showSpecialtyLoader = false;
      }

      //AoE& OfceSrvc Lookup
      if (!_isNumricSearch) {
        this.aoeOfceSrvcFwdLookup(encodeURI(_searchTerm));
      } else {
        this.showAoeLoader = false;
        this.showOfcSrvcLoader = false;
      }

      // provider name search if term is not numeric
      if (!_isNumricSearch && (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL || this.optionSelected === SEARCH_OPTIONS.PROVIDER_NAME)) {
        if (!this.planUnknownError && this.searchCriteria && this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude && this.searchCriteria.coordinates.longitude) {
          this.getPrecareLookupProviders();
        } else {
          this.providerList = [];
          this.providerTop5List = [];
          this.showProviderLoader = false;
        }
      } else {
        this.showProviderLoader = false;
      }

      //NPI Search if term is numeric
      if (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL && _isNumricSearch) {
        if (this.searchCriteria && this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude && this.searchCriteria.coordinates.longitude) {
          this.getfeatureLookupProviders(FEATURE_SEARCH_TYPE.NPI);
        } else {
          this.providerNpiList = [];
          this.providerNpiTop5List = [];
          this.showNpiProviderLoader = false;
        }
      } else {
        this.showNpiProviderLoader = false;
      }
      // License Number if term contains at least 2 numeric
      if (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL && this.validateLicenseNumber(_searchTerm)) {
        if (this.searchCriteria && this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude && this.searchCriteria.coordinates.longitude) {
          this.getfeatureLookupProviders(FEATURE_SEARCH_TYPE.LICENSE);
        } else {
          this.providerLicenseList = [];
          this.providerLicenseTop5List = [];
          this.showLicenseProviderLoader = false;
        }
      } else {
        this.showLicenseProviderLoader = false;
      }
    }
  }

  resetSearchCriteria() {
    const _providerTypeCode = this.searchCriteria.typeSelectNm;
    const _providerTypeCodeList = this.searchCriteria.providerTypeCodeList;
    const _eycSearchParam = this.searchCriteria.eycSearchParam;
    this.clearSearchCriteria();
    this.searchCriteria.typeSelectNm = _providerTypeCode;
    this.searchCriteria.providerTypeCodeList = _providerTypeCodeList;
    //Procedure search
    this.searchCriteria.eycSearchParam = _eycSearchParam;
    //Pharmacy search
    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }
  }

  isAllListEmpty() {
    return (
      this.procedureList.length === 0 &&
      this.specialtyList.length === 0 &&
      this.aoeList.length === 0 &&
      this.ofcSrvcList.length === 0 &&
      this.providerList.length === 0 &&
      this.providerLicenseList.length === 0 &&
      this.providerNpiList.length === 0 &&
      this.cptList.length === 0
    );
  }

  getfeatureLookupProviders(type: FEATURE_SEARCH_TYPE) {
    let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
    _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession);
    const _session: AppSession = Object.assign({}, this._appSession);
    _session.searchParams = _searchCriteria;
    const _searchTerm = this.searchTerm.trim();
    const _lookUpRequest = ResultsUtil.buildProviderLookUpRequest(_session, _searchTerm);
    _lookUpRequest.searchCriteria.featureSearchType = type;
    _lookUpRequest.searchCriteria.providerName = '';
    _lookUpRequest.searchCriteria.featureSearchText = _searchTerm;
    this._providerSummarySvc.getProviderFeatureLookUp(_lookUpRequest).then(
      (result: any) => {
        this.showNpiProviderLoader = false;
        this.showLicenseProviderLoader = false;
        switch (type) {
          case FEATURE_SEARCH_TYPE.NPI:
            this.providerNpiList = result && result.providers && result.providers.length > 0 ? result.providers : [];
            this.providerNpiTop5List = result && result.providers && result.providers.length > 0 ? result.providers.slice(0, this.appConfig?.features.lookUp.minCount) : [];
            break;
          case FEATURE_SEARCH_TYPE.LICENSE:
            this.providerLicenseList = result && result.providers && result.providers.length > 0 ? result.providers : [];
            this.providerLicenseTop5List = result && result.providers && result.providers.length > 0 ? result.providers.slice(0, this.appConfig?.features.lookUp.minCount) : [];
            break;
          default:
            break;
        }
      },
      (error: any) => {
        try {
          this.showNpiProviderLoader = false;
          this.showLicenseProviderLoader = false;
          this.onCategoryError('PROVIDER_FEATURE_LOOKUP', error);
        } catch (e) {}
      }
    );
  }

  async getPrecareLookupProviders() {
    let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
    _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession, false);
    const _session: AppSession = Object.assign({}, this._appSession);
    _session.searchParams = _searchCriteria;
    const _searchTerm = this.searchTerm.trim();
    let result = {} as IProviderLookup;
    try {
      result = await this._providerLookupSvc.search(_session, _searchTerm);
    } catch (error) {
      this.showProviderLoader = false;
      try {
        this.onCategoryError('PROVIDER_LOOKUP', error);
      } catch (e) {}
    }
    if (result?.providers?.length > 0) {
      this.providerList = result.providers;
      this.providerTop5List = result.providers.slice(0, this.appConfig?.features.lookUp.minCount);
      this._appSession.oopEligible = result.oopEligible ? result.oopEligible : false;
    } else {
      this.providerList = [];
      this.providerTop5List = [];
    }
    this.showProviderLoader = false;
  }

  showSpecialtyMore() {
    this.specialtyMore = !this.specialtyMore;
    return false;
  }

  showAoeMore() {
    this.aoeMore = !this.aoeMore;
    return false;
  }

  showOfcSrvcMore() {
    this.ofcSrvcMore = !this.ofcSrvcMore;
    return false;
  }

  showLicenseMore() {
    this.providerLicenseMore = !this.providerLicenseMore;
    return false;
  }

  showProcedureMore() {
    this.procedureMore = !this.procedureMore;
    return false;
  }

  showProvidersMore(searchType, oonOnly = false) {
    this.clearAll();
    this.clearSearchCriteria();
    const _searchTerm = this.searchTerm.trim();
    if (searchType === FEATURE_SEARCH_TYPE.NPI) {
      this.searchCriteria.provNm = '';
      this.searchCriteria.nationalProviderIdentifier = _searchTerm;
      this.searchCriteria.npi = _searchTerm;
    } else {
      this.searchCriteria.provNm = _searchTerm;
      this.searchCriteria.nationalProviderIdentifier = '';
      this.searchCriteria.npi = '';
    }
    // if the Link to show OON providers only is clicked
    if (oonOnly) {
      this.searchCriteria.planTypeList?.length ? this.searchCriteria.planTypeList.push(PLANTYPE_OON) : (this.searchCriteria.planTypeList = [PLANTYPE_OON]);
    }
    this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this.searchCriteria = this.bindProviderType(this.searchCriteria, this._appSession);

    //Adobe Analytics
    const _searchDetails = {} as IAdobeSearchDetail;
    _searchDetails.provName = _searchTerm;
    _searchDetails.zip = this.searchCriteria.zip;
    this.setAnalyticsQuery(_searchDetails);

    //Pharmacy search
    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }

    this.resetAppSessionParams();
    this.reloadProviderSummary();
  }

  resetAppSessionParams(eyc: boolean = false) {
    this._appSession.pageNumber = undefined;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.searchOption = this.optionSelected;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
    this._appSession.billingType = this.medicalCodeCriteria.billingType;
    this._appSession.medicalCode = this.medicalCodeCriteria.medicalCode;
    this._appSession.medicalModifierOptionCode = this.medicalCodeCriteria.medicalModifierOptionCode;
    this._appSession.medicalPosCode = this.medicalCodeCriteria.medicalPosCode;
  }

  //Method to handle summary page navigation and provider summary page update event.
  reloadProviderSummary() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (
      this._appSession &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH &&
      this._appSession.feature &&
      this._appSession.feature.showBCP &&
      this._appSession.feature.showPreFilterBCP &&
      this._appSession.searchParams
    ) {
      this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
    }

    if (this._contentHelper.isSearchResultRoute(_routeUrl) && this._appSession.isCaHmoDmhc && !this._appSession.serviceAreaValidated) {
      this._savNavigationService.navigate();
    } else if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.search.emit(true);
    } else {
      this._savNavigationService.navigate();
    }
  }

  onDataChanged(data: any) {
    const _searchTerm = data.trim();
    if (this._appSession) {
      this._appSession.searchTerm = _searchTerm;
    }
    if (typeof _searchTerm === 'string' && _searchTerm.length > 0) {
      this.showPCPSearch = this.labelsContent?.primaryCareTerms?.some((term: string) => _searchTerm.toUpperCase().includes(term));
    }
    this._appSession.isSearchTerm = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (_searchTerm.length === 0 && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.resetSearchCriteria();
      this._appSession.searchTerm = this.searchTerm;
      this._appSession.searchOption = this.optionSelected;
      this._appSession.searchParams = this.searchCriteria;
      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.isSearchTerm = false;
    }
  }

  highlightText(value: string) {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  onProcedureSelect(procedure: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isEyc = true;

    if (procedure) {
      this.searchTerm = procedure.procedureCode.name;
      const eycCriteria = new EycProcedureCriteria();
      const eycSearchParam = new EycSearchParam();

      this.searchCriteria.providerTypeCodeList = [];
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureProcedureScenario(procedure.providerCategoryCode.code) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;
      eycCriteria.procedureCode = procedure.procedureCode && procedure.procedureCode.code ? procedure.procedureCode.code : '';
      eycCriteria.providerCategoryCode = procedure.providerCategoryCode.code;
      eycCriteria.procedureName = procedure.procedureCode.name;
      eycSearchParam.procedureDescription = procedure.procedureCode && procedure.procedureCode.description ? procedure.procedureCode.description : '';
      eycSearchParam.costDescription = procedure.costExplainationText;
      eycSearchParam.eycProcedureCriteria = eycCriteria;

      this.searchCriteria.eycSearchParam = eycSearchParam;
      this.searchCriteria.brandCode = this._appSession.metaData.brandCd;
      this.searchCriteria.smartSearchRequestId = this.smartSearchRequestId;

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          this.searchCriteria.selectedMem = _member.firstName + ' ' + _member.lastName;
        }
        this.searchCriteria.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
      }

      if (this.isColdStateEYC) {
        if (this._appSession.searchParams.eycPrefix) {
          this.searchCriteria.eycPrefix = this._appSession.searchParams.eycPrefix;
        }
        if (this._appSession.searchParams.isPrefixStateEnabledForProcSearch) {
          this.searchCriteria.isPrefixStateEnabledForProcSearch = this._appSession.searchParams.isPrefixStateEnabledForProcSearch;
        }
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  onAOESelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isAoeSearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;

    if (this.isAoeOfceSrvcForwardLookupEnabled) {
      this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    } else if (obj.providerTypes && obj.providerTypes.length > 0) {
      if (!(this._appSession?.providerTypes?.length && this._appSession.providerTypes.some((prov) => prov.code === PROVIDER_TYPE.OTHER_SERVICES_PROVIDER))) {
        obj.providerTypes = obj.providerTypes.filter((prov) => prov !== PROVIDER_TYPE.OTHER_SERVICES_PROVIDER);
      }
      _providerTypes = obj.providerTypes;
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      if (obj.providerTypes.length === 1) {
        this.searchCriteria.typeSelectNm = obj.providerTypes[0].toUpperCase().trim();
      }
      this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
      this.searchCriteria.providerTypeCodeList = _providerTypes;
    }
    this.searchCriteria.aoeSelectNm.push(obj.code);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  onSpecialtySelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isSpecialitySearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;
    if (!this.planUnknownError) {
      if (obj.providerTypes && obj.providerTypes.length > 0) {
        _providerTypes = obj.providerTypes;
        this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
        if (obj.providerTypes.length === 1) {
          this.searchCriteria.typeSelectNm = obj.providerTypes[0].toUpperCase().trim();
        }
      }

      // Specialty & Sub-specialty search params setting.
      if (obj.categories?.length > 0) {
        this.searchCriteria.specialtySelectNm = obj.categories;
        this.searchCriteria.taxonomySelectNm.push(obj.code);
      } else {
        this.searchCriteria.specialtySelectNm.push(obj.code);
      }
      if (obj?.ableToServePcp) {
        this.searchCriteria.ableToServePcp = true;
      }

      this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
      this.searchCriteria.smartSearchRequestId = this.smartSearchRequestId;
      if (this._dataHelper.isEmptyString(this.searchCriteria.coverageTypeCode)) {
        if (_providerTypes && _providerTypes.length > 0) {
          this.searchCriteria.providerTypeCodeList = _providerTypes;
        }
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  onProviderSelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();

    let _providerTypes = [];
    // Medsupp scenario
    if (this._appSession.planRule && this._appSession?.planRule?.isMedicareGov) {
      _providerTypes = obj.providerTypes.filter((x) => x.toUpperCase().trim() === PROVIDER_TYPE.VISION);
      this.searchCriteria.standAloneVision = true;
    } else {
      _providerTypes = obj.providerTypes;
    }
    this.searchTerm = obj.name.trim();
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    this._dataService.setPageTitle(pageFadObj);
    const selectedProvider = {
      providerIdentifier: obj.code,
      providerTypeCodeList: _providerTypes,
      providerName: obj.name,
      networkRegisteredName: obj.networkRegisteredName,
      titleList: obj.titleList,
      location: {
        address: obj.address
      },
      pdtKey: obj.pdtKey ? obj.pdtKey : undefined
    } as unknown as ISelectedProvider;
    this.searchCriteria.provNm = this.searchTerm;
    if (_providerTypes && _providerTypes.length > 0) {
      if (_providerTypes.length === 1) {
        this.searchCriteria.typeSelectNm = _providerTypes[0].toUpperCase().trim();
      }
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    }

    this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
    if (this._dataHelper.isEmptyString(this.searchCriteria.coverageTypeCode)) {
      if (_providerTypes && _providerTypes.length > 0) {
        this.searchCriteria.providerTypeCodeList = _providerTypes;
      }
    }

    //Adobe Analytics
    const _searchDetails = {} as IAdobeSearchDetail;
    _searchDetails.provName = this.searchTerm;
    _searchDetails.zip = this.searchCriteria.zip;
    this.setAnalyticsQuery(_searchDetails);

    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      const _numberPattrn = /\#\d+(?:(\d+))?/gi;
      const _match = _numberPattrn.exec(this.searchTerm);
      const _hasMatch = _match && _match.length > 0 ? true : false;
      if (_hasMatch) {
        this.searchCriteria.provNm = this.searchTerm = this.searchTerm.replace(_numberPattrn, '').trim();
      }
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);

      this.resetAppSessionParams();
      if (this.isMemberSecure) {
        this.reloadProviderSummary();
      } else {
        this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
      }
    } else {
      this.resetAppSessionParams();
      this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
    }
    if (this.isMemberSecure && !this._appSession.isEmulation) {
      this.emitLiveChatEvent();
    }
  }

  onOfcSrvcSelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isOfcSrvcSearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;

    if (this.isAoeOfceSrvcForwardLookupEnabled) {
      this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    } else if (obj.providerTypes && obj.providerTypes.length > 0) {
      if (!(this._appSession?.providerTypes?.length && this._appSession.providerTypes.some((prov) => prov.code === PROVIDER_TYPE.OTHER_SERVICES_PROVIDER))) {
        obj.providerTypes = obj.providerTypes.filter((prov) => prov !== PROVIDER_TYPE.OTHER_SERVICES_PROVIDER);
      }
      _providerTypes = obj.providerTypes;
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      if (obj.providerTypes.length === 1) {
        this.searchCriteria.typeSelectNm = obj.providerTypes[0].toUpperCase().trim();
      }
      this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
      this.searchCriteria.providerTypeCodeList = _providerTypes;
    }
    this.searchCriteria.ofcSrvcSelectNm.push(obj.code);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }
  onSearchAllClick() {
    this.showFlyOut = !this.showFlyOut;
    this.optionTypeSelected = this.optionSelected;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  clearAll() {
    this.specialtyMore = false;
    this.specialtyList = [];
    this.specialtyTop5List = [];

    this.aoeMore = false;
    this.aoeList = [];
    this.aoeTop5List = [];

    this.ofcSrvcMore = false;
    this.ofcSrvcList = [];
    this.ofcSrvcTop5List = [];

    this.procedureMore = false;
    this.procedureList = [];
    this.procedureTop5List = [];

    this.providerMore = false;
    this.providerList = [];
    this.providerTop5List = [];

    this.providerNpiMore = false;
    this.providerNpiList = [];
    this.providerNpiTop5List = [];

    this.providerLicenseMore = false;
    this.providerLicenseList = [];
    this.providerLicenseTop5List = [];

    this.showMedicalCode = false;
    this.cptList = [];
    this.showMedicalCodeMoreLess = false;
    this.visibleMedicalCodeCount = THREE;
    this.userIntent = IntentScenario.IGNORE;
  }

  clearSearchCriteria() {
    const _searchCriteria: ISearchParameters = {} as ISearchParameters;
    _searchCriteria.plan = this.searchCriteria.plan;
    _searchCriteria.zip = this.searchCriteria.zip;
    _searchCriteria.stateCd = this.searchCriteria.stateCd;
    _searchCriteria.city = this.searchCriteria.city;
    if (this.searchCriteria.countyCode) {
      _searchCriteria.countyCode = this.searchCriteria.countyCode;
    }
    _searchCriteria.coordinates = this.searchCriteria.coordinates;
    _searchCriteria.typeOfCareNm = this.searchCriteria.typeOfCareNm;
    _searchCriteria.stateDdlValues = this.searchCriteria.stateDdlValues;
    _searchCriteria.planCategoryDdlValues = this.searchCriteria.planCategoryDdlValues;
    _searchCriteria.planDdlValues = this.searchCriteria.planDdlValues;
    _searchCriteria.coverageTypeCode = this.searchCriteria.coverageTypeCode;
    if (this.searchCriteria.identificationNumber) {
      _searchCriteria.identificationNumber = this.searchCriteria.identificationNumber;
    }
    _searchCriteria.typeSelectNm = '';
    _searchCriteria.specialtySelectNm = [];
    _searchCriteria.taxonomySelectNm = [];
    _searchCriteria.providerTypeCodeList = [];
    _searchCriteria.blueDistinctionCenter = [];
    _searchCriteria.categoryDescription = {};
    _searchCriteria.visionSrvcAvailableList = [];
    _searchCriteria.provNm = '';
    _searchCriteria.aoeSelectNm = [];
    _searchCriteria.ofcSrvcSelectNm = [];
    _searchCriteria.nationalProviderIdentifier = '';
    this.searchCriteria.npi = '';
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      _searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    _searchCriteria.includePrimeGroupSearch = true;
    _searchCriteria.eycPrefix = this._appSession?.searchParams?.eycPrefix;
    _searchCriteria.isPrefixStateEnabledForProcSearch = this._appSession?.searchParams?.isPrefixStateEnabledForProcSearch;
    this.searchCriteria = _searchCriteria;
    this._appSession.isSpecialitySearch = false;
    this._appSession.isAoeSearch = false;
    this._appSession.isOfcSrvcSearch = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.isEyc = false;
  }

  formatDistance(distance: string) {
    return distance ? parseFloat(distance).toFixed(2) : '';
  }

  onCancel() {
    this.showFlyOut = false;
  }

  reloadSAVPage() {
    this.reloadSAV.emit(true);
  }

  onApply() {
    this.selected = this.selectedOption();
    this.clearAll();
    this.searchTerm = '';
    this.showFlyOut = false;
  }

  selectedOption() {
    let _selectedItem = '';
    this.optionSelected = this.optionTypeSelected;
    switch (this.optionSelected) {
      case SEARCH_OPTIONS.SEARCH_ALL: {
        _selectedItem = this.content.searchCriteriaComponent.labels.searchAll;
        this.searchWatermark = this.hasEYC ? this.content.searchCriteriaComponent.labels.searchSpecialtyProcedureName : this.content.searchCriteriaComponent.labels.searchSpecialtyName;
        break;
      }
      case SEARCH_OPTIONS.PROCEDURES: {
        _selectedItem = this.content.searchCriteriaComponent.labels.procedures;
        this.searchWatermark = this.content.searchCriteriaComponent.labels.searchLookUpBy.replace(/{CATEGORY}/gi, this.content.searchCriteriaComponent.labels.procedure);
        break;
      }
      case SEARCH_OPTIONS.SPECIALTIES: {
        _selectedItem = this.content.searchCriteriaComponent.labels.specialties;
        this.searchWatermark = this.content.searchCriteriaComponent.labels.searchLookUpBy.replace(/{CATEGORY}/gi, this.content.searchCriteriaComponent.labels.specialty);
        break;
      }
      case SEARCH_OPTIONS.PROVIDER_NAME: {
        _selectedItem = this.content.searchCriteriaComponent.labels.providerName;
        this.searchWatermark = this.content.searchCriteriaComponent.labels.searchLookUpBy.replace(/{CATEGORY}/gi, this.content.searchCriteriaComponent.labels.name);
        break;
      }
      default: {
        _selectedItem = this.content.searchCriteriaComponent.labels.searchAll;
        this.searchWatermark =
          this.hasEYC || this.isColdStateEYC ? this.content.searchCriteriaComponent.labels.searchSpecialtyProcedureName : this.content.searchCriteriaComponent.labels.searchSpecialtyName;
        break;
      }
    }

    //Handle search text box watermark for mobile screen.
    if (document.documentElement && document.documentElement.clientWidth && document.documentElement.clientWidth < 900) {
      this.searchWatermark = this.content.searchCriteriaComponent.search;
    }

    return _selectedItem;
  }

  searchCtrlFocusOut() {
    this.showSpecialtyLoader = true;
    this.showAoeLoader = true;
    this.showOfcSrvcLoader = true;
    this.showProcedureLoader = true;
    this.showSmartSearchLoader = true;
    this.showProviderLoader = true;
    this.clearAll();
    this.getScroll();
  }

  getScroll() {
    if (this.isMemberSecure && (!this._dataHelper.isEmptyString(this.locationCmp?.locationTxt) || !this._dataHelper.isEmptyString(this.locationCmpV1?.locationTxt)) && this.executionCount === 0) {
      document.getElementById('txt-search-field').focus();
      this.executionCount++;
    }
  }

  searchCtrlFocus() {
    this.isRedBorderShow = false;
    if (((!this.locationCmp?.locationTxt && !this.isNewLocationFlowEnable) || (!this.locationCmpV1?.locationTxt && this.isNewLocationFlowEnable)) && this.executionCount !== 0) {
      this.isRedBorderShow = true;
    }
    this.executionCount++;
    !this.isNewLocationFlowEnable ? this.locationCmp?.showRedColorBorder(this.isRedBorderShow) : this.locationCmpV1?.showRedColorBorder(this.isRedBorderShow);
    this.showFlyOut = false;
  }

  searchTypeCtrlFocus() {
    this.searchCtrlFocusOut();
  }

  locationCtrlFocus() {
    this.searchCtrlFocusOut();
    this.searchCtrlFocus();
  }

  isLocationValid() {
    this.isRedBorderShow = false;
    let _isValid = true;
    if (!(this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude !== '' && this.searchCriteria.coordinates.longitude !== '')) {
      _isValid = false;
      if (this.isNewLocationFlowEnable) {
        if (!this.locationCmpV1?.locationTxt) {
          this.isRedBorderShow = true;
          this.locationCmpV1.showRedColorBorder(this.isRedBorderShow);
        } else if (this.locationCmpV1?.locationTxt) {
          this.locationCmpV1.setLocationError();
        }
      }
    }
    return _isValid;
  }

  openModal(modal: string) {
    switch (modal) {
      case MODAL_TYPE.TRANSPORTATION: {
        this.transportationModal.show();
        break;
      }
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.homeHealthCareModal.show();
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.medicareSupplementModal.show();
        break;
      }
      case MODAL_TYPE.GENERIC_MODAL: {
        this.genericModal.show();
        break;
      }
    }
  }

  closeModal(modal: string) {
    switch (modal) {
      case MODAL_TYPE.TRANSPORTATION: {
        this.transportationModal.hide();
        break;
      }
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.homeHealthCareModal.hide();
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.medicareSupplementModal.hide();
        break;
      }
      case MODAL_TYPE.GENERIC_MODAL: {
        this.genericModal.hide();
        break;
      }
    }
  }

  redirectModal(modal: string) {
    let _url = null;

    switch (modal) {
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.closeModal(MODAL_TYPE.HOMEHEALTHCARE);
        _url = this.content.homeHealthCareContent.url;
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.closeModal(MODAL_TYPE.MEDICARE_SUPPLEMENT);
        _url = this.content.medicareSupplementContent.url;
        break;
      }
    }

    if (_url) {
      const winArgs = [_url];
      this._window.open.apply(this._window, winArgs);
    }
  }

  bindProviderType(searchParams: ISearchParameters, appSession: AppSession, excludePharmacy: boolean = true) {
    const _searchParams: ISearchParameters = searchParams;
    _searchParams.standAloneDental = null;
    _searchParams.standAloneVision = null;
    _searchParams.coverageTypeCode = '';
    _searchParams.typeSelectNm = '';
    _searchParams.providerTypeCodeList = [];

    if (appSession) {
      if (appSession.isSecureState) {
        // Handle Secure State
        let _providerTypes: IProviderType[] = [];
        if (excludePharmacy) {
          _providerTypes = this._appSession.providerTypes.filter((x) => x.code !== PROVIDER_TYPE.PHARMACY);
        } else {
          _providerTypes = this._appSession.providerTypes;
        }

        // Medsupp scenario
        if (this._appSession?.planRule && this._appSession?.planRule?.isMedicareGov) {
          _providerTypes = _providerTypes.filter((x) => x.code === PROVIDER_TYPE.VISION);
          _searchParams.standAloneVision = true;
        }

        if (_providerTypes && _providerTypes.length > 0) {
          _searchParams.providerTypeCodeList = _providerTypes.map((provType) => provType.code);
        }

        if (appSession.isDentalStandAlone) {
          _searchParams.standAloneDental = appSession.isDentalStandAlone;
        }
        if (appSession.isVisionStandAlone) {
          _searchParams.standAloneVision = appSession.isVisionStandAlone;
        }
      } else {
        // Handle Public State
        if (appSession.isPharmacyStandAlone) {
          _searchParams.typeSelectNm = PROVIDER_TYPE.PHARMACY;
          _searchParams.providerTypeCodeList.push(PROVIDER_TYPE.PHARMACY);
        } else if (appSession.isDentalStandAlone) {
          _searchParams.standAloneDental = appSession.isDentalStandAlone;
          _searchParams.typeSelectNm = PROVIDER_TYPE.DENTAL;
          _searchParams.providerTypeCodeList.push(PROVIDER_TYPE.DENTAL);
        } else if (appSession.isVisionStandAlone) {
          _searchParams.standAloneVision = appSession.isVisionStandAlone;
          _searchParams.typeSelectNm = PROVIDER_TYPE.VISION;
          _searchParams.providerTypeCodeList.push(PROVIDER_TYPE.VISION);
        } else {
          //adding providerTpe R in cold State lookUP request
          if (this._appSession.providerTypes && this._appSession.providerTypes.length) {
            _searchParams.providerTypeCodeList = this._appSession.providerTypes.map((provType) => provType.code);
          }
        }
      }
    }
    return _searchParams;
  }

  loadProviderSummary() {
    if (this.searchTerm && this.searchCriteria) {
      if (this.searchCriteria.specialtySelectNm && this.searchCriteria.specialtySelectNm.length) {
        //Adobe Analytics
        const _searchDetails = {} as IAdobeSearchDetail;
        _searchDetails.specialityName = this.searchTerm;
        _searchDetails.zip = this.searchCriteria.zip;
        this.setAnalyticsQuery(_searchDetails);

        this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
          (rules: IRulesResponse) => {
            this.invokeActions(rules);
          },
          (error: any) => {
            try {
              this.onCategoryError('RULE', error);
            } catch (e) {}
          }
        );
      } else if (this.searchCriteria?.aoeSelectNm?.length || this.searchCriteria?.ofcSrvcSelectNm?.length) {
        this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
          (rules: IRulesResponse) => {
            this.invokeActions(rules);
          },
          (error: any) => {
            try {
              this.onCategoryError('RULE', error);
            } catch (e) {}
          }
        );
      } else if (this.searchCriteria.eycSearchParam) {
        //Adobe Analytics
        const _searchDetails = {} as IAdobeSearchDetail;
        _searchDetails.procedureName = this.searchTerm;
        _searchDetails.zip = this.searchCriteria.zip;
        this.setAnalyticsQuery(_searchDetails);

        this.resetAppSessionParams(true);
        this.reloadProviderSummary();
      } else if (CommonUtil.isCptSearch(this._appSession)) {
        this.resetAppSessionParams(false);
        this.reloadProviderSummary();
      }
    }
    if (this.isMemberSecure && !this._appSession.isEmulation) {
      this.emitLiveChatEvent();
    }
  }

  printTransportation() {
    window.print();
    return false;
  }

  //Sets the Analytics query for the search criteria - Only on RCP integrated page
  setAnalyticsQuery(data: IAdobeSearchDetail) {
    if (data && window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      // Clear Adobe data query path
      if (window.digitalData.page.search.specialtySearchQuery) {
        window.digitalData.page.search.specialtySearchQuery = '';
      }
      if (window.digitalData.page.search.procedureSearchQuery) {
        window.digitalData.page.search.procedureSearchQuery = '';
      }
      if (window.digitalData.page.search.providerSearchQuery) {
        window.digitalData.page.search.providerSearchQuery = '';
      }

      const _adobePlan = {} as IAdobe;
      _adobePlan.searchDetail = data;
      const _query = CommonUtil.getAnalyticsQuery(_adobePlan);
      if (data.specialityName) {
        window.digitalData.page.search.specialtySearchQuery = _query;
      } else if (data.procedureName) {
        window.digitalData.page.search.procedureSearchQuery = _query;
      } else {
        window.digitalData.page.search.providerSearchQuery = _query;
      }
    }
  }

  resetforHPN() {
    this.searchTerm = this._appSession.searchTerm;
    this.optionTypeSelected = this._appSession.searchOption;
    this.selected = this.selectedOption();
  }

  get transportationLocation() {
    this.getScroll();
    if ((typeof this._transportationLocation === 'undefined' || this._transportationLocation === null) && this._appSession.planRule && this._appSession.planRule.transportationLocation) {
      this._transportationLocation = this._appSession.planRule.transportationLocation;
    }
    return this._transportationLocation;
  }

  showError() {
    return !this.showLoading && this.searchTerm && this.searchTerm.length >= this.inputMinLength;
  }

  navigateToLiveChat() {
    SydneyChatbotEventChannelUtil.publishEventForIntent(this.userIntent, this.searchTerm, this._appSession, this._eventHandler);
  }

  get leavingDisclaimerContent(): string {
    if (this.content && this.content.leavingDisclaimerComponent && this.content.leavingDisclaimerComponent.content1) {
      return this.content.leavingDisclaimerComponent.content1.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get leavingDisclaimerTermsOfUse(): string {
    if (this.content && this.content.leavingDisclaimerComponent && this.content.leavingDisclaimerComponent.content2) {
      return this.content.leavingDisclaimerComponent.content2.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get noResultsText() {
    return this.content.searchCriteriaComponent.labels.noResultsMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  get defaultSearchText() {
    return this.content.searchCriteriaComponent.labels.defaultSearchMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  // Logic to display defaultSearchText info. msg in search bar when there is no match in provider, procedure or specialty against search term
  isProviderMatchFound(): boolean {
    // If any provider is found against search term, returning false
    if (
      (this.providerList && this.providerList.length > 0) ||
      (this.providerLicenseList && this.providerLicenseList.length > 0) ||
      (this.providerNpiList && this.providerNpiList.length > 0) ||
      this.cptList?.length
    ) {
      return false;
    } else {
      let _procedureMatchFound: IProcedureLookup[] = [];
      let _specialtyMatchFound: ISpecialtyLookup[] = [];
      let _aoeMatchFound: IAreaOfExpertise[] = [];
      let _ofcSrvcMatchFound: IOfficeService[] = [];
      // pupulating _procedureMatchFound if procedures in procedureList has a match with search term
      if (this.procedureList.length > 0) {
        _procedureMatchFound = this.procedureList.filter((procedure) => {
          if (procedure.alias.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return procedure;
          }
        });
      }
      // populating _specialtyMatchFound if specialtyList has a match with search term
      if (this.specialtyList.length > 0) {
        _specialtyMatchFound = this.specialtyList.filter((specialty) => {
          if (specialty.alias.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return specialty;
          }
        });
      }
      // populating _aoe if aoeList has a match with search term
      if (this.aoeList.length > 0) {
        _aoeMatchFound = this.aoeList.filter((aoe) => {
          if (aoe.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return aoe;
          }
        });
      }
      // populating _ofcSrvc if ofcSrvcList has a match with search term
      if (this.ofcSrvcList.length) {
        _ofcSrvcMatchFound = this.ofcSrvcList.filter((ofcSrvc) => {
          if (ofcSrvc.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return ofcSrvc;
          }
        });
      }
      // If procedure or specialty has matches with search term, return false
      return !(_procedureMatchFound.length > 0 || _specialtyMatchFound.length > 0 || _aoeMatchFound.length > 0 || _ofcSrvcMatchFound.length);
    }
  }

  get showLoading() {
    if (this._appSession.searchTerm && this._appSession.searchTerm.length >= this.inputMinLength) {
      return (
        this.showSpecialtyLoader ||
        this.showProcedureLoader ||
        this.showProviderLoader ||
        this.showSmartSearchLoader ||
        this.showNpiProviderLoader ||
        this.showLicenseProviderLoader ||
        this.showAoeLoader ||
        this.showOfcSrvcLoader
      );
    }
    return false;
  }

  get suppressAoeAndOfcSrvcLookup(): boolean {
    const careType = !this._dataHelper.isEmptyString(this._appSession?.searchParams?.plan?.careType) ? this._appSession.searchParams.plan.careType : '';
    if (
      [PROVIDER_TYPE_OF_CARE.VISION.toString(), PROVIDER_TYPE_OF_CARE.DENTAL.toString(), PROVIDER_TYPE_OF_CARE.PHARMACY.toString()].includes(careType.toUpperCase()) ||
      this._appSession.isDentalStandAlone ||
      this._appSession.isVisionStandAlone ||
      this._appSession.isPharmacyStandAlone
    ) {
      return true;
    }
    return false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (
      typeof this._elementRef.nativeElement !== 'undefined' &&
      this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'fly-out look-up-container' &&
      !this._appSession.isCaHmoDmhc
    ) {
      this.searchCtrlFocusOut();
      this.searchCtrlFocus();
      if (event.target.className !== 'btn btn-primary' && !this.isLocationValid() && event.target.type !== 'radio') {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
  }

  isNumber(userInput: any): boolean {
    return userInput.match(/^[0-9]+$/) !== null ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target && event.target.screen && event.target.screen.availWidth) {
      this.selectedOption();
      //Handle search text box watermark for mobile screen.
      if (event.target.screen.availWidth < 900) {
        this.searchWatermark = this.content.searchCriteriaComponent.search;
      }
    }
  }

  //Allow if icense number has 2 digit and some text or full of digits
  validateLicenseNumber(input: any) {
    return input.match(/\d{2,}/) !== null ? true : false;
  }

  private invokeActions(rules: IRulesResponse) {
    if (rules) {
      if (rules.showTransportation) {
        this.openModal(MODAL_TYPE.TRANSPORTATION);
      } else if (rules.isMyNexus) {
        if (this._appSession.isSecureState) {
          this.myNexusHeader = this.content.homeHealthCareContent.secureHeader;
          const myNexusSecureContent: Array<IAlertMessage> = this.content?.homeHealthCareSecureContentInfo?.MY_NEXUS_SECURE_CONTENT;
          this.myNexusContent = WcsUtil.getInfoListContent(myNexusSecureContent);
          this.myNexusClose = this.content.homeHealthCareContent.Ok;
        } else {
          this.myNexusHeader = this.content.homeHealthCareContent.publicHeader;
          this.myNexusContent = this.content.homeHealthCareContent.publicContent;
          this.myNexusClose = this.content.homeHealthCareContent.Ok;
        }
        this.openModal(MODAL_TYPE.HOMEHEALTHCARE);
      } else if (rules.showMedicareSupplement) {
        this.openModal(MODAL_TYPE.MEDICARE_SUPPLEMENT);
      } else {
        this.resetAppSessionParams();
        this._appSession.feature = rules;
        this.reloadProviderSummary();
      }
    }
  }

  private createAoeOfceSrvcFwdLookupReq(): IProviderMetadataRequest {
    const zipCode = isEmpty(this.searchCriteria.countyCode) && isEmpty(this.searchCriteria.city) ? this.searchCriteria.zip : undefined;
    const city = isEmpty(this.searchCriteria.countyCode) ? this.searchCriteria.city : undefined;
    const countycode = !isEmpty(this.searchCriteria.countyCode) ? this.searchCriteria.countyCode : undefined;
    const req: IProviderMetadataRequest = {
      searchTerm: this.searchTerm.trim(),
      locale: this.locale,
      location: {
        city: city,
        countyCode: countycode,
        postalCode: zipCode,
        state: this.searchCriteria.stateCd?.code
      }
    };
    return req;
  }

  aoeOfceSrvcFwdLookup(_searchTerm: string) {
    if (this.isAoeOfceSrvcForwardLookupEnabled) {
      if (this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL || this.optionSelected === SEARCH_OPTIONS.OFFICE_SERVICE || this.optionSelected === SEARCH_OPTIONS.AOE) {
        const request = this.createAoeOfceSrvcFwdLookupReq();
        if (
          !this._dataHelper.isEmptyString(request.location.city) ||
          !this._dataHelper.isEmptyString(request.location.countyCode) ||
          !this._dataHelper.isEmptyString(request.location.postalCode) ||
          !this._dataHelper.isEmptyString(request.location.state)
        ) {
          this._providerUtilityService.getProviderMetaDataLookupList(request).then(
            (result: IProviderMetadataResponse) => {
              if (result) {
                if (result?.areaOfExpertises?.length) {
                  this.aoeList = result.areaOfExpertises;
                  this.aoeTop5List = result.areaOfExpertises.slice(0, this.appConfig?.features.lookUp.minCount);
                } else {
                  this.aoeList = [];
                  this.aoeTop5List = [];
                }
                if (result?.officeServices?.length) {
                  this.ofcSrvcList = result.officeServices;
                  this.ofcSrvcTop5List = result.officeServices.slice(0, this.appConfig?.features.lookUp.minCount);
                } else {
                  this.ofcSrvcList = [];
                  this.ofcSrvcTop5List = [];
                }
                this.showOfcSrvcLoader = false;
                this.showAoeLoader = false;
              }
            },
            (error: any) => {
              try {
                this.showOfcSrvcLoader = false;
                this.showAoeLoader = false;
                this.onCategoryError('PROVIDER_METADATA_LOOKUP', error);
              } catch (e) {}
            }
          );
        } else {
          this.showOfcSrvcLoader = false;
          this.showAoeLoader = false;
        }
      } else {
        this.showOfcSrvcLoader = false;
        this.showAoeLoader = false;
      }
    } else {
      // office service search if term is not numeric
      if (
        (this._appSession?.feature?.showOfficeServiceLookup && !this.suppressAoeAndOfcSrvcLookup && this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL) ||
        this.optionSelected === SEARCH_OPTIONS.OFFICE_SERVICE
      ) {
        this._providerUtilityService.getOfcSrvcLookupList({ searchTerm: encodeURI(_searchTerm), locale: this.locale }).then(
          (result: any) => {
            if (result && result.officeServices && result.officeServices.length) {
              this.ofcSrvcList = result.officeServices;
              this.ofcSrvcTop5List = result.officeServices.slice(0, this.appConfig?.features.lookUp.minCount);
            } else {
              this.ofcSrvcList = [];
              this.ofcSrvcTop5List = [];
            }
            this.showOfcSrvcLoader = false;
          },
          (error: any) => {
            try {
              this.showOfcSrvcLoader = false;
              this.onCategoryError('OFFICE_SERVICE_LOOKUP', error);
            } catch (e) {}
          }
        );
      } else {
        this.showOfcSrvcLoader = false;
      }
      // area of expertise search if term is not numeric
      if ((this._appSession?.feature?.showAoeLookup && !this.suppressAoeAndOfcSrvcLookup && this.optionSelected === SEARCH_OPTIONS.SEARCH_ALL) || this.optionSelected === SEARCH_OPTIONS.AOE) {
        this._providerUtilityService.getAoeLookupList({ searchTerm: encodeURI(_searchTerm), locale: this.locale }).then(
          (result: any) => {
            if (result && result.aoes && result.aoes.length) {
              this.aoeList = result.aoes;
              this.aoeTop5List = result.aoes.slice(0, this.appConfig?.features.lookUp.minCount);
            } else {
              this.aoeList = [];
              this.aoeTop5List = [];
            }
            this.showAoeLoader = false;
          },
          (error: any) => {
            try {
              this.showAoeLoader = false;
              this.onCategoryError('AOE_LOOKUP', error);
            } catch (e) {}
          }
        );
      } else {
        this.showAoeLoader = false;
      }
    }
  }

  isCaHmoDmhc() {
    return this._appSession.isCaHmoDmhc;
  }
  openAddressSelectionPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.changePlanSidePanel);
  }

  onClose() {
    this.sidePanelRef.close();
  }

  loadCaHmo() {
    this.loadCahmoPage.emit(true);
  }

  medicalCodeDropDownClick() {
    this.showMedicalCode = !this.showMedicalCode;
  }

  medicalCodeShowMoreLessClick() {
    this.showMedicalCodeMoreLess = !this.showMedicalCodeMoreLess;
    this.visibleMedicalCodeCount = !this.showMedicalCodeMoreLess ? THREE : this.cptList.length;
  }

  updateZipcode(zipcode: string) {
    this.searchParams.postalCode = zipcode;
    this.searchParams.zip = zipcode;
    this.locationCmp.locationTxt = zipcode;
    this.locationCmp.getGeoCoordinates(false);
  }

  //Updating the transportation popup content based on prefix
  get isPrefixAvailable(): boolean {
    return !this._dataHelper.isEmptyString(this._appSession?.searchParams?.plan?.alphaPrefix) && TRANSPORTATION_PREFIX.includes(this._appSession.searchParams.plan.alphaPrefix);
  }

  getContactUsText(): string {
    return CommonUtil.isMemberSecure(this._appSession) ? this.content?.transportationContent?.contactUsSecure : this.content?.transportationContent?.contactUsPublic;
  }

  onMedicalCodeSelect(selectedMedicalCode: ICptCode) {
    this.clearAll();
    this.clearSearchCriteria();
    const { medicalTypeCd, medicalCd } = selectedMedicalCode;
    this.medicalCodeCriteria = {
      billingType: medicalTypeCd,
      medicalCode: medicalCd
    };
    this.searchTerm = selectedMedicalCode.searchDesc?.slice(0, this.inputMaxLength);
    this._appSession.isMedicalCodeSearch = true;
    this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.billingType = medicalTypeCd;
    this._appSession.medicalCode = medicalCd;
    this.searchCriteria.providerTypeCodeList = [];
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  bindSmartSearchRequest(_searchTerm: string): ISmartSearchLookupRequest {
    let _productType = '';
    if (this._appSession.metaData?.appContract) {
      if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.productType && !this._appSession.isPlanUnknown) {
        _productType = this._appSession.metaData.appContract.coverageDetails.productType;
      }
    }
    let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
    _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession, false);
    const _ruleRequest: ISmartSearchLookupRequest = {
      brand: this._appSession.metaData?.brandCd,
      state: this.searchCriteria.plan ? this.searchCriteria.plan.stateCd : '',
      planCategory: this.searchCriteria?.plan?.category?.catCode ? this.searchCriteria.plan.category.catCode : '',
      productType: _productType,
      identification: this.searchCriteria.identificationNumber ? this.searchCriteria.identificationNumber : '',
      locale: this.locale,
      network: this.searchCriteria.plan?.networkList && this.searchCriteria.plan?.networkList?.length > 0 ? this.searchCriteria.plan.networkList : [],
      prefix: CommonUtil.getPrefix(this.searchCriteria, this._appSession),
      contractUid: this._appSession.metaData?.appContract && !this._appSession.isPlanUnknown ? this._appSession.metaData.appContract.contractUid : undefined,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid,
      providerTypeList: _searchCriteria?.providerTypeCodeList || [],
      searchTerm: encodeURI(_searchTerm),
      isPrefixProcSearchEnabled: this.isColdStateEYC
    };
    return _ruleRequest;
  }

  getSmartSearchList(ruleRequest: ISmartSearchLookupRequest) {
    this._smartSearchService.getSmartSearchLookup(ruleRequest).then(
      (result: ISmartSearchLookupResponse) => {
        if (result?.procedures?.length > 0 || result?.specialties?.length > 0) {
          this.procedureList = result.procedures;
          this.procedureTop5List = result.procedures.slice(0, this.appConfig?.features.lookUp.minCount);
          this.specialtyList = result.specialties;
          this.specialtyTop5List = result.specialties.slice(0, this.appConfig?.features.lookUp.minCount);
        } else {
          this.procedureList = [];
          this.procedureTop5List = [];
          this.specialtyList = [];
          this.specialtyTop5List = [];
        }
        if (result?.transportationLocation) {
          this._transportationLocation = result.transportationLocation;
        }
        this.smartSearchRequestId = result?.smartSearchRequestId ?? undefined;
        this.showSmartSearchLoader = false;
      },
      (error: any) => {
        try {
          this.smartSearchRequestId = undefined;
          this.showSmartSearchLoader = false;
          this.onCategoryError('SMART_SEARCH_LOOKUP', error);
        } catch (e) {}
      }
    );
  }

  /**
   * Asynchronously retrieves the intent based on the current search term.
   * If the intent is either `BH_CRISIS` or `BH_NON_CRISIS`, it assigns the intent to `userIntent`.
   * Otherwise, it clears the `userIntent`.
   * In case of an error, it clears the `userIntent` and calls `onCategoryError` with the error details.
   *
   * @returns {Promise<void>} A promise that resolves when the intent lookup is complete.
   */
  async getIntent() {
    if (!this.isIntentEnabled || !this._appSession.hasMedicalContracts) return;
    const searchTerm = {
      query: encodeURI(this.searchTerm)
    };
    try {
      this.userIntent = IntentScenario.IGNORE;
      const response = await this._intentService.getIntentLookup(searchTerm);
      this.userIntent = response.intent;
    } catch (error) {
      this.onCategoryError('getIntent', error);
    }
  }

  setDeeplinkErrorModal(): void {
    if (this._appSession.isDeeplinkErrorMsg && this.content.precareSearchErrorMessages && !this._dataHelper.isEmptyObject(this.content.precareSearchErrorMessages)) {
      this.genericModalTemplate = this.genericModalTemplate ?? ({} as IGenericModalTemplate);
      const genericMessage = this.content.precareSearchErrorMessages.genericMessage;
      if (genericMessage) {
        this.genericModalTemplate.header = !this._dataHelper.isEmptyString(genericMessage.header) ? genericMessage.header : undefined;
        this.genericModalTemplate.close = !this._dataHelper.isEmptyString(genericMessage.close) ? genericMessage.close : undefined;
        this.genericModalTemplate.closeAriaLabel = !this._dataHelper.isEmptyString(genericMessage.closeAriaLabel) ? genericMessage.closeAriaLabel : undefined;
        this.genericModalTemplate.message = WcsUtil.getInfoListContent(genericMessage.message);
      }
    }
  }

  getProviderName(provider: IProvider): string {
    return CommonUtil.getProviderTitles(provider?.titleList, provider?.networkRegisteredName, provider?.name);
  }

  getOutOfNetworkProvidersLinkText(): string {
    if (this.content?.searchCriteriaComponent?.labels?.showNotInNtwrkProvsLink) {
      return this.content?.searchCriteriaComponent?.labels?.showNotInNtwrkProvsLink.replace(/{searchTerm}/gi, this.searchTerm);
    } else {
      return undefined;
    }
  }

  openOONProvidersLearnMore(): void {
    this.oonLearnMoreSlider.openSidePanel();
  }
}
