import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppErrorMsg, VIRTUAL_CARE_HUB } from '../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { IVirtualVisitSSO } from '../../../../common/interfaces/iMessage';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-virtual-connect-now-cmp',
  templateUrl: './virtualConnectNowCmp.html'
})
export class VirtualConnectNowComponent extends BaseComponent implements OnInit {
  @Input() provider: IProvider;
  virtualNavigationButton: string;
  resultContent = this.content?.result?.virtualCareProvider;
  navigationUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationService: NavigationService
    ) {
      super(_route, _eventHandler, _appSession);
  }
  ngOnInit() {
    this.virtualNavigationButton =
      this.provider?.providerName?.includes('Khealth') || this.provider?.providerName?.includes('LHO') ? this.resultContent?.goToVirtualCare : this.resultContent?.connectNow;
  }
  navigateToUrl() {
    if (this.provider?.callToAction?.internalRedirectId) {
      if (this.provider?.callToAction?.internalRedirectId === VIRTUAL_CARE_HUB) {
        this._navigationService?.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH);
      } else if (this.provider?.callToAction?.virtualCareUrl) {
        window.open(this.provider?.callToAction?.virtualCareUrl);
      }
    } else {
      this.navigationUrl = this.provider?.location?.address?.onlineUrl || this.provider?.callToAction?.virtualCareUrl;
      window.open(this.navigationUrl);
    }
  }

  get hasSsoKey() {
    return this._appSession.isSecureState && this._appSession.hasTalkSpaceSSO && this.provider?.callToAction?.hasOwnProperty('ssoKey');
  }

  getVirtualCareUrlSsoConfig(ssoKey: string): IVirtualVisitSSO {
    return {
      errorMessage: AppErrorMsg.SSO_ERROR_MSG,
      iconAfterClasses: '',
      iconBeforeClasses: '',
      linkClasses: 'fcr-sso-connect-now',
      linkText: this.virtualNavigationButton,
      ssoErrorClass: '',
      ssoErrorPageHeader: '',
      ssoErrorRedirectUrl: '',
      ssoErrorType: '2',
      ssoSubType: 'virtualCareSSOURL',
      ssoType: 'sso',
      analyticsText: 'connectNowBtnFindCare',
      imageSource: 'externalIcon.svg',
      ssoKey
    };
  }
}
