export enum PageAlertID {
  PA_COVID_FINDER_ALERT = 'PA_COVID_FINDER_ALERT',
  PA_API_ERROR = 'PA_API_ERROR',
  PA_BEHAVIORAL_ALERT = 'PA_BEHAVIORAL_ALERT',
  PA_BH_SIMPLYMPL = 'PA_BH_SIMPLYMPL',
  PA_CPT = 'PA_CPT',
  PA_CPT_PD_BACK_PROVIDER = 'PA_CPT_PD_BACK_PROVIDER',
  PA_DME_ALERT = 'PA_DME_ALERT',
  PA_DME_SIMPLYMPL = 'PA_DME_SIMPLYMPL',
  PA_EMORY_ALERT = 'PA_EMORY_ALERT',
  PA_EMPTY_REULTS_RESULT_PAGE = 'PA_EMPTY_REULTS_RESULT_PAGE',
  PA_EYEQUEST = 'PA_EYEQUEST',
  PA_GA_ALERT = 'PA_GA_ALERT',
  PA_GA_TERMINATION_PROVIDERS = 'PA_GA_TERMINATION_PROVIDERS',
  PA_GREEN_MEDICAL_BH = 'PA_GREEN_MEDICAL_BH',
  PA_HCS = 'PA_HCS',
  PA_HOME_HEALTH_SIMPLYMPL = 'PA_HOME_HEALTH_SIMPLYMPL',
  PA_HOME_HEALTH_CARE = 'PA_HOME_HEALTH_CARE',
  PA_INCREASED_RADIUS_ALERT = 'PA_INCREASED_RADIUS_ALERT',
  PA_KYPORTAL = 'PA_KYPORTAL',
  PA_LABS_ALERT = 'PA_LABS_ALERT',
  PA_LIBERTY = 'PA_LIBERTY',
  PA_MAGELLAN_PHARMACY = 'PA_MAGELLAN_PHARMACY',
  PA_MARCH = 'PA_MARCH',
  PA_MEMBER_32BJ_ALERT = 'PA_MEMBER_32BJ_ALERT',
  PA_MSSP_ALERT = 'PA_MSSP_ALERT',
  PA_NO_COST = 'PA_NO_COST',
  PA_PLAN_NOT_COVER = 'PA_PLAN_NOT_COVER',
  PA_BENEFIT_DENIED = 'PA_BENEFIT_DENIED',
  PA_NO_OO_RESULT_PAGE = 'PA_NO_OO_RESULT_PAGE',
  PA_NPN_PDF = 'PA_NPN_PDF',
  PA_OON_SEARCH_NO_RESULT = 'PA_OON_SEARCH_NO_RESULT',
  PA_PLAN_NOT_COVERED = 'PA_PLAN_NOT_COVERED',
  PA_PROMOTED_PROCEDURES_ALERT = 'PA_PROMOTED_PROCEDURES_ALERT',
  PA_REFINE_SEARCH = 'PA_REFINE_SEARCH',
  PA_SUPPRESSED_MEDSUPP = 'PA_SUPPRESSED_MEDSUPP',
  PA_TENNCARE_PHARMACY = 'PA_TENNCARE_PHARMACY',
  PA_TRANSPORTATION_ALERT = 'PA_TRANSPORTATION_ALERT',
  PA_UNIMASS_SECURE_EYC_ALERT = 'PA_UNIMASS_SECURE_EYC_ALERT',
  PA_WLP_BH_ALERT = 'PA_WLP_BH_ALERT',
  PA_NO_RESULT_DEFAULT_MSG = 'PA_NO_RESULT_DEFAULT_MSG',
  PA_DNTAQST_FWDHLTH = 'PA_DNTAQST_FWDHLTH',
  PA_LCS = 'PA_LCS',
  PA_DENTAL_PROC_MSG = 'PA_DENTAL_PROC_MSG',
  PA_EycAlert = 'PA_EycAlert',
  PA_SUPERIOR = 'PA_SUPERIOR',
  PA_HEALTHY_BLUE_EYEMED = 'PA_HEALTHY_BLUE_EYEMED',
  PA_SKYGEN = 'PA_SKYGEN',
  PA_TEXCHIP = 'PA_TEXCHIP',
  PA_TEXSTAR = 'PA_TEXSTAR',
  PA_TEXCHIP_PERINATAL = 'PA_TEXCHIP_PERINATAL',
  PA_DELTA = 'PA_DELTA',
  PA_MD_DENTAL = 'PA_MD_DENTAL',
  PA_OHDENTAQUEST = 'PA_OHDENTAQUEST',
  PA_AVESIS = 'PA_AVESIS',
  PA_OHEYEMED = 'PA_OHEYEMED',
  PA_EYEMED = 'PA_EYEMED',
  PA_VSP = 'PA_VSP',
  PA_BARIATRIC_REPLACEMENT_ALERT = 'PA_BARIATRIC_REPLACEMENT_ALERT',
  PA_BARIATRIC_ALERT = 'PA_BARIATRIC_ALERT',
  PA_PLAN_SELECTION = 'PA_PLAN_SELECTION',
  // DISCLAIMERS
  PA_D136 = 'PA_D136',
  PA_D254 = 'PA_D254',
  PA_D262 = 'PA_D262',
  PA_D264 = 'PA_D264',
  PA_D268 = 'PA_D268',
  PA_D284 = 'PA_D284',
  PA_D37 = 'PA_D37',
  PA_MORE_THAN_ONE_VISIT_COST_MESSAGE = 'PA_MORE_THAN_ONE_VISIT_COST_MESSAGE',
  PA_INITIAL_VISIT_COST_MESSAGE = 'PA_INITIAL_VISIT_COST_MESSAGE',
  PA_NON_EMG_SVC = 'PA_NON_EMG_SVC',
  PA_MPLVISION = 'PA_MPLVISION',
  PA_TN_LIBERTY = 'PA_TN_LIBERTY',
  PA_SPCLTY_PHMCY_ALERT = 'PA_SPCLTY_PHMCY_ALERT',
  PA_PUBLICPHARMACY = 'PA_PUBLICPHARMACY',
  PA_WLP_MEDICAL_BH = 'PA_WLP_MEDICAL_BH',
  PA_COUPE_ALERT = 'PA_COUPE_ALERT',
  PA_PRIME_GROUP_SEARCH = 'PA_PRIME_GROUP_SEARCH',
  PA_HEALTH_SYSTEM_MSG = 'PA_HEALTH_SYSTEM_MSG',
  PA_NO_RESULT_SNY_MEMBER = 'PA_NO_RESULT_SNY_MEMBER',
  PA_NO_RESULT = 'PA_NO_RESULT',
  PA_CPT_ALERT = 'PA_CPT_ALERT',
  PA_NON_GA_COLD_STATE_PROC_SEARCH_MSG = 'PA_NON_GA_COLD_STATE_PROC_SEARCH_MSG',
  PA_BH_SEARCH_ASSISTANT = 'PA_BH_SEARCH_ASSISTANT',
  PA_COST_NOT_AVAILABLE_ALERT = 'PA_COST_NOT_AVAILABLE_ALERT'
}
