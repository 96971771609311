import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ISpecialty } from '../../../../fad/provider-details/models/iDetailsResponse';
import { SliderDirection } from '../../../common/constants/common';
import { FindCarePageType } from '../../../common/enums/findCarePages';
import { ProviderCard, ProviderCardNavigation } from '../../constants/result';
import { IProvider } from '../../interfaces/iProvider';
import { IAffiliationProvider } from '../../interfaces/iProviderAffiliation';
import { IDetail } from '../../interfaces/iProviderDetail';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-overview-cmp',
  templateUrl: './providerOverviewCmp.html'
})
export class ProviderOverviewComponent extends BaseComponent {
  page = FindCarePageType.DetailOverview;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() specialtySelected: EventEmitter<ISpecialty> = new EventEmitter<ISpecialty>();
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  overviewContent = this.content?.result?.providerOverview;
  summaryContent = this.content?.result?.summaryComponent;
  resultLabels = this.content?.result?.result?.labels;
  showProviderLoader = true;
  @Input() affiliations: Map<string, IAffiliationProvider[]>;
  isMapView: boolean;
  showScheduleAppointment: boolean = false;
  showGender: boolean = false;
  showEducation: boolean = false;
  isVirtualCareProvider: boolean = false;
  diamondTooltip: string;
  @ViewChild('viewRatingsSlider')
  ratingsSlider: TemplateRef<HTMLElement>;
  ratingsSliderRef: ModalRef<HTMLElement>;
  sliderDirection = SliderDirection;
  ratingsUrl: SafeResourceUrl;
  @Output() isMoreDetailsChange = new EventEmitter<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sanitizer: DomSanitizer,
    private _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['providerDetails']) {
      this.isVirtualCareProvider = this.providerDetails?.isVirtualProvider;
      this.showGender = this.providerDetails?.visibilityRule?.showGender;
      this.showScheduleAppointment = this.providerDetails?.visibilityRule?.showScheduleAppointment;
      this.showEducation = this.providerDetails?.visibilityRule?.showEducation;
    }
  }

  isDetailPageAlert(): boolean {
    return this._appSession.pageAlerts[FindCarePageType.DetailOverview].length > 0;
  }

  /**
   * View PCP List Card Show Event.
   * Emits boolean value true for opening pcpList.
   */
  onViewPCPList() {
    this.cardSelected.emit(ProviderCard.PCP);
  }

  /**
   * View Speciality Card Show Event.
   * Emits boolean value true for opening speciality.
   */
  onShowSpeciality(speciality: ISpecialty) {
    const isMoreDetails = false;
    this.isMoreDetailsChange.emit(isMoreDetails);
    this.cardSelected.emit(ProviderCard.SPECIALITY);
    this.specialtySelected.emit(speciality);
  }

  /**
   * View Recognitions Card Show Event.
   * Emits boolean value true for opening recognitions.
   */
  onShowRecognitions() {
    this.cardSelected.emit(ProviderCard.RECOGNITION);
  }

  /**
   * View Affiliations Card Show Event.
   * Emits boolean value true for opening affiliations.
   */
  onShowAffiliations() {
    this.cardSelected.emit(ProviderCard.AFFILIATION);
  }

  /**
   * Open the side panel
   * @param direction - side panel direction
   */
  openSlider(direction: SliderDirection) {
    this.ratingsUrl = this.getReviewURL();
    this.ratingsSliderRef = this._sidePanel.open(direction, this.ratingsSlider);
  }

  /**
   * Close the side panel
   */
  closeSlider() {
    this.ratingsSliderRef.close();
  }

  getGenderAndEthnicity(): string {
    const gender = this.providerDetails?.gender;
    const ethnicity = this.providerDetails?.ethnicityCodes || [];
    const ethnicityString = ethnicity?.length > 0 ? ethnicity.join(', ') : this.overviewContent.labels.noEthnicity;

    if (gender && ethnicity?.length > 0) {
      return `${this.titleCase(gender)}, ${this.titleCase(ethnicityString)}`;
    }
    if (gender && ethnicity?.length === 0) {
      return `${this.titleCase(gender)}, ${ethnicityString}`;
    }
    if (!gender && ethnicity?.length > 0) {
      return `${this.titleCase(ethnicityString)}, ${this.overviewContent.labels.noGender}`;
    }
    return this.overviewContent.labels.noGenderOrEthnicity;
  }

  getNetworkStatus(status: string): string {
    const plan = this.providerDetails?.providerNetworkStatus?.coverages?.[0];
    const formattedPlan = this.titleCase(plan || '');

    const getStatusLabel = (isSecure: boolean) => {
      const labels = isSecure ? this.summaryContent.networkStatusSecured : this.summaryContent.networkStatus;
      return {
        IN_NETWORK: labels.TP_INNETWORK.replace('{PLANTYPE}', formattedPlan),
        OUT_NETWORK: labels.TP_OUTNETWORK
      };
    };

    const labels = getStatusLabel(this.isSecureState);
    return status === 'OUT_NETWORK' ? labels.OUT_NETWORK : labels.IN_NETWORK;
  }

  checkNum(review: string): boolean {
    return !isNaN(Number(review));
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  isDiamondProvider(): boolean {
    if (!this.providerDetails?.performance?.recognition) {
      return false;
    }
    for (let recognition of this.providerDetails?.performance?.recognition) {
      if (this.titleCase(recognition.name) === this.overviewContent?.diamondCode) {
        this.diamondTooltip = recognition.hoverText;
        return true;
      }
    }
    return false;
  }

  get tooltipContent(): string {
    if (this.providerDetails?.reviewRatings?.[0]?.toolTip) {
      return this.providerDetails.reviewRatings[0].toolTip.replace(/{DATE}/gi, this.providerDetails.reviewRatings[0].lastReviewTime);
    }
    return '';
  }

  getReviewURL(): string {
    let reviewUrl;
    const pdtStrKey = this.providerDetails?.reviewRatings?.[0]?.pdtStrKey;
    if (pdtStrKey) {
      reviewUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + pdtStrKey + '/en');
    }
    return reviewUrl;
  }

  getMapEndpoint(): string {
    const latitude = this.providerDetails?.address?.latitude?.trim();
    const longitude = this.providerDetails?.address?.longitude?.trim();
    return `${this.overviewContent?.mapEndpoint}${latitude}+${longitude}`;
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  preventNavigation(event: Event) {
    if (!this.providerDetails?.address?.latitude) {
      event.preventDefault();
    }
  }
}
