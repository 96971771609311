import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { OUTNETWORK } from '../../../../fad/search-results/values/providerSearchConstants';
import { ProviderCard, ProviderCardNavigation } from '../../constants/result';
import { IProvider } from '../../interfaces/iProvider';
import { IDetail } from '../../interfaces/iProviderDetail';
import { IProviderLocationsResponse } from '../../interfaces/iProviderLocation';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-pharmacy-card-cmp',
  templateUrl: './pharmacyCardCmp.html'
})
export class PharmacyCardComponent extends BaseComponent implements OnChanges {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Input() showSearchResultsFilter: boolean;
  overviewContent = this.content?.result?.providerOverview;
  pharmacyContent = this.content?.result?.pharmacy;
  summaryContent = this.content?.result?.summaryComponent;
  locationContent = this.content?.result?.locationsComponent;
  moreDetailsContent = this.content?.result?.providerMoreDetails;
  showProviderLoader: boolean = true;
  @Input() locations: IProviderLocationsResponse;
  currentDayNumber: number = ((new Date().getDay() + 6) % 7) + 1;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerDetails']) {
      if (changes['providerDetails'].previousValue !== changes['providerDetails'].currentValue) {
        this.showProviderLoader = false;
      }
    }
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  /**
   * Method to show Previous Card State in Provider Tab.
   * Hiding Affiliations List and Showing Provider Card.
   */

  onShowPreviousState(): void {
    this.cardSelected.emit(ProviderCard.OVERVIEW);
  }

  getMapEndpoint(): string {
    const latitude = this.providerDetails?.address?.latitude?.trim();
    const longitude = this.providerDetails?.address?.longitude?.trim();
    return `${this.pharmacyContent?.mapEndpoint}${latitude}+${longitude}`;
  }

  getNetworkStatus(status: string): string {
    const plan = this.providerDetails?.providerNetworkStatus?.coverages?.[0];
    const statusLabels = {
      IN_NETWORK: this.summaryContent.networkStatusSecured.TP_INNETWORK.replace('{PLANTYPE}', this.titleCase(plan || '')),
      OUT_NETWORK: this.summaryContent.networkStatusSecured.TP_OUTNETWORK
    };
    return status === OUTNETWORK ? statusLabels.OUT_NETWORK : statusLabels.IN_NETWORK;
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  /**
   * View Recognitions Card Show Event.
   * Emits boolean value true for opening recognitions.
   */
  onShowRecognitions() {
    this.cardSelected.emit(ProviderCard.RECOGNITION);
  }

  /** Function to print content other than table */
  onPrintCard(): void {
    let screenPrintContainer = document.createElement('div');
    screenPrintContainer.classList.add('provider-card-print-content-container');
    const contentToPrint = document.querySelector('.fcr-pharmacy-card-container');
    if (contentToPrint) {
      screenPrintContainer.appendChild(contentToPrint.cloneNode(true));
      document.body.appendChild(screenPrintContainer);
      window.print();
      document.body.removeChild(screenPrintContainer);
    }
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  /**
   * Method to show PharmacyFeatures Card in Provider Tab.
   */
  onViewAllFeatures() {
    this.cardSelected.emit(ProviderCard.PHARMACY_FEATURES);
  }
}
