import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Locale } from '../../../../common/constants/app-constants';
import { ICostInfo } from '../../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { PHARMACY_TYPE, PROVIDER_CATEGORY_CODE } from '../../../../fad/search-providers/values/providerSearchConstants';
import { IAffliation } from '../../../../fad/search-results/interfaces/iSummaryResp';
import { INNETWORK, OUTNETWORK } from '../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { PROVIDER_TYPE, ProviderFlag } from '../../../common/constants/common';
import { RESULT_CONTENT_EN } from '../../../common/contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../../../common/contents/result/resultContent.es';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { IProvider } from '../../interfaces/iProvider';
import { CompareProvider } from '../../services/compareProvider';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { IOption } from './../../../common/interfaces/iOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-card-cmp',
  templateUrl: './providerCardCmp.html'
})
export class ProviderCardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() provider: IProvider;
  @Input() index: number;
  @Input() isActive!: boolean;
  @Input() mapVisible = true;
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasCostInfo = false;
  comapreProvider: IOption;
  comapreSelectedProviders: IProvider[] = [];
  summaryContent: any;
  provNetworkStatus: string;

  private compareProviderSubscription: Subscription;
  providerMap = new Map<number, IProvider>();
  @Input() providerRemoved: IProvider = null;
  @Input() mapvisible: boolean = false;
  @Input() showProviderCard: boolean = false;
  @Input() clearAll: boolean = false;
  @Output() showProviderCompare: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedPairedProvider = new EventEmitter<IAffliation>();
  resultContent: any;
  hoursToday: string;
  isSecuredPharmacy: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    this.resultContent = this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES : RESULT_CONTENT_EN;
    this.summaryContent = this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent : RESULT_CONTENT_EN.summaryComponent;
    if (this.provider?.costInfo) {
      this.hasCostInfo = true;
    }
    this.comapreProvider = this.getOptionObject(this.provider, this.index);
    this.compareProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
    this.provNetworkStatus = this.provider?.providerNetworkStatus?.statusCode;
    this.hoursToday = this.provider?.location?.address?.open24hours
      ? this.summaryContent.open24Hours
      : this.provider?.location?.address?.officeHours?.length
        ? this.provider?.location?.address?.officeHours.toLocaleString()
        : '';
    this.isSecuredPharmacy = this.provider?.providerTypeCodeList?.includes(PROVIDER_TYPE.PHARMACY) && this.isSecureState;
  }

  getEycCostInfo(provider: IProvider): ICostInfo {
    let eycCostInfo = {} as ICostInfo;
    if (provider?.eycAffliation?.affiliationList?.length) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      if (affFacility?.eycCostInfo) {
        eycCostInfo = affFacility.eycCostInfo;
      }
    } else if (provider?.eycCostInfo) {
      eycCostInfo = provider.eycCostInfo;
    }
    return eycCostInfo;
  }

  providerCardContainerClasses(): object {
    return {
      'provider-card-container fcr-container-spacing-left': true,
      'is-active': this.isActive,
      'fcr-container-spacing-right': !this.mapVisible && !this.showProviderCard,
      'in-network': this.provNetworkStatus === INNETWORK && !this.showVirtualOption(),
      'not-in-network': this.provNetworkStatus !== INNETWORK && !this.showVirtualOption(),
      'out-of-network': this.provNetworkStatus === OUTNETWORK,
      'virtual-provider': this.showVirtualOption() && this.provNetworkStatus !== OUTNETWORK
    };
  }

  ngOnDestroy(): void {
    this.compareProviderSubscription?.unsubscribe();
  }

  getNetworkStatus(resultView?: boolean): string {
    const networktext = CommonUtility.getProviderNetworkStatus(this.provider?.providerNetworkStatus, this._appSession, resultView);
    return networktext ? networktext : '';
  }

  onSendCardStatus(status: boolean): void {
    this.cardSelected.emit(status);
  }

  onCompare(providerObj: IProvider, index: number): void {
    if (this.providerMap.has(index)) {
      this.providerMap.delete(index);
      this._compareProvider.remove(providerObj);
      this.showProviderCompare.emit(false);
    } else {
      this.providerMap.set(index, providerObj);
      this._compareProvider.add(providerObj);
      this.showProviderCompare.emit(true);
    }
  }

  setCompareSelection(providerObj: IProvider, index: number): string {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      if (this.providerMap.has(index)) {
        return value;
      } else {
        return '';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['providerRemoved'] && this.providerRemoved) {
      const providerToRemove = this.providerRemoved;
      this.providerMap.forEach((provider, index) => {
        if (provider.providerIdentifier === providerToRemove.providerIdentifier) {
          this.providerMap.delete(index);
        }
      });
    }
    if (changes['clearAll']) {
      this.providerMap.clear();
    }
  }

  disableSelection(providerIdentifier: string): boolean {
    const provider = this.comapreSelectedProviders.find((option) => option.providerIdentifier === providerIdentifier);
    const isMobile = this.onScreenResize();
    const maxProviders = isMobile ? 2 : 3;
    return (this.comapreSelectedProviders.length >= maxProviders && !provider) || this.providerGreyedOut;
  }

  /***
   *  get the FutureEffectiveProvider value from feature flag config
   * if true, we will enable the details page for future effective provider
   */
  get isFutureEffectiveProviderEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  get providerGreyedOut(): boolean {
    return this.isGreyedOut(this.provider, true);
  }

  private isGreyedOut(provider: IProvider, enableFutureProvider: boolean = true): boolean {
    // Feature flag to disable future effective provider navigation to details view.
    if (provider?.pharmacyType === PHARMACY_TYPE.AHD && provider?.isShipDirectToDoor) {
      return true;
    }

    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }
    return !!(provider?.eycCostInfo?.isGreyedout || (!enableFutureProvider && provider?.isFutureEffective));
  }

  private getOptionObject(providerObj: IProvider, index: number): IOption {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      return {
        value: value,
        id: `input-${value}`,
        name: providerObj.providerName,
        trueValue: value,
        falseValue: ''
      } as IOption;
    }
  }

  // check for virtual provider
  showVirtualOption() {
    return this.provider?.providerFlags?.includes(ProviderFlag.VIRTUAL_PROVIDER);
  }

  showOnlinePharmacy() {
    return this.provider?.providerTypeCodeList?.includes(PROVIDER_TYPE.PHARMACY) && this.provider?.isShipDirectToDoor && !this.provider?.location?.address?.addressOne; // && !this.mapVisible;
  }

  showOnlineProviders() {
    return this.provider?.providerFlags?.includes(ProviderFlag.ONLINE_PROVIDER);
  }

  showAssignedAsPcp() {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.metaData?.appContract?.mbrUid) {
      const provAndAddressId = this.provider?.providerIdentifier + this.provider?.location?.address?.addressId;
      return provAndAddressId === this._appSession?.pcpData[this._appSession?.metaData?.appContract?.mbrUid]?.currentPCP;
    }
  }

  /**
   * Method to check if the provider is assigned as future PCP
   * @returns boolean
   */
  isFuturePCP() {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.appState?.selectedEligibilityProduct?.selectedContract) {
      const activeContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
      const provAndAddressId = this.provider?.providerIdentifier + this.provider?.location?.address?.addressId;
      return this._appSession.pcpData[activeContract.mbrUid]?.futurePCP === provAndAddressId;
    } else {
      return false;
    }
  }

  showAffiliation(provider: IProvider) {
    return (
      this.isEYC &&
      provider &&
      provider.eycAffliation &&
      Array.isArray(provider.eycAffliation.affiliationList) &&
      provider.eycAffliation.affiliationList.length > 0 &&
      provider.eycAffliation.isVisibleAffiliation
    );
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  getPairedProviderLabelAndSwapAddress(code: string): string {
    if (this.provider?.addressSummary) {
      const affliationAddress = this.provider?.eycAffliation?.affiliationList?.[0]?.location?.address;
      if (affliationAddress) {
        this.provider.addressSummary.addressOne = affliationAddress.addressOne;
        this.provider.addressSummary.addressTwo = affliationAddress.addressTwo;
        this.provider.addressSummary.distance = affliationAddress.distance;

        if (affliationAddress.phone) {
          this.provider.addressSummary.phoneNumberList = [affliationAddress.phone];
        }
      }
    }
    if (code) {
      return code === PROVIDER_CATEGORY_CODE.Individual
        ? this._appSession.metaData.locale === Locale.SPANISH
          ? RESULT_CONTENT_ES.summaryComponent.providingCareAt
          : RESULT_CONTENT_EN.summaryComponent.providingCareAt
        : this._appSession.metaData.locale === Locale.SPANISH
          ? RESULT_CONTENT_ES.summaryComponent.performedBy
          : RESULT_CONTENT_EN.summaryComponent.performedBy;
    }
    return '';
  }

  showPairedProviderDetails(event: Event) {
    event.stopPropagation();
    this.selectedPairedProvider.emit(this.provider.eycAffliation.affiliationList[0]);
  }

  showSpfiDisclaimer(data): boolean {
    return data && Object.keys(data).length > 0 && !!data.code;
  }

  getTitleList(titleList: string[]): string {
    return titleList.join(', ');
  }
}
