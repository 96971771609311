import { IProvider } from './iProvider';
export interface IMapInfoboxContext {
  provider?: IProvider;
  providers?: IProvider[];
}

export enum IMapIcon {
  DEFAULT_PUSHPIN = 'DEFAULT_PUSHPIN',
  HOVER_PUSHPIN = 'HOVER_PUSHPIN',
  DEFAULT_CLUSTER = 'DEFAULT_CLUSTER',
  HOVER_CLUSTER = 'HOVER_CLUSTER',
  AFFILIATION_PUSHPIN = 'AFFILIATION_PUSHPIN'
}

export interface IMapLocation {
  latitude: string;
  longitude: string;
  countyLatitude?: string;
  countyLongitude?: string;
}

export enum IMapInfoboxEvent {
  CLOSE = 'CLOSE',
  VIEW_DETAILS = 'VIEW_DETAILS'
}
