import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Locale } from '../../../../../common/constants/app-constants';
import { SidePanelDirections } from '../../../../../common/interfaces/iCptMedicalResponse';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ProviderUtilityService } from '../../../../../fad/search-providers/services/providerUtilitySvc';
import { IHealthwiseBaseCode, IHealthWiseRequest, IHealthWiseResponse } from '../../../../../fad/search-results/interfaces/iSearchRequest';
import { HYPHEN } from '../../../../../fad/search-results/values/providerSearchConstants';
import { IProviderList } from '../../../../result/interfaces/iProvider';
import { CptSummaryModel } from '../../../../result/services/cptSummaryModel';
import { IAlertMessage } from '../../../interfaces/iPageAlert';
import { CommonUtility } from '../../../utilities/commonUtil';
import { BaseComponent } from '../../core/baseCmp';
import { CptSearchSlideOutTabs } from './searchSlideOutConstants';

@Component({
  moduleId: module.id,
  selector: 'app-search-slide-out-cmp',
  templateUrl: './searchSlideOutCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSlideOutComponent extends BaseComponent {
  cptSearchResult: Observable<IProviderList> = this._cptSummaryModel.cptSummaryResponse.pipe(
    map((value: IProviderList) => {
      if (value?.medicalCode) {
        this.medicalCodeHeader = value?.medicalCode?.billingType + HYPHEN + value?.medicalCode?.code;
      }
      return value;
    })
  );
  tabIndex: number = 0;
  @ViewChild('viewSearchSlideOutModal')
  viewSearchSlideOutContentModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement>;
  medicalCodeHeader: string;
  isApiError: boolean = false;
  showhealthwiseLoader: boolean = true;
  isEyc: boolean = false;
  healthwiseContentResp: Array<IHealthwiseBaseCode> = [];
  healthwiseContentObj: IHealthwiseBaseCode = {} as IHealthwiseBaseCode;
  @Input() pageName: string;
  alertList: IAlertMessage[] = [];
  cptData: IProviderList = {} as IProviderList;
  @Input() providersInfo: IProviderList;
  constructor(
    private _sidePanel: SidePanel,
    private _cptSummaryModel: CptSummaryModel,
    private _providerUtilityService: ProviderUtilityService,
    private cd: ChangeDetectorRef,
    route: ActivatedRoute,
    eventHandler: EventHandler,
    private _appSession: AppSession
  ) {
    super(route, eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  getHasCptSearch(): boolean {
    return CommonUtility.isCptSearch(this._appSession);
  }

  openSidePanel(direction: SidePanelDirections, procedureCode?: string, tabIndex?: CptSearchSlideOutTabs) {
    this.tabIndex = tabIndex ?? 0;
    if (procedureCode) {
      this.showhealthwiseLoader = true;
      this.isEyc = true;
      const healthWiseReq: IHealthWiseRequest = {
        procedureCategoryCode: [],
        specialtyCategoryList: [],
        subSpecialtyList: [],
        taxonomyList: [],
        specialtyCategoryFilter: [],
        providerTypeCodeList: []
      };

      if (procedureCode.length > 0) {
        healthWiseReq.procedureCategoryCode = [procedureCode];
      }
      healthWiseReq.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

      this._providerUtilityService.getHealthwiseProcedureAndSpecialityList(healthWiseReq).then(
        (healthWiseResponse: IHealthWiseResponse) => {
          this.showhealthwiseLoader = false;
          if (healthWiseResponse && healthWiseResponse.hwContentDesciptionList && healthWiseResponse.hwContentDesciptionList.length > 0) {
            this.healthwiseContentResp = healthWiseResponse.hwContentDesciptionList;
            this.healthwiseContentObj = healthWiseResponse.hwContentDesciptionList[0];
            if (this.healthwiseContentObj.isHealthwiseContent) {
              this.healthwiseContentObj.description = this.healthwiseContentObj.description.replace(/<h3[^>]*>.*?<\/h3>/g, '');
            }
          } else {
            this.healthwiseContentResp = [];
            this.healthwiseContentObj = null;
          }
        },
        (error: any) => {
          this.showhealthwiseLoader = false;
          this.healthwiseContentResp = [];
          this.healthwiseContentObj = null;
          this.isApiError = true;
        }
      );
    } else if (this.getHasCptSearch()) {
      this.cptSearchResult.subscribe((response: IProviderList) => {
        if (response) {
          this.cptData = response;
        }
      });
    }
    this.cd.detectChanges();
    this.alertList = this._appSession.pageAlerts?.results;
    this.sidePanelRef = this._sidePanel.open(direction, this.viewSearchSlideOutContentModal);
  }

  onClose() {
    this.isApiError = false;
    this.showhealthwiseLoader = true;
    this.healthwiseContentResp = [];
    this.healthwiseContentObj = null;
    this.sidePanelRef?.close();
  }
}
