import { EventEmitter } from '@angular/core';
import { AppExternalCommands } from '../../common/constants/app-constants';
import { AppEvents } from '../../common/enums/appEvents';
import { IntentScenario } from '../../common/enums/intent';
import { IEventDetail } from '../../common/interfaces/iEventDetail';
import { EventHandler } from '../../common/services/eventHandler';
import { AppSession } from '../../common/values/appSession';

export class SydneyChatbotEventChannelUtil {
  public static _pfAppCommandEvent: EventEmitter<IEventDetail> = new EventEmitter<IEventDetail>();

  static publishEventForIntent(userIntent: IntentScenario, searchTerm: string, appSession: AppSession, eventHandler: EventHandler) {
    this._pfAppCommandEvent = eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    switch (userIntent) {
      case IntentScenario.BH_NON_CRISIS:
      case IntentScenario.NON_BH_CRISIS:
        eventDetail.target = AppExternalCommands.CHATBOT_INTERVENTION.CMD;
        eventDetail.message = AppExternalCommands.CHATBOT_INTERVENTION.MSG;
        break;
      case IntentScenario.BH_CRISIS:
      default:
        eventDetail.target = AppExternalCommands.RCP_LIVE_CHAT.CMD;
        eventDetail.message = AppExternalCommands.RCP_LIVE_CHAT.MSG;
        break;
    }
    if (userIntent !== IntentScenario.IGNORE) {
      eventDetail.object = {
        preChatbotHandoverDetails: {
          selectedMbrUid: appSession?.metaData?.appContract?.mbrUid,
          mbrUid: appSession?.appState?.selectedEligibilityProduct?.selectedContract?.mbrUid ? appSession?.appState?.selectedEligibilityProduct?.selectedContract?.mbrUid : '',
          predictedClass: userIntent,
          zipCode: appSession?.addressObj?.homeAddress?.zipCode || '',
          userQuery: searchTerm
        }
      };
    }
    this._pfAppCommandEvent.emit(eventDetail);
  }
}
