import { Injectable } from '@angular/core';
import { Locale } from '../../../../../../common/constants/app-constants';
import { IMemberCriteria } from './../../../../../../common/interfaces/iCommonSearchRequest';
import { IPlan } from './../../../../../../common/interfaces/iSearchStrategyRequest';
import { AppSession } from './../../../../../../common/values/appSession';
import { ISearchParameters } from './../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from './../../../../../../fad/utilities/commonUtil';
import { ISearchBoxRequest, ISearchBoxResponse } from './../../../../interfaces/iSearchBox';
import { UtilityService } from './../../../../services/utilitySvc';

@Injectable({
  providedIn: 'root'
})
export class SearchBoxHandler {
  constructor(private _utilitySvc: UtilityService) {}

  /**
   * Performs a search using the provided app session and query text.
   * @param appSession The application session containing search parameters.
   * @param queryText The query text to search for.
   * @returns A promise that resolves to the provider lookup response.
   */
  async search(appSession: AppSession, queryText: string): Promise<ISearchBoxResponse> {
    try {
      const request: ISearchBoxRequest = this.transformRequest(appSession, queryText);
      return await this._utilitySvc.searchBox(request);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Transforms the app session and query text into a search box request.
   * @param appSession The application session containing search parameters.
   * @param queryText The query text to search for.
   * @returns The search box request object.
   */
  private transformRequest(appSession: AppSession, queryText: string): ISearchBoxRequest {
    const searchBoxRequest = {} as ISearchBoxRequest;
    searchBoxRequest.queryText = encodeURI(queryText);
    searchBoxRequest.locale = appSession.metaData?.locale ?? Locale.ENGLISH;
    const searchParams: ISearchParameters = appSession.searchParams;

    if (searchParams) {
      searchBoxRequest.typeCodes = searchParams.providerTypeCodeList;
      searchBoxRequest.searchTypes = searchParams.searchTypes;

      if (searchParams.plan) {
        searchBoxRequest.planstateCode = searchParams.plan.stateCd ? searchParams.plan.stateCd.trim() : '';
        if (searchParams.plan.alphaPrefix) {
          searchBoxRequest.alphaPrefix = searchParams.plan.alphaPrefix;
        }
        if (searchParams.plan.identifier) {
          const planIdentifier: IPlan = {
            identifier: searchParams.plan.identifier?.trim()
          };
          searchBoxRequest.plans = [planIdentifier];
        }
        if (searchParams.plan.networkList && searchParams.plan.networkList.length) {
          searchBoxRequest.networks = searchParams.plan.networkList;
        }
        if (searchParams.plan.category?.catCode) {
          searchBoxRequest.planCategory = searchParams.plan.category.catCode;
        }
      }

      // Set alphaPrefix for deeplink search
      if (appSession.deeplinkParams?.alphaprefix) {
        searchBoxRequest.alphaPrefix = appSession.deeplinkParams.alphaprefix;
      }
      if (searchParams.standAloneDental) {
        searchBoxRequest.standAloneDental = searchParams.standAloneDental;
      }
      if (searchParams.standAloneVision) {
        searchBoxRequest.standAloneVision = searchParams.standAloneVision;
      }

      searchBoxRequest.brandCode = appSession.metaData?.brandCd;
      searchBoxRequest.includeVirtualProviders = (appSession.hasVirtualUrgentCare && appSession.feature?.customizedFeatures?.showKHealthFeatures) || false;

      const memberCriteria: IMemberCriteria = {};
      if (appSession.metaData && appSession.metaData.appContract) {
        if (appSession.metaData.appContract.mbrUid && !appSession.isPlanUnknown) {
          memberCriteria.mbrUid = appSession.metaData.appContract.mbrUid;
        }
        if (appSession.metaData.appContract.contractUid && !appSession.isPlanUnknown) {
          memberCriteria.contractUid = appSession.metaData.appContract.contractUid;
        }
      }
      if (searchParams.identificationNumber) {
        memberCriteria.identificationNumber = searchParams.identificationNumber;
      }
      if ((CommonUtil.isMemberSecure(appSession) || searchParams?.identificationNumber) && appSession.isFutureCoverage) {
        memberCriteria.isFuturePlan = appSession?.isFutureCoverage;
      }
      searchBoxRequest.memberCriteria = memberCriteria;

      if (searchParams.countyCode) {
        searchBoxRequest.countyCode = searchParams.countyCode;
      }

      searchBoxRequest.latitude = searchParams.coordinates?.latitude.toString();
      searchBoxRequest.longitude = searchParams.coordinates?.longitude.toString();
      if (searchParams.distanceNm && !isNaN(Number(searchParams.distanceNm.trim()))) {
        searchBoxRequest.distance = Number(searchParams.distanceNm);
      }
      if (searchParams.stateCd && searchParams.stateCd.code) {
        searchBoxRequest.state = searchParams.stateCd.code;
      }
      if (searchParams.zip) {
        searchBoxRequest.postalCode = isNaN(Number(searchParams.zip.trim())) ? '' : searchParams.zip.trim();
      }
      if (searchParams.city) {
        searchBoxRequest.city = searchParams.countyCode ? '' : searchParams.city;
      }
      if (searchParams.countyCode) {
        searchBoxRequest.countyCode = searchParams.countyCode;
      }
      searchBoxRequest.isPrefixProcSearchEnabled = appSession.hasColdStateEYC;
    }
    return searchBoxRequest;
  }
}
