import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { isEmpty } from 'lodash';
import { IPcpRule, IPillFilterOrder, IRulesResponse } from '../../../app/fad/rules/interfaces/iRulesResponse';
import { CustomizedFeatures } from '../../../app/fad/rules/models/customizedFeatures';
import { IMap } from '../../../app/fad/search-providers/interfaces/iMap';
import { IProviderCptCodes } from '../../fad/provider-details/models/iProviderCptCodesResponse';
import { IPlanRule } from '../../fad/search-providers/interfaces/iPlanRule';
import { IProviderType } from '../../fad/search-providers/interfaces/iProviderType';
import { ILocation, ISearchParameters } from '../../fad/search-providers/interfaces/iSearchParameters';
import { IMemberPlanProgressResp } from '../../findcare/common/interfaces/iMemberPlanProgressResponse';
import { IPageAlertMessage } from '../../findcare/common/interfaces/iPageAlert';
import { IDeeplink } from '../components/app-secure/models/iDeeplink';
import { IGeoLocation, IGeoLocationInfo, IlocationForm } from '../components/geoLocation/models/geoLocation';
import { IRebrandConfig } from '../interfaces/iBrandConfig';
import { IRecognition } from '../interfaces/iCommonSearchResponse';
import { IPageAlert } from '../interfaces/iScenarioAlert';
import { DataHelper } from '../services/dataHelper';
import { IAddressDetails } from './../../fad/search-providers/interfaces/iAddressForm';
import { ISpecialtyCategory } from './../../fad/search-providers/interfaces/iProvTypeSpecialtyUtility';
import { IGroupAffiliations } from './../../fad/search-results/interfaces/iGroupAffiliations';
import { IAppState, IBootstrapResponse } from './../../findcare/common/interfaces/iBootstrap';
import { IMetadata, IPCP } from './../components/app-secure/models/iMetadata';
import { IFilterChangePlan } from './../components/change-plan/models/iChangePlanParam';
import { AppConstants, BRAND_CODE, Locale } from './../constants/app-constants';
import { AppEvents } from './../enums/appEvents';
import { CoverageStatus, HcidPrefix, IAddressByMember, IAppContract, IAppMetadata } from './../interfaces/iAppMetadata';
import { AppConfig } from './appConfig';

/**
 *  Acts as app session
 */
@Injectable()
export class AppSession {
  constructor(private _dataHelper: DataHelper) {
    this.feature = {} as IRulesResponse;
    this.feature.customizedFeatures = new CustomizedFeatures();
    this.appState = { executionMode: 'UNDEFINED', selectedEligibilityProduct: {}, features: [], flags: [], opsIndicator: {}, opsState: {} } as IAppState;
  }

  bootstrap: IBootstrapResponse;
  appState: IAppState;
  appConfig: AppConfig;
  metaData: IAppMetadata = {
    brandCd: 'ABCBS',
    brandName: 'Anthem',
    integratedBrandCode: '',
    mbrUid: '',
    contractUid: '',
    locale: Locale.ENGLISH,
    appContract: {} as IAppContract,
    pfEmMode: false,
    token: '',
    metaSrcEnv: '',
    isBrowserNavigationEnabled: false
  };
  deeplinkParams = {} as IDeeplink;
  isSecureState: boolean = false;
  waitUntilAppReLoad: boolean = false;
  searchParams: ISearchParameters;
  hasAppInitialized: boolean = false;
  isEycMaintenanceEnabled?: boolean = false;
  hasEYC: boolean = false;
  hasCostTransparency: boolean = false;
  hasDentalEYC: boolean = false;
  initialSearch: boolean = false;
  clientCoordinates: IMap; //browser location coordinates
  /** @deprecated Stop using this property and move to appSession > appState > opsState > isFtsActive */
  isFtsFeatureLive: boolean = false; //When user has switched to beta FreeTxt search.
  provDataModifiedOn: string = '';
  isWebView: boolean = false;
  increasedRadius?: boolean = false;
  captchaToken: string = '';
  hasPersonalizedMatch?: boolean = false;
  pageAlerts?: IPageAlertMessage = {
    landing: [],
    results: [],
    details: [],
    detailsCostEstimate: []
  };
  rebrandConfig = {} as IRebrandConfig;
  modalName?: string = '';
  appEvent?: AppEvents;
  defaultLocation?: any = null;
  removedTaxonomies: string;
  dos: string = ''; //DENIAL OF  SERVICE code

  // FIXME: Remove properties below if unused or could be used with information available in appState or other properties.
  isCareProvider: boolean = false;
  filterSearchParams: ISearchParameters;
  feature: IRulesResponse;
  isEyc: boolean = false;
  isEycbanner?: boolean = false;
  isHcidSearch: boolean = false;
  isEmulation: boolean = false;
  isIntegratedMode: boolean = false;
  isDeepLink: boolean = false;
  selectedProvider: any;
  costDescription?: string;
  costDisclaimers: any;
  isDentalStandAlone: boolean = false;
  isVisionStandAlone: boolean = false;
  isPharmacyStandAlone: boolean = false;
  planRule: IPlanRule;
  providerTypes?: Array<IProviderType>;
  isFirstTimeLoad: boolean = true;
  isIdentifactionSearch: boolean = false;
  hasSydneyBenefits: boolean = false;
  hasRxCvsIndicator: boolean = false;
  hasRxEsiIndicator: boolean = false;
  hasPharmacyPlan: boolean = false;
  hasLHO?: boolean = false;
  hasEngagement: boolean = false;
  hasHealthWellness: boolean = false;
  isMyNexus?: any = null;
  showPrefixPopup: boolean = true;
  isCPTCodeSearch: boolean = false;
  oopEligible: boolean = false;
  integratedSearch?: boolean = false;
  pageNumber?: string;
  useSecureUrl: boolean = false;
  hasCoupeHealth: boolean = false;
  get state(): string {
    return this.useSecureUrl === true ? 'secure' : 'public';
  }
  pcpIndicator: string;
  pcpProductType: string;
  sourceSystem: string;
  isOfficeVisitCost?: boolean = false;
  hasANPEligibleSourceSystem?: boolean;
  isChangePlan: boolean = false;
  isChangePCP: boolean = false;
  changePlanMetaData = {} as IMetadata;
  filterChangePlan: IFilterChangePlan;
  isPlanUnknown: boolean = false;
  landingTab: number = 0;
  searchTerm?: string;
  medicalCodeDescription?: string;
  isSearchTerm?: boolean = false;
  searchOption?: string;
  hospSearchTerm?: string;
  medicalGrpSearchTerm?: string;
  isMedicalAffSearch?: boolean;
  isHospAffSearch?: boolean;
  pcpRule?: IPcpRule;
  pillFilterOrder?: IPillFilterOrder;
  preConfigureSearchRule?: any;
  isFutureCoverage?: boolean;
  mobileOS: string = '';
  encryptedUserName?: string = '';
  encryptedGroupId?: string = '';
  isSpecialitySearch: boolean = false;
  isMedicalCodeSearch: boolean = false;
  isAoeSearch: boolean = false;
  isOfcSrvcSearch: boolean = false;
  primeGroupAffiliations: IGroupAffiliations;
  isPrimeGroupSearch: boolean = false;
  hasMedicalPlan?: boolean = false;
  addressObj?: IAddressDetails = {};
  isMemberMedicalGrpSearch?: boolean = false;
  customAddressSearch?: boolean = false;
  isCaHmoDmhc?: boolean = false;
  showSAVPage?: boolean = false;
  mbu?: string;
  underwritingStateCode?: string;
  alphaPrefixStateCode?: string = '';
  hasColdStateEYC: boolean = false;
  backToFindCareClicked: boolean = false;
  networkList?: string[];
  hasMedicalContracts?: boolean = false;
  hasStandaloneDentalContract?: boolean = false;
  hasStandaloneVisionContract?: boolean = false;
  personalizedSessionId?: string = '';
  hasVPC?: boolean = false;
  hasVirtualUrgentCare?: boolean = false;
  hasTelehealth?: boolean = false;
  hasVirtualCareHubWeb?: boolean = false;
  hasVirtualWebPage?: boolean = false;
  hasTalkSpaceSSO?: boolean = false;
  hasFindCareFts?: boolean = false;
  isKHealthModalOpen?: boolean = false;
  hasHealthSystem?: boolean = false;
  hasSentMemberInfoToKHealth?: boolean = false;
  serviceAreaValidated?: boolean = false;
  groupId?: string = '';
  subGroupId?: string = '';
  scenarioAlerts?: IPageAlert[] = [];
  billingType?: string = '';
  medicalCode?: string = '';
  medicalModifierOptionCode?: string = '';
  medicalPosCode?: string = '';
  pcpData?: IPCP;
  isOnlyAnpProvider?: boolean = false;
  isDeeplinkErrorMsg?: boolean = false;
  coverageUid?: string = '';
  specialtyCategories: Array<ISpecialtyCategory>;
  location?: ILocation = undefined;
  selectedCodeStatus?: boolean;
  selectedPosCode?: string;
  selectedModifierCode?: string;
  geolocationInfo: IGeoLocation = {};
  memberAddressDetails: IGeoLocationInfo = {};
  memberPlanProgressDetails = {} as IMemberPlanProgressResp;
  selectLocationForm: IlocationForm = {};
  navigationStackCount?: number = 0;
  navigationStack?: { commands: string[]; extras?: NavigationExtras }[] = [];
  isGlobalBackBtnHidden?: boolean = false;
  isLocationDeepLinkFlow?: boolean = false;
  addressesByMember: IAddressByMember = {} as IAddressByMember;
  isSpecialtyOfficeVisitCost?: boolean = false;
  providerTypeCodes: string[];
  adpPgmCode?: IRecognition = {
    code: ''
  };
  selectedCptInfo: IProviderCptCodes = {
    billingType: '',
    medicalCode: {
      code: '',
      name: '',
      billingType: '',
      description: ''
    }
  };
  openDetailPopup?: boolean;
  userIntent?: string = '';

  /**
   * Do not change the scope of _hcidPrefix and _hcidPrefixList.
   * Use getHcidPrefix method to identify prefix
   */
  private _hcidPrefix?: string;
  private _hcidPrefixList: Array<HcidPrefix>;

  resetAppSession() {
    this.metaData.brandCd = BRAND_CODE.ABCBS;
    this.metaData.brandName = 'Anthem';
    this.metaData.mbrUid = '';
    this.metaData.integratedBrandCode = '';
    this.metaData.contractUid = '';
    this.metaData.locale = Locale.ENGLISH;
    this.metaData.appContract = {} as IAppContract;
    this.metaData.pfEmMode = false;
    this.metaData.token = '';
    this.metaData.metaSrcEnv = '';
    this.hasAppInitialized = false;
    this.appState = { executionMode: 'UNDEFINED', selectedEligibilityProduct: {}, features: [], flags: [], opsIndicator: {}, opsState: {} } as IAppState;
    this.searchParams = {} as ISearchParameters;
    this.isEyc = false;
    this.hasPersonalizedMatch = false;
    this.isOfficeVisitCost = false;
    this.isSecureState = false;
    this.isEmulation = false;
    this.isIntegratedMode = false;
    this.deeplinkParams = {} as IDeeplink;
    this.isDeepLink = false;
    this.selectedProvider = undefined;
    this.costDescription = undefined;
    this.costDisclaimers = undefined;
    this.provDataModifiedOn = '';
    this.isDentalStandAlone = false;
    this.isVisionStandAlone = false;
    this.isPharmacyStandAlone = false;
    this.planRule = undefined;
    this.providerTypes = [];
    this.isFirstTimeLoad = true;
    this.isIdentifactionSearch = false;
    this.sourceSystem = '';
    this.hasANPEligibleSourceSystem = false;
    this.hasEYC = false;
    this.hasCostTransparency = false;
    this.hasDentalEYC = false;
    this.hasSydneyBenefits = false;
    this.hasRxCvsIndicator = false;
    this.hasRxEsiIndicator = false;
    this.hasPharmacyPlan = false;
    this.hasLHO = false;
    this.hasEngagement = false;
    this.hasHealthWellness = false;
    this.showPrefixPopup = true;
    this.initialSearch = false;
    this.oopEligible = false;
    this.integratedSearch = false;
    this.useSecureUrl = false;
    this.pcpIndicator = '';
    this.pcpProductType = '';
    this.isWebView = false;
    this.appEvent = undefined;
    this.isChangePlan = false;
    this.isChangePCP = false;
    this.isPlanUnknown = false;
    this.filterChangePlan = {} as IFilterChangePlan;
    this.changePlanMetaData = {} as IMetadata;
    this.searchTerm = undefined;
    this.medicalCodeDescription = undefined;
    this.searchOption = undefined;
    this.isCareProvider = false;
    this._hcidPrefix = '';
    this._hcidPrefixList = [];
    this.isFutureCoverage = false;
    this.mobileOS = '';
    this.encryptedUserName = '';
    this.encryptedGroupId = '';
    this.isEycMaintenanceEnabled = false;
    this.isEycbanner = false;
    this.primeGroupAffiliations = {} as IGroupAffiliations;
    this.isPrimeGroupSearch = false;
    this.hasMedicalPlan = false;
    this.addressObj = {} as IAddressDetails;
    this.isMemberMedicalGrpSearch = false;
    this.isCaHmoDmhc = false;
    this.customAddressSearch = false;
    this.alphaPrefixStateCode = '';
    this.hasColdStateEYC = false;
    this.backToFindCareClicked = false;
    this.networkList = [];
    this.hasMedicalContracts = false;
    this.hasStandaloneDentalContract = false;
    this.hasStandaloneVisionContract = false;
    this.hasVPC = false;
    this.hasVirtualUrgentCare = false;
    this.hasTelehealth = false;
    this.hasVirtualCareHubWeb = false;
    this.hasVirtualWebPage = false;
    this.hasTalkSpaceSSO = false;
    this.isKHealthModalOpen = false;
    this.increasedRadius = false;
    this.isHcidSearch = false;
    this.hasSentMemberInfoToKHealth = false;
    this.serviceAreaValidated = false;
    this.groupId = '';
    this.subGroupId = '';
    this.pcpData = {};
    this.isDeeplinkErrorMsg = false;
    this.scenarioAlerts = [];
    this.coverageUid = '';
    this.isFtsFeatureLive = false;
    this.location = undefined;
    this.rebrandConfig = {} as IRebrandConfig;
    this.memberAddressDetails = {};
    this.memberPlanProgressDetails = {} as IMemberPlanProgressResp;
    this.selectLocationForm = {};
    this.geolocationInfo = {};
    this.metaData.isBrowserNavigationEnabled = (window as Window)['enablePfCustomNavigation'];
    this.navigationStackCount = 0;
    this.navigationStack = [];
    this.isGlobalBackBtnHidden = false;
    this.isLocationDeepLinkFlow = false;
    this.addressesByMember = {} as IAddressByMember;
    this.isSpecialtyOfficeVisitCost = false;
    this.adpPgmCode = {} as IRecognition;
    this.pageAlerts = {
      landing: [],
      results: [],
      details: [],
      detailsCostEstimate: []
    };
    this.providerTypeCodes = [];
    this.hasCoupeHealth = false;
    this.dos = '';
  }

  /**
   * Determines whether to navigate to the landing page based on the current state and search parameters.
   * @returns True if the navigation should go to the landing page, otherwise false.
   */
  get navigateToLanding(): boolean {
    const isPublicStateWithPlan = !this.isSecureState && !isEmpty(this.searchParams?.plan);
    const isSecureStateWithKnownPlan = this.isSecureState && this.searchParams?.plan?.securePlanLabelKey !== AppConstants.PlanUnknown;

    return isPublicStateWithPlan || isSecureStateWithKnownPlan;
  }

  /**
   * return hcid prefix for given coverage status or based on coverage/contract selected in change plan
   * @param coverageStatus
   */
  getHcidPrefix(coverageStatus?: CoverageStatus): string {
    let hcidPrefix: string = '';

    if (coverageStatus) {
      hcidPrefix = this.getHcidPrefixForCoverage(coverageStatus);
    } else {
      if (this.isFutureCoverage) {
        hcidPrefix = this.getHcidPrefixForCoverage(CoverageStatus.PENDING);
      } else if (!this.isFutureCoverage) {
        hcidPrefix = this.getHcidPrefixForCoverage(CoverageStatus.ACTIVE);
      }
    }

    return hcidPrefix;
  }

  /**
   * set hcid prefix to app session variable
   * TODO: this property can be remove once member-summary/contracts API stop sending the same
   */
  set hcidPrefix(prefix: string) {
    this._hcidPrefix = prefix;
  }

  /**
   * set hcid prefixes to app session variable
   */
  set hcidPrefixList(prefixList: Array<HcidPrefix>) {
    this._hcidPrefixList = prefixList;
  }

  /**
   * return hcid prefix for given coverage status
   * @param coverageStatus
   */
  private getHcidPrefixForCoverage(coverageStatus: CoverageStatus): string {
    return (
      (
        (this._hcidPrefixList || []).find((prefix: HcidPrefix) => {
          return this._dataHelper.areEqualStrings(prefix.coverageStatus, coverageStatus);
        }) || ({} as HcidPrefix)
      ).hcidPrefix || ''
    ).trim();
  }

  /**
   * Get loggedin user's Full Name
   * @returns string
   */
  getMemberName(): string {
    const mbrUid = this.metaData?.appContract?.mbrUid;
    if (!mbrUid) {
      return '';
    }

    const mbrList = this.metaData.appContract.memberList ?? [];
    const mbr = mbrList.find((m) => {
      return m.mbrUid === mbrUid;
    });

    if (!mbr) {
      return '';
    }

    let mbrName = `${mbr.firstName} ${mbr.lastName}`;
    return mbrName;
  }

  resetGeoLocation() {
    this.memberAddressDetails = {};
    this.selectLocationForm = {};
    this.geolocationInfo = {};
    this.addressesByMember = {} as IAddressByMember;
  }
}
