import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { BenefitType, BenefitTypeKey, MemberPlanProgressLabels_en, MemberPlanProgressLabels_es, VendorUrl } from '../constants/memberPlanProgressConstants';
import { FormattedMbrPlanProgress, FormattedMbrPlanProgressList, IMemberPlanProgressResp } from '../interfaces/iMemberPlanProgressResponse';

@Pipe({
  name: 'transformMemberPlanProgress'
})
@Injectable()
export class TransformMemberPlanProgressPipe implements PipeTransform {
  transform(result: IMemberPlanProgressResp, content: any, locale?: string): FormattedMbrPlanProgressList {
    const formattedMbrPlanProgressList: FormattedMbrPlanProgressList = [];
    if (Object.keys(result)?.length > 0) {
      Object.keys(result).forEach((key) => {
        const benefitData = result[key];
        const externalVendor = benefitData.externalVendor;
        if (!externalVendor && !isNaN(Number(benefitData.limit))) {
          const label = locale && locale === Locale.ENGLISH ? MemberPlanProgressLabels_en[key] : MemberPlanProgressLabels_es[key];
          formattedMbrPlanProgressList.push({
            type: BenefitType.BENEFIT,
            label,
            spent: benefitData.spent,
            valueType: benefitData.valueType,
            limit: benefitData.limit,
            ...(key.startsWith(BenefitTypeKey.SPENDING) ? { available: benefitData.remaining } : { remaining: benefitData.remaining })
          } as FormattedMbrPlanProgress);
        }
      });
    }

    if (!Object.keys(result).length) {
      return [];
    }

    if (!Object.keys(result).some((key) => key.startsWith(BenefitTypeKey.FAMILY) || key.startsWith(BenefitTypeKey.IND))) {
      formattedMbrPlanProgressList.push({ type: BenefitType.ERROR, message: content.result.memberPlanProgress.planProgressInfo });
    }

    if (Object.keys(result)?.length > 0) {
      Object.keys(result).forEach((key) => {
        const benefitData = result[key];
        const externalVendor = benefitData.externalVendor;
        if (externalVendor) {
          const accountDesc = locale && locale === Locale.ENGLISH ? MemberPlanProgressLabels_en[key] : MemberPlanProgressLabels_es[key];
          const accountType = accountDesc.replace(/\s*\(.*?\)\s*/g, '') ?? '';
          const labelKey = key + '_' + externalVendor;
          const label = locale && locale === Locale.ENGLISH ? MemberPlanProgressLabels_en[labelKey] : MemberPlanProgressLabels_es[labelKey];
          if (label) {
            formattedMbrPlanProgressList.push({
              type: BenefitType.EXTERNALBENEFIT,
              label,
              message: content?.result?.memberPlanProgress?.vendorAdminstratedAccountInfo.replace(/{account}/gi, accountType).replace(/{vendorUrl}/gi, VendorUrl[externalVendor])
            } as FormattedMbrPlanProgress);
          }
        }
      });
    }
    return formattedMbrPlanProgressList;
  }
}
