import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { IDeeplink } from '../../../common/components/app-secure/models/iDeeplink';
import { ChangePlanService } from '../../../common/components/change-plan/services/changePlanSvc';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../common/enums/appEvents';
import { IAppContract } from '../../../common/interfaces/iAppMetadata';
import { IRule } from '../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../common/services/eventHandler';
import { GeocodeApi } from '../../../common/services/geocodeApi';
import { NavigationService } from '../../../common/services/navigationService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IDetailsResponse, IProviderDetail } from '../../../fad/provider-details/models/iDetailsResponse';
import { IGroupAffiliations } from '../../../fad/search-results/interfaces/iGroupAffiliations';
import { IHealthwiseAdobeAnalyticsObj, ISpecialty } from '../../../fad/search-results/interfaces/iSummaryResp';
import { Email, Print, ResultActionType, ResultActions } from '../../../fad/search-results/values/resultActions';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { EmailComponent } from '../../common/components/shareable/email/emailCmp';
import { ViewHealthwiseComponent } from '../../common/components/shareable/healthwise/healthwiseCmp';
import { GenericAlertNavigationService } from '../../common/components/shareable/modal/services/genericAlertNavigationSvc';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../common/components/shareable/page-alert/service/pageAlertSvc';
import { SearchHeaderService } from '../../common/components/shareable/search-header/services/searchHeaderSvc';
import { SearchSlideOutComponent } from '../../common/components/shareable/search-slide-out/searchSlideOutCmp';
import { CptSearchSlideOutTabs } from '../../common/components/shareable/search-slide-out/searchSlideOutConstants';
import { MAX_DESK_RESPONSIVE_WIDTH_M, PROVIDER_TYPE, ProviderFlag, SliderDirection } from '../../common/constants/common';
import { FindCareFeature } from '../../common/enums/findCareFeature';
import { FindCarePageType } from '../../common/enums/findCarePages';
import { PageAlertID } from '../../common/enums/pageAlertId';
import { IResultPageAlertProps } from '../../common/interfaces/iPageAlert';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
import { IPlanRule } from '../../common/interfaces/iRulesParameters';
import { ISearchParameters } from '../../common/interfaces/iSearchParameters';
import { ISearchRequest } from '../../common/interfaces/iSearchRequest';
import { ActionService } from '../../common/services/actionSvc';
import { PublicTccMedicalAdapterSvc } from '../../common/services/publicTccMedicalAdapterSvc';
import { SecureCptAdapterSvc } from '../../common/services/secureCptAdapterSvc';
import { SecureDentalProcedureAdapterSvc } from '../../common/services/secureDentalProcedureAdapterSvc';
import { SecureProviderCptOfficeVisitCostSvc } from '../../common/services/secureProviderCptOfficeVisitCostSvc';
import { SecureRxAdapterSvc } from '../../common/services/secureRxAdapterSvc';
import { SecureTccMedicalAdapterSvc } from '../../common/services/secureTccMedicalAdapterSvc';
import { SpecialtySearchSvc } from '../../common/services/specialtySearchSvc';
import { CommonUtility } from '../../common/utilities/commonUtil';
import { SortOptionHandler } from '../classes/sortOptionHandler';
import { HYPHEN, PDF_FILE_NAME, ProviderCardNavigation, UNDERSCORE } from '../constants/result';
import { IMapLocation } from '../interfaces/iMap';
import { IProvider, IProviderList } from '../interfaces/iProvider';
import { IProviderLocationAddress } from '../interfaces/iProviderLocation';
import { AppliedSearchFilter } from '../services/appliedSearchFilter';
import { CompareProvider } from '../services/compareProvider';
import { PrimeGroupFilter } from '../services/primeGroupFilter';
import { ProviderDetail } from '../services/providerDetail';
import { AffiliationUtility } from '../utilities/affiliationUtil';
import { GEO_LOCATION_SEARCH_TYPE, IGeoLocation } from './../../../common/components/geoLocation/models/geoLocation';
import { SearchSvc } from './../../../common/services/searchSvc';
import { FtsAvailableSuggestionsComponent } from './freeTextSearchResultSummary/ftsAvailableSuggestions';
import { ProviderMapComponent } from './providerMap/providerMapCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-result-cmp',
  templateUrl: './resultCmp.html'
})
export class ResultComponent extends BaseComponent implements OnInit, OnDestroy {
  [x: string]: any;
  @ViewChild('providerMap') providerMap: ProviderMapComponent;
  page = FindCarePageType.Results;
  @ViewChild('fcEmailmodalNew') emailModal: EmailComponent;
  @ViewChild('ftsAvailableSuggestion')
  ftsAvailableSuggestionCmp: FtsAvailableSuggestionsComponent;

  providers: IProvider[] = [];
  ruleList: IRule[] = [];
  selectedProvider: IProvider;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  mapVisible: boolean = true;
  showLoading: boolean = false;
  showLoadProviders: boolean = false;
  searchClickSubscription: Subscription;
  private readonly changePlanSaveClickSubscription: Subscription;
  @ViewChild('searchSlideOutContentModal')
  searchSlideOutContentModal: SearchSlideOutComponent;
  currentIndex: number = 0;
  showProviderCard: boolean = false;
  isCptSearch: boolean = false;
  closeProviderCard: boolean = false;
  showSearchResultsFilter: boolean = false;
  providerCardNavigation: ProviderCardNavigation = ProviderCardNavigation.PROVIDER;
  resultContent = this.content?.result?.result;
  detailsResponse: IProviderDetail;
  resultCountText = this.content?.result?.pagination?.resultCountText;
  totalPages: number;
  selectedPageNumber: number = 1;
  totalRecords: number;
  totalRecordsOverall: number;
  totalPagesOverall: number;
  comapreSelectedProviders: IProvider[] = [];
  isOONSearch: boolean = false;
  distance: string = '';
  showPdfAndEmail: boolean = false;
  showPageProgress: boolean = false;
  isVitalsRequired: boolean = false;
  selectedPushpinProvider: IProvider;
  closeVirtualOptions: boolean = false;
  allProviders: IProvider[] = [];
  virtualProviders: IProvider[] = [];
  apiInfo: IProviderList;
  providerRemoved: IProvider = null;
  clearAllProviders: boolean = false;
  isAffiliationFlow: boolean = false;
  isLocationFlow: boolean = false;
  locations: IProviderLocationAddress[];
  showLocationLink: boolean = true;
  onlyVCProvidersAvailable: boolean = false;
  compareSelectionVisible: boolean = false;
  private initializeSortOption: boolean = true;
  showCptAlert: boolean = false;
  showVirtualOptionInMap: boolean = true;
  costRange: string = '';
  showPrimeGroupDisclaimer: boolean = false;

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: true
  };
  showAboutYourSearch: boolean = false;
  hasLocation: boolean;
  showMemberPlanProgress: boolean = true;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  private showProviderCardSubscription: Subscription;
  private isCaHmoDmhc: boolean;
  private initialSearchParams?: ISearchParameters;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _specialtySearchSvc: SpecialtySearchSvc,
    private _geocodeApi: GeocodeApi,
    private _securePharmacySvc: SecureRxAdapterSvc,
    private _searchHeaderService: SearchHeaderService,
    @Inject(ActionService)
    private _actionService: ActionService,
    private searchService: SearchSvc,
    private _secureTccMedicalSvc: SecureTccMedicalAdapterSvc,
    private _secureTccDentalSvc: SecureDentalProcedureAdapterSvc,
    private _publicTccMedicalSvc: PublicTccMedicalAdapterSvc,
    private _secureCptMedicalSvc: SecureCptAdapterSvc,
    private _providerDetail: ProviderDetail,
    private _providerOfcVisitCostSvc: SecureProviderCptOfficeVisitCostSvc,
    @Inject(AppUtility)
    private _appUtility: AppUtility,
    private _secureRxSvc: SecureRxAdapterSvc,
    private _infoListSvc: InfoListService,
    private _renderer: Renderer2,
    private _el: ElementRef,
    private _compareProvider: CompareProvider,
    private _pageAlertSvc: PageAlertSvc,
    private _appliedFilter: AppliedSearchFilter,
    private _changePlanService: ChangePlanService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _sortOption: SortOptionHandler,
    private _navigationService: NavigationService,
    private _primeGroup: PrimeGroupFilter,
    private genericAlertNavigationService?: GenericAlertNavigationService
  ) {
    super(_route, _eventHandler, _appSession);
    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (this._contentHelper.isSearchResultRoute(path)) {
        this._appSession.initialSearch = true;
        this.ngOnInit();
      }
    });
    this.genericAlertNavigationService.alertNavigationSubjectListener().subscribe((alertID: string) => {
      if (alertID === 'PA_NAVIGATE_PLAN_SELECTION') {
        this.goToPlanSelection();
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.isCaHmoDmhc = this._appSession.isCaHmoDmhc;
    this.onPreLoad();
    this.setPageHeader();
    this.initialSearchParams = this._appSession.searchParams;
    // check if the user is in public state and service area is validated
    if (!this.isSecureState) {
      if ((this.isCaHmoDmhc && this.isServiceAreaValidated) || !this.isCaHmoDmhc) {
        this.getProviders();
      } else if (this.isCaHmoDmhc && !this.isServiceAreaValidated) {
        this._navigationService.navigateByUrl(AppNavigations.SERVICE_AREA_VALIDATION_PATH);
        return;
      }
    } else {
      this.getProviders();
    }

    this.subscribeSearchClick();
    this.clearFilters();

    if (this._appSession.isEyc || this._appSession.isMedicalCodeSearch) {
      this.showAboutYourSearch = true;
    }
    this.showPdfAndEmail =
      (this.searchService?.currentScenario &&
        (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL ||
          this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL ||
          this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL ||
          this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL)) ||
      this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY ||
      this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY ||
      this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    const pageHeaderWrapper = this._el.nativeElement.querySelector('.page-header-wrapper');
    this._renderer.listen(pageHeaderWrapper, 'click', () => {
      this.showProviderCard = false;
      this.isLocationFlow = false;
      this.isAffiliationFlow = false;
      this.showSearchResultsFilter = false;
    });
    this.showVirtualOptionInMap = !this._appSession.appState?.opsIndicator?.isVirtualOnlySearchFilterEnabled;

    this._primeGroup.primeGroup.subscribe((data: IGroupAffiliations) => {
      if (data?.affiliationList?.length) {
        this.updatePrimeGroupDisclaimer();
      }
    });
  }

  ngOnDestroy() {
    const curNav = this._navigationService.currentRoute;
    this.searchClickSubscription?.unsubscribe();
    this.showProviderCardSubscription?.unsubscribe();
    this.changePlanSaveClickSubscription?.unsubscribe();
    this._appliedFilter?.clearFilters();

    // retain search params if user navigates to service area validation
    if (curNav !== AppNavigations.FCR_SAV_PATH) {
      CommonUtility.clearSearchCriteria(this._appSession);
    } else {
      this._appSession.searchParams = this.initialSearchParams;
    }
  }

  get isServiceAreaValidated(): boolean {
    return this._appSession.serviceAreaValidated;
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
    }
  }

  getProviders(map = false, pageNumber: number = 1) {
    this.onCloseProviderCard();
    if (pageNumber == 1) {
      this._compareProvider.clear();
      this.compareSelectionVisible = false;
    }
    //TODO- FCR disclaimer - SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY is pending(v2 version)
    if (map) {
      this.showLoadProviders = true;
    } else {
      this.showLoading = true;
    }
    let isSearchBlocked = false;
    this.showCptAlert = false;
    this.costRange = '';
    this._pageAlertSvc.getPageAlerts(PageAlertHandler.buildPageAlertRequest(FindCarePageType.Results, this._appSession)).then(() => {
      isSearchBlocked = CommonUtility.isResultSearchBlocked(this._appSession);
      this._appSession.providerTypeCodes = [];
      if (!isSearchBlocked) {
        if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
          this._secureTccDentalSvc.search(pageNumber, 20).then((response) => {
            if (response) {
              this.onGetProviderSuccess(response, pageNumber);
            }
          });
        } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
          this._secureTccMedicalSvc.search(pageNumber, 20).then((response) => {
            if (response) {
              this.onGetProviderSuccess(response, pageNumber);
            }
          });
        } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
          this._publicTccMedicalSvc.search(pageNumber, 20).then((response) => {
            if (response) {
              this.onGetProviderSuccess(response, pageNumber);
              this.costRange = response?.costRange;
            }
          });
        } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL && this.getHasCptSearch()) {
          this.isCptSearch = true;
          this._secureCptMedicalSvc.search(pageNumber, 20).then((response) => {
            this.showCptAlert = response?.isPreventiveCare;
            this.onGetProviderSuccess(response, pageNumber);
            this._appSession.medicalModifierOptionCode = response.modifier?.code;
            this._appSession.medicalPosCode = response.pos?.code;
          });
        } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
          this._secureRxSvc.search(pageNumber, 20).then((response) => {
            if (response) {
              this.onGetProviderSuccess(response, pageNumber);
            }
          });
        } else {
          this._specialtySearchSvc.search(pageNumber, 20).then((response) => {
            if (response) {
              this.onGetProviderSuccess(response, pageNumber);
              if (this.isOfficeVisitCostEnabled) {
                this._providerOfcVisitCostSvc.getProviderCptOfficeVisitCost(this.providers);
              }
            }
          });
        }
      } else {
        this.showLoading = false;
        this.showLoadProviders = false;
      }
    });
  }

  getHasCptSearch(): boolean {
    return CommonUtility.isCptSearch(this._appSession);
  }

  onProviderHover(provider: IProvider) {
    this.providerMap?.toggleProviderInfobox(provider, true);
  }

  onProviderLeave(provider: IProvider) {
    this.providerMap?.toggleProviderInfobox(provider, false);
  }

  onMapSearch(center: IMapLocation) {
    this._appSession.searchParams.coordinates = center;

    this._geocodeApi.getAddressByLatLong(center.latitude, center.longitude).then((address) => {
      if (!address) {
        return;
      }
      const geoLocation: IGeoLocation = {
        isZipcodeSearch: true,
        searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY,
        zipcode: address.zipcode.slice(0, 5),
        stateCd: address.state,
        latitude: center.latitude,
        longitude: center.longitude
      };
      this._searchHeaderService.setSearchLocation(geoLocation);
      this.initializeSortOption = false;
      this._appUtility.scrollToTop();
      this.getProviders(true);
    });
  }

  toggleMap(visible: boolean) {
    this.mapVisible = visible;
  }

  subscribeSearchClick() {
    this.searchClickSubscription = this._actionService?.getSearchClick().subscribe((value) => {
      this.initializeSortOption = value;
      if (value) {
        this._appliedFilter.clearFilters();
      }
      this._appliedFilter.clearInitialFilters();
      this.getProviders();
    });
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(specialty?: ISpecialty, provider?: IProvider, procedureCode?: string) {
    if (procedureCode) {
      this._adobeAnalyticsObj.procedureName = this.procedureName;
    }
    if (specialty && specialty.specialtyCodeName) {
      this._adobeAnalyticsObj.specialtyName = specialty.specialtyCodeName;
    }
    this.viewHealthwiseContentModal.openSidePanel(SliderDirection.RIGHT, specialty, provider, procedureCode, this._adobeAnalyticsObj);
  }

  get procedureName(): string {
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName
    ) {
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
    }
    return '';
  }

  get procedureNameCode(): string {
    return this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
  }

  /**
   * Method to get the selected provider.
   * Getting the selected provider details that needs to be shown in provider details card.
   * For selected provider, calling alert as well
   * @param provider Provider data.
   */
  onProviderSelect(provider: IProvider) {
    if (provider?.providerIdentifier) {
      this._providerDetail.initialize(provider?.providerIdentifier, provider?.addressSummary?.addressIdentifier, provider);
      this.selectedProvider = provider;
      this._appSession.selectedProvider = this.selectedProvider;
      if (provider?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(provider?.costInfo?.copay)) {
        this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
        this._appSession.billingType = BILLING_TYPE.CPT;
        this._appSession.selectedPosCode = this._appSession?.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
        this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
        this._appSession.isSpecialtyOfficeVisitCost = true;
      }
    }
  }

  onShowProviderCard(cardStatus: boolean) {
    this.showProviderCard = cardStatus;
  }

  onCloseProviderCard() {
    this.selectedProvider = null;
    this.showProviderCard = false;
    this.isLocationFlow = false;
    this.isAffiliationFlow = false;
    this.showSearchResultsFilter = false;
    this.showLocationLink = true;
    this.providerCardNavigation = ProviderCardNavigation.PROVIDER;
  }

  provDetailsLoaded(details: IDetailsResponse) {
    this.detailsResponse = details.providerDetail;
  }

  get isEyc() {
    return this._appSession.isEyc;
  }

  openSearchSidePanel(procedureCode?: string, tabName?: CptSearchSlideOutTabs) {
    this.searchSlideOutContentModal.openSidePanel('right', procedureCode, tabName);
  }

  onPageChanged(pageNumber: number) {
    this.showProviderCard = false;
    this.initializeSortOption = false;
    this.getProviders(true, pageNumber);
  }

  onGetProviderSuccess(response: IProviderList, pageNumber?: number) {
    this.apiInfo = response;
    this.hasLocation = response?.hasLocation != undefined ? response.hasLocation : true;
    this.providers = [];
    if (this._appSession.openDetailPopup) {
      const provider = response.providers[0];
      this.providers.push(provider);
      this.allProviders = this.providers;
      this.totalPages = 1;
      this.totalRecords = 1;
      this.onProviderSelect(provider);
      this.onShowProviderCard(true);
      this._appSession.openDetailPopup = false;
    } else {
      this.providers = response.providers;
      this.allProviders = this.providers;
      this.totalPages = response?.totalPages;
      this.totalRecords = response?.totalRecords;
    }
    this.totalRecordsOverall = this.totalRecords;
    this.totalPagesOverall = this.totalPages;
    if (!this.totalRecords) {
      this.showAboutYourSearch = false;
      this.showMemberPlanProgress = false;
    }
    this.ruleList = response?.rules;
    if (this.totalRecords < 20) {
      this.resultCountText = this.content?.result?.pagination?.resultCountLessProviderText;
    } else {
      this.resultCountText = this.content?.result?.pagination?.resultCountText;
    }
    if (pageNumber) {
      this.selectedPageNumber = pageNumber;
    }
    //Initialize Sort Options.
    if ((!pageNumber || pageNumber === 1) && this.initializeSortOption) {
      this._sortOption.init(response.sortOptions, response.defaultSort ?? response.sortBy, this.content?.result?.sortOptions);
    } else {
      const sortOption = this._sortOption.selectedOption?.value || response.sortBy;
      if (sortOption) {
        this._sortOption.setSelectedOption(sortOption);
      }
    }
    this.showVirtualProviders(false);
    this.showLoading = false;
    this.showLoadProviders = false;
    this._appSession.selectedPosCode = response.pos?.code;
    this.setVitalsIndicator(response.providers);
    this._appUtility.scrollToTop();
    this.hasLocation ? this.toggleMap(true) : this.toggleMap(false);
    this.setResultPageAlertMsg(response);

    this._appSession.isPrimeGroupSearch = response?.isPrimeGroupSearchResponse;

    if (this._appSession.isPrimeGroupSearch) {
      if (!this._appSession.primeGroupAffiliations?.affiliationList?.length) {
        this.getPrimeGroupAffiliation();
      } else {
        this.updatePrimeGroupDisclaimer();
      }
    } else {
      PageAlertHandler.removePageAlert([PageAlertID.PA_PRIME_GROUP_SEARCH, PageAlertID.PA_HEALTH_SYSTEM_MSG], this._appSession, FindCarePageType.Results);
    }
  }

  /** Method to set Prime Group Disclaimer */
  updatePrimeGroupDisclaimer() {
    this.showPrimeGroupDisclaimer = this._appSession.isPrimeGroupSearch && !this._appSession.searchParams?.ableToServePcp && !this._appSession.searchParams?.includeHealthSystemSearch;
    PageAlertHandler.handlePrimeGroupSearch(this._appSession, this.showPrimeGroupDisclaimer);
  }

  receiveDataFromChild(data: string) {
    let tabIndex = 0;
    if (this.procedureNameCode) {
      tabIndex = 1;
    } else if (this.getHasCptSearch) {
      tabIndex = 2;
    }
    this.searchSlideOutContentModal.openSidePanel('right', this.procedureNameCode, tabIndex);
  }

  onPrintClick() {
    this.onActions(new Print());
  }

  onEmailClick() {
    this.onActions(new Email());
  }

  onActions(action: ResultActions) {
    if (action.getType === ResultActionType.Print) {
      this.showPageProgress = true;
      this.generatePdf('');
    } else if (action.getType === ResultActionType.Email) {
      setTimeout(() => {
        this.emailModal.show();
      }, 300);
    }
  }

  private generatePdf(emailId: string) {
    if (!CommonUtility.isValidString(emailId)) {
      this.executeOnPrint();
    } else {
      this.executeOnEmail(emailId);
    }
  }

  async executeOnPrint() {
    this.showPageProgress = true;
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY) {
        this._specialtySearchSvc
          .print(this.content.result, true)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
        this._securePharmacySvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    } else {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY) {
        this._specialtySearchSvc
          .print(this.content.result, false)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    }
  }

  onPrintSuccess(printResponse: Buffer) {
    this.downloadPdf(printResponse);
    this.showPageProgress = false;
  }

  onPrintFailure(error) {
    this._appSession.initialSearch = false;
    this.showPageProgress = false;
  }

  private downloadPdf(pdf: any) {
    if (this._appUtility.isWebkitBrowser()) {
      // Download for webkit based browser
      let binary = '';
      const bytes = new Uint8Array(pdf);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64 = 'data:' + 'application/pdf' + ';base64,' + window.btoa(binary);
      const fileURL = encodeURI(base64);
      let windowParams: string;
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
      const winArgs = [fileURL, '_blank'];
      if (windowParams) {
        winArgs.push(windowParams);
      }
      window.open.apply(window, winArgs);
    } else {
      const file = new Blob([pdf], { type: 'application/octet-stream' });
      //Download for other browsers
      const lnkDownload = document.createElement('a');
      lnkDownload.style.display = 'none';
      const fileURL = URL.createObjectURL(file);
      lnkDownload.href = fileURL;
      lnkDownload.download = `${this.pdfFileName}.pdf`;
      document.body.appendChild(lnkDownload);
      lnkDownload.click();
      // To make work with Firefox
      setTimeout(() => {
        document.body.removeChild(lnkDownload);
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  private get pdfFileName(): string {
    const now = new Date(Date.now());
    const date = `${now.getMonth() + 1}${now.getDate()}${now.getFullYear()}`;
    const code = this._appSession.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
    const medical_code = this._appSession.billingType + HYPHEN + this._appSession.medicalCode;

    if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
      const dental_procedure_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.DENTAL_PROCEDURE];
      return this.getPdfFileName(dental_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL || this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
      const medical_procedure_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_PROCEDURE];
      return this.getPdfFileName(medical_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
      const medical_code_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_CODE];
      return this.getPdfFileName(medical_code_content, medical_code, date);
    } else {
      return CommonUtility.getProviderTypeText(this._appSession, date);
    }
  }

  private getPdfFileName(content: string, code: string, date: string): string {
    const search_on = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.SEARCH_ON];
    return content + UNDERSCORE + code + search_on + `${date}`;
  }

  getEmailPopupHeader(): string {
    return this.content.result.searchEmailComponent.emailSearchHeader;
  }

  emailBody(): string {
    return this.content.result.searchEmailComponent.emailSearchNote;
  }

  onSendEmail(emailId: string) {
    this.generatePdf(emailId);
  }

  async executeOnEmail(email: string) {
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY) {
        this._specialtySearchSvc
          .email(email, this.content.result, true)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
        this._securePharmacySvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    } else {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY) {
        this._specialtySearchSvc
          .email(email, this.content.result, false)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    }
  }

  onEmailSuccess() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailSent;
  }

  onEmailFailure() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailFailed;
  }

  onToggleSearchResultFilter() {
    this.showSearchResultsFilter = !this.showSearchResultsFilter;
    if (!this.showSearchResultsFilter && this.isLocationFlow) {
      this.isLocationFlow = false;
    }
    if (!this.showSearchResultsFilter && this.isAffiliationFlow) {
      this._providerDetail.getParentDetail();
    }
    this.providerCardNavigation = ProviderCardNavigation.PROVIDER;
    if (window.innerWidth < MAX_DESK_RESPONSIVE_WIDTH_M) {
      this.selectedProvider = undefined;
      this.showProviderCard = false;
      this.showLocationLink = true;
    }
  }

  onCardNavigation(flowType: ProviderCardNavigation) {
    this.showSearchResultsFilter = true;
    this.isAffiliationFlow = flowType === ProviderCardNavigation.AFFILIATION;
    this.isLocationFlow = !this.isAffiliationFlow;
    this.providerCardNavigation = flowType;
  }

  /**
   * Method to view virtual options.
   */
  showVirtualProviders(visible: boolean) {
    this.closeVirtualOptions = visible;
    this.virtualProviders = this.allProviders?.filter((provider) => provider?.providerFlags?.includes(ProviderFlag.VIRTUAL_PROVIDER));
    if (visible) {
      this.providers = this.virtualProviders;
      this.totalRecords = this.providers?.length;
      this.totalPages = 1;
    } else {
      this.providers = this.allProviders;
      this.totalRecords = this.totalRecordsOverall;
      this.totalPages = this.totalPagesOverall;
    }
    this.onlyVCProvidersAvailable = this.virtualProviders?.length === this.allProviders?.length;
    this.onlyVCProvidersAvailable ? this.toggleMap(false) : this.toggleMap(!visible);
  }

  showViewMapOption() {
    return !this.mapVisible && !this.showSearchResultsFilter && !this.closeVirtualOptions && !this.onlyVCProvidersAvailable && this.hasLocation;
  }

  setVitalsIndicator(providers: IProvider[]) {
    this.isVitalsRequired = providers?.some((provider) => !isEmpty(provider?.pdtKey));
  }

  // Handle the Alert message on AddCare
  showAddCareAlert(disclaimerCodes) {
    this._infoListSvc.setInfoList(disclaimerCodes);
  }

  onSelectProviderFromMap(provider: IProvider) {
    this.selectedPushpinProvider = provider;
  }

  updateSortCriteria(data: string) {
    this._appSession.searchParams.selectedSortCriteria = data;
    this.initializeSortOption = false;
    this.getProviders();
  }

  isPrintPdfEnabled(): boolean {
    return this.hasFeature(FindCareFeature.FC_EXPORT_PDF);
  }

  isEmailPdfEnabled(): boolean {
    return this.hasFeature(FindCareFeature.FC_EXPORT_EMAIL);
  }

  /** Function to execute on click of individual close button */
  onProviderRemoved(provider: IProvider): void {
    this.providerRemoved = provider;
  }

  showCompareProviders(data) {
    this.compareSelectionVisible = data;
  }

  /** Function to execute on click of cancel button */
  clearAllSelectedProviders() {
    this.clearAllProviders = !this.clearAllProviders;
  }

  clearFilters(): void {
    this._appliedFilter.clearFilters();
    this._appSession.initialSearch = true;
    if (this._appSession.filterSearchParams) {
      this._appSession.searchParams = this._appSession.filterSearchParams;
    }
  }

  setLocations(locations: IProviderLocationAddress[]): void {
    this.locations = locations;
  }

  getResultListContainerClasses() {
    return {
      'fcr-results-list-container': true,
      'show-map-view': this.mapVisible,
      'show-card-view': !this.mapVisible && this.showProviderCard,
      'list-full-width': !this.mapVisible && !this.showProviderCard,
      'show-affiliation-map-view': this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isAffiliationFlow,
      'show-affiliation-card-view': !this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isAffiliationFlow,
      'show-location-map-view': this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isLocationFlow,
      'show-location-card-view': !this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isLocationFlow
    };
  }

  getResultRightContainerClasses() {
    return {
      'fcr-results-right-container': true,
      'show-map-view': this.mapVisible,
      'show-card-view': !this.mapVisible && this.showProviderCard,
      'single-page-map-ht': this.mapVisible && this.totalPages === 1,
      'single-page-card-ht': !this.mapVisible && this.showProviderCard && this.totalPages === 1,
      'list-full-width': !this.mapVisible && !this.showProviderCard,
      'hide-it': !this.mapVisible && !this.showProviderCard,
      'show-affiliation-map-view': this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isAffiliationFlow,
      'show-affiliation-card-view': !this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isAffiliationFlow,
      'show-location-map-view': this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isLocationFlow,
      'show-location-card-view': !this.mapVisible && this.showProviderCard && this.showSearchResultsFilter && this.isLocationFlow,
      'fcr-container-spacing-right': !this.mapVisible,
      'fcr-container-spacing-left': !this.mapVisible && window.innerWidth < MAX_DESK_RESPONSIVE_WIDTH_M
    };
  }

  setResultPageAlertMsg(response: IProviderList) {
    const props: IResultPageAlertProps = {
      response,
      providers: this.providers,
      appSession: this._appSession,
      currentScenario: this.searchService.currentScenario,
      isCptSearch: this.isCptSearch,
      isColdStateEYC: this.isColdStateEYC,
      isOONSearch: this.isOONSearch,
      showCptAlert: this.showCptAlert,
      isEyc: this.isEyc,
      isOfficeVisitCostEnabled: this.isOfficeVisitCostEnabled,
      isMedicalGroupSearch: this.showPrimeGroupDisclaimer
    };

    PageAlertHandler.handlePageAlerts(props);
  }

  goToPlanSelection() {
    if (this._appSession?.deeplinkParams?.hcid) {
      this._appSession.deeplinkParams = {} as IDeeplink;
    }
    this._appSession.isChangePlan = true;
    if (!CommonUtil.isMemberSecure(this._appSession) && this._appSession?.searchParams) {
      this._appSession.searchParams.zip = '';
      this._appSession.searchParams.stateCd = '';
      this._appSession.searchParams.city = '';
      //To Be Cleared only on Cold State Change Plan
      this._appSession.planRule = {} as IPlanRule;
      this._appSession.metaData.appContract = {} as IAppContract;
    }
    this._navigationService.navigateByUrl('fc-home');
  }

  async getPrimeGroupAffiliation(): Promise<void> {
    const affiliationRequest: ISearchRequest | undefined = AffiliationUtility.getAffiliationRequest(this._appSession, PROVIDER_TYPE.MEDICAL_GROUP);
    if (!affiliationRequest) {
      return;
    }
    this._primeGroup.initialize(affiliationRequest);
  }
}
