import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../common/constants/app-constants';
import { SELECT_DEFAULT_ZERO } from '../../common/constants/strategy-search-constant';
import { ICommonSearchRequest, IGeoLocationInRequest, IProviderMiscFilterInRequest, IRecognitionRequest, ISpecialtyDemographicFilter } from '../../common/interfaces/iCommonSearchRequest';
import { ICptMedicalRequest, IMedicalCodeCriteria } from '../../common/interfaces/iCptMedicalRequest';
import { IEycProcedureCriteria, ISecureDentalRequest, ISecureDentalSearchCriteria } from '../../common/interfaces/iDentalProcedureSearchRequest';
import { IPlanNetworkPrefixContent } from '../../common/interfaces/iPlanNetworkPrefixContent';
import { IPublicSpecialtyDemographicFilter, IPublicSpecialtySearchCriteria, IPublicSpecialtySearchRequest } from '../../common/interfaces/iPublicSpecialtySearchRequest';
import { IRxFilter, ISecurePharmacyDemographicFilter, ISecurePharmacyRequest, ISecurePharmacySearchCriteria } from '../../common/interfaces/iSecurePharmacySearchRequest';
import { IPlanRequest, ISecureSpecialtySearchCriteria, ISecureSpecialtySearchRequest } from '../../common/interfaces/iSecureSpecialtySearchRequest';
import { AppSession } from '../../common/values/appSession';
import { IOutputFlags } from '../provider-details/models/iDetailsParameters';
import { ISearchParameters } from '../search-providers/interfaces/iSearchParameters';
import { EYC_RETURN_PROVIDER_TYPE, OUT_OF_NETWORK, PROVIDER_TYPE } from '../search-providers/values/providerSearchConstants';
import { IBlueDistinctCode, IPlan } from '../search-results/interfaces/iSearchRequest';
import { API_NAME, providerOutputFlags } from '../search-results/values/providerSearchConstants';
import { apiNameList } from './../../../environments/api-name-list';
import { IMemberCriteriaInRequest } from './../../common/interfaces/iCommonSearchRequest';
import { ICptSearchCriteria } from './../../common/interfaces/iCptMedicalRequest';
import { CommonUtil } from './commonUtil';
import { ResultsUtil } from './resultsUtil';

@Injectable({
  providedIn: 'root'
})
export class SearchRequestUtil {
  static checkOutputFlags(appSession: AppSession): IOutputFlags {
    let outputFlags: IOutputFlags = {
      includeTaxonomy: true,
      includeVisibilityRules: providerOutputFlags.visibilityRulesFlag,
      improveSearchCriteria: providerOutputFlags.improveSearchCriteria,
      includeDisclaimerRules: providerOutputFlags.includeDisclaimerRules
    };
    if (!CommonUtil.isCptSearch(appSession)) {
      outputFlags.includeReviewRatings = providerOutputFlags.reviewRatingsFlag;
      outputFlags.imposeAddressConsolidation = providerOutputFlags.imposeAddressConsolidation;
      outputFlags.includeSpfInfo = providerOutputFlags.includeSpfInfo;
      outputFlags.includeProviderNetworks = providerOutputFlags.includeProviderNetworks;
      outputFlags.includePrimeGroupSearch = !appSession.isEyc && appSession.searchParams ? appSession.searchParams?.includePrimeGroupSearch : false;
      outputFlags.includeHealthSystemSearch = !appSession.isEyc && appSession.searchParams ? appSession.searchParams?.includeHealthSystemSearch : false;
    }

    if (!appSession.isEyc) {
      outputFlags.includeAllNetworks = (appSession.hasStandaloneDentalContract || appSession.hasStandaloneVisionContract) && appSession.hasMedicalContracts;
    }

    return outputFlags;
  }

  static getSpecialSpecialties(appSession: AppSession, specialtyList: string[]) {
    let _specialtyList = specialtyList;

    if (specialtyList && specialtyList.length === 0) {
      const _provType = appSession.searchParams.providerTypeCodeList && appSession.searchParams.providerTypeCodeList.length === 1 ? appSession.searchParams.providerTypeCodeList[0] : '';
      if (_provType === PROVIDER_TYPE.HEARING) {
        _specialtyList = this.getSpecialtyCodeList(appSession, specialtyList);
      }
    }

    return _specialtyList.filter((x) => !isEmpty(x));
  }

  static getSpecialtyCodeList(appSession: AppSession, specialtyList: string[]) {
    if (appSession.searchParams.speicalityDdlValues && appSession.searchParams.speicalityDdlValues.options) {
      specialtyList = appSession.searchParams.speicalityDdlValues.options.map((x) => x.value);
      specialtyList = specialtyList && specialtyList.length > 0 ? specialtyList.filter((x) => x !== SELECT_DEFAULT_ZERO) : [];
    }
    return specialtyList;
  }

  static buildSecureSpecialtyRequest(appSession: AppSession, pageNumber: number, pageSize: number): ISecureSpecialtySearchRequest {
    const searchParams = appSession.searchParams;
    const commonSearchRequest = this.buildCommonSearchRequest(appSession, pageNumber, pageSize);
    const demographicFilter = this.buildDemographicFilterRequest(appSession);
    const miscFilter = this.buildMiscFilterRequest(searchParams);
    const searchCriteria: ISecureSpecialtySearchCriteria = {
      miscFilter,
      demographicFilter,
      brandCode: appSession.metaData.brandCd,
      isInitialSearch: appSession?.initialSearch
    };
    const request: ISecureSpecialtySearchRequest = {
      ...commonSearchRequest,
      searchCriteria
    };
    return request;
  }

  static buildDentalProcedureRequest(appSession: AppSession, pageNumber: number, pageSize: number): ISecureDentalRequest {
    const searchParams = appSession.searchParams;
    const commonSearchRequest = this.buildCommonSearchRequest(appSession, pageNumber, pageSize);
    if (appSession.isSecureState) {
      commonSearchRequest.memberCriteria.memberSeqNum = appSession.metaData.appContract?.memberSeqNum;
    }
    const eycProcedureCriteria: IEycProcedureCriteria = {
      procedureCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode
    };
    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const searchCriteria: ISecureDentalSearchCriteria = {
      demographicFilter,
      miscFilter,
      brandCode: appSession.metaData.brandCd,
      cobrandCode: this.setCobrandCode(appSession),
      isInitialSearch: appSession?.initialSearch,
      returnTypeofProvider: EYC_RETURN_PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
      isPrefixStateEnabledForProcSearch: searchParams?.isPrefixStateEnabledForProcSearch
    };
    const request: ISecureDentalRequest = {
      ...commonSearchRequest,
      eycProcedureCriteria,
      searchCriteria
    };
    return request;
  }

  static buildSecurePharmacyRequest(appSession: AppSession, pageNumber: number, pageSize: number): ISecurePharmacyRequest {
    const searchParams = appSession.searchParams;
    const commonSearchRequest = this.buildCommonSearchRequest(appSession, pageNumber, pageSize);
    const demographicFilter: ISecurePharmacyDemographicFilter = {
      coverageTypeCode: searchParams?.coverageTypeCode
    };
    if (searchParams.languages && !searchParams.languages.includes(SELECT_DEFAULT_ZERO)) {
      demographicFilter.languageCodes = searchParams.languages;
    }
    if (searchParams.provNm || appSession.isMedicalAffSearch || appSession.isHospAffSearch) {
      demographicFilter.providerName = appSession.isMedicalAffSearch ? appSession.medicalGrpSearchTerm : appSession.isHospAffSearch ? appSession.hospSearchTerm : searchParams.provNm;
    } else {
      if (!appSession.isSpecialitySearch && !searchParams.nationalProviderIdentifier && !appSession.isEyc && !appSession.isAoeSearch && !appSession.isOfcSrvcSearch && !appSession.isSearchTerm) {
        demographicFilter.providerName = appSession.searchTerm;
      }
    }
    demographicFilter.specialtyCategoryCodes =
      searchParams.specialtySelectNm && searchParams.specialtySelectNm.length > 0 ? searchParams.specialtySelectNm.filter((x) => x !== SELECT_DEFAULT_ZERO) : [];
    demographicFilter.specialtyCategoryCodes = this.getSpecialSpecialties(appSession, demographicFilter.specialtyCategoryCodes);

    demographicFilter.typeCodes = searchParams.providerTypeCodeList?.length ? searchParams.providerTypeCodeList : searchParams.typeSelectNm ? [searchParams.typeSelectNm] : [];
    const rxFilter = this.buildRXFilterRequest(searchParams);

    const searchCriteria: ISecurePharmacySearchCriteria = {
      brandCode: appSession.metaData.brandCd,
      isInitialSearch: appSession?.initialSearch,
      demographicFilter,
      rxFilter
    };
    const request: ISecurePharmacyRequest = {
      ...commonSearchRequest,
      searchCriteria
    };
    return request;
  }

  static buildPublicSpecialtyRequest(appSession: AppSession, pageNumber: number, pageSize: number): IPublicSpecialtySearchRequest {
    const searchParams = appSession.searchParams;
    const commonSearchRequest = this.buildCommonSearchRequest(appSession, pageNumber, pageSize);
    let demographicFilter: IPublicSpecialtyDemographicFilter = {};
    demographicFilter = this.buildDemographicFilterRequest(appSession);
    demographicFilter.coverageTypeCode = searchParams.coverageTypeCode;
    const miscFilter = this.buildMiscFilterRequest(searchParams);
    const rxFilter = this.buildRXFilterRequest(searchParams);

    const searchCriteria: IPublicSpecialtySearchCriteria = {
      miscFilter,
      demographicFilter,
      rxFilter,
      brandCode: appSession.metaData.brandCd,
      isInitialSearch: appSession?.initialSearch
    };
    const request: IPublicSpecialtySearchRequest = {
      ...commonSearchRequest,
      searchCriteria
    };
    return request;
  }

  static buildCommonSearchRequest(appSession: AppSession, pageNumber: number, pageSize: number) {
    const memberCriteria: IMemberCriteriaInRequest = {};
    if (!appSession.searchParams) {
      throw new Error('searchParams in appSession is mandatory');
    }
    const searchParams = appSession.searchParams;
    const plan: IPlanRequest = {
      category: searchParams.plan?.category?.catCode,
      stateCode: searchParams.plan?.stateCd,
      types: searchParams.planTypeList,
      plans: []
    };
    const planIdentifierList = [];
    const planIdentifier: IPlan = {
      identifier: searchParams.plan?.identifier?.trim(),
      isNationalPlan: searchParams.plan?.isNationalPlan
    };

    if (!Object.values(planIdentifier).every((value) => value === null || value === '' || value === undefined)) {
      planIdentifierList.push(planIdentifier);
    }
    plan.plans = planIdentifierList;
    const location: IGeoLocationInRequest = {
      distance: searchParams.distanceNm,
      latitude: searchParams.coordinates?.latitude.toString(),
      longitude: searchParams.coordinates?.longitude.toString(),
      customAddressSearch: appSession?.customAddressSearch
    };
    if (appSession.addressObj?.customAddress?.streetAddress) {
      location.addressOne = appSession.addressObj?.customAddress?.streetAddress;
    }
    if (appSession.addressObj?.customAddress?.addressLine2) {
      location.addressTwo = appSession.addressObj?.customAddress?.addressLine2;
    }
    if (searchParams.city) {
      location.city = searchParams.countyCode ? '' : searchParams.city;
    }
    if (searchParams.countyCode) {
      location.countyCode = searchParams.countyCode;
    }
    if (searchParams.stateCd?.code) {
      location.state = searchParams.stateCd.code;
    }
    if (searchParams.zip) {
      location.postalCode = isNaN(Number(searchParams.zip.trim())) ? '' : searchParams.zip.trim();
    }
    if (CommonUtil.isMemberSecure(appSession)) {
      memberCriteria.mbrUid = appSession.metaData.appContract?.mbrUid;
      memberCriteria.contractUid = appSession.metaData.appContract?.contractUid ?? appSession.metaData.contractUid;
      memberCriteria.coverageUid = appSession.coverageUid;
    } else {
      if (searchParams.identificationNumber?.length) {
        memberCriteria.identificationNumber = searchParams.identificationNumber;
      }
    }

    const request: ICommonSearchRequest = {
      alphaPrefix: searchParams.plan?.alphaPrefix ?? undefined,
      isFuturePlan: appSession?.isFutureCoverage?.toString(),
      locale: appSession?.metaData?.locale?.toString() || '',
      location,
      memberCriteria,
      networks: searchParams.plan?.networkList ?? undefined,
      outputFlags: this.checkOutputFlags(appSession),
      pageNumber: pageNumber,
      pageSize: pageSize,
      plan,
      sortBy: searchParams.selectedSortCriteria ?? undefined
    };
    if (appSession.hasCostTransparency) {
      const medicalCodeCriteria = {} as IMedicalCodeCriteria;
      medicalCodeCriteria.code = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
      medicalCodeCriteria.billingType = BILLING_TYPE.CPT;
      request.medicalCodeCriteria = medicalCodeCriteria;
    }
    return request;
  }

  static buildDemographicFilterRequest(appSession: AppSession): ISpecialtyDemographicFilter {
    const searchParams = appSession.searchParams;
    const demographicFilter: ISpecialtyDemographicFilter = {};
    demographicFilter.anp = searchParams?.acceptingNewPatients;
    demographicFilter.ableToServeAsPcp = searchParams?.ableToServePcp;
    const filteredAoe = searchParams?.aoeSelectNm?.filter((x) => x !== SELECT_DEFAULT_ZERO);
    if (filteredAoe) {
      demographicFilter.aoeCodes = filteredAoe;
    }
    const filteredAda = searchParams?.adaSelectNm?.filter((x) => x !== SELECT_DEFAULT_ZERO);
    if (filteredAda) {
      demographicFilter.adaAccessibilityList = filteredAda;
    }
    demographicFilter.boardCertification = searchParams?.boardCertification;
    if (searchParams.ethnicityList && !searchParams.ethnicityList.includes(SELECT_DEFAULT_ZERO)) {
      demographicFilter.ethnicityCodes = searchParams.ethnicityList;
    }
    demographicFilter.genderCodes = searchParams?.genderCodeList;
    if (searchParams.hospitalAffiliationSelectNm && searchParams.hospitalAffiliationSelectNm !== SELECT_DEFAULT_ZERO) {
      demographicFilter.hospitalAffiliation = searchParams.hospitalAffiliationSelectNm;
    }
    if (searchParams.languages && !searchParams.languages.includes(SELECT_DEFAULT_ZERO)) {
      demographicFilter.languageCodes = searchParams.languages;
    }
    demographicFilter.levelOfCareCodes = searchParams?.levelOfCareList;
    if (searchParams.groupAffiliationSelectNm && searchParams.groupAffiliationSelectNm !== SELECT_DEFAULT_ZERO) {
      demographicFilter.physicianAssociationAffiliation = searchParams.groupAffiliationSelectNm;
    }
    if (searchParams.provNm || appSession.isMedicalAffSearch || appSession.isHospAffSearch) {
      demographicFilter.providerName = appSession.isMedicalAffSearch ? appSession.medicalGrpSearchTerm : appSession.isHospAffSearch ? appSession.hospSearchTerm : searchParams.provNm;
    } else {
      if (!appSession.isSpecialitySearch && !searchParams.nationalProviderIdentifier && !appSession.isEyc && !appSession.isAoeSearch && !appSession.isOfcSrvcSearch && !appSession.isSearchTerm) {
        demographicFilter.providerName = appSession.searchTerm;
      }
    }
    demographicFilter.specialtyCategoryCodes =
      searchParams.specialtySelectNm && searchParams.specialtySelectNm.length > 0 ? searchParams.specialtySelectNm.filter((x) => x !== SELECT_DEFAULT_ZERO) : [];
    demographicFilter.specialtyCategoryCodes = this.getSpecialSpecialties(appSession, demographicFilter.specialtyCategoryCodes);
    demographicFilter.taxonomyCodes =
      searchParams.typeSelectNm === PROVIDER_TYPE.HEARING ? CommonUtil.getSubSpecialtyList(searchParams.subSpeicalityDdlValues, searchParams.specialtySelectNm) : searchParams?.taxonomySelectNm;
    demographicFilter.telehealth = searchParams?.teleHealthInd;
    demographicFilter.typeCodes = searchParams.providerTypeCodeList?.length ? searchParams.providerTypeCodeList : searchParams.typeSelectNm ? [searchParams.typeSelectNm] : [];
    demographicFilter.typeCodes = demographicFilter.typeCodes?.filter((item) => item !== '');
    demographicFilter.visionSvcCodes = searchParams?.visionSrvcAvailableList;
    demographicFilter.scheduleAptmntAvailable = searchParams?.scheduleAptmntAvailable;
    demographicFilter.includeVirtualProviders = (appSession.hasVirtualUrgentCare && appSession.feature?.customizedFeatures?.showKHealthFeatures) || false;
    demographicFilter.virtualOnly = searchParams?.virtualOnly;
    const selectedOfficeServiceName = searchParams?.ofcSrvcSelectNm?.filter((x) => x !== SELECT_DEFAULT_ZERO);
    if (selectedOfficeServiceName) {
      demographicFilter.ofcSrvcCodes = selectedOfficeServiceName;
    }
    return demographicFilter;
  }

  static buildMiscFilterRequest(searchParams: ISearchParameters): IProviderMiscFilterInRequest {
    const miscFilter: IProviderMiscFilterInRequest = {};
    const selectedPatientPreference = searchParams?.patientPreferenceSelectNm?.filter((x) => x !== SELECT_DEFAULT_ZERO) ?? [];
    if (selectedPatientPreference) {
      miscFilter.patientPopulationList = selectedPatientPreference;
    }
    miscFilter.extendedHoursOnly = searchParams.extendedHoursOnly;
    miscFilter.medicaid = searchParams?.medicaidInd;
    miscFilter.smartSearchRequestId = searchParams?.smartSearchRequestId ?? undefined;
    const recognition: IRecognitionRequest = {
      blueCarePrimePreferredSpecialistOnly: searchParams?.blueCarePrimePreferredSpecialistOnly,
      bluePrecision: searchParams?.bluePrecision,
      patientCenteredCare: searchParams?.enhancedPersonalCare,
      centerOfExcellenceOnly: searchParams.centerOfExcellence,
      cmeDesignationList: searchParams.cmeValue,
      sosOnly: searchParams?.sosOnly,
      cooperativeCareOnly: searchParams?.cooperativeCareOnly,
      ecpProvider: searchParams?.ecpprovider,
      hasCcare: searchParams?.hasCcare,
      mspOnly: searchParams.msp,
      nyOASASOnly: searchParams?.nyOASASOnly,
      nyOMHOnly: searchParams?.nyOMHOnly,
      providerOfDistinctionOnly: searchParams.providerOfDistinction,
      providerTier: searchParams?.providerTier,
      upswingOnly: searchParams?.upswingOnly,
      snyDentalOnly: searchParams?.snyDentalOnly,
      valueBasedProviderOnly: searchParams.valueBasedProvider,
      greenRibbonOnly: searchParams.greenRibbonOnly,
      lwrCpyPcpRcgn: searchParams?.lowerCopayPCP ?? undefined,
      lgbtqProviderOnly: searchParams?.lgbtqProviderOnly
    };
    if (searchParams.blueDistinct) {
      recognition.blueDistinctionCodes = [];
      const bdc: IBlueDistinctCode = {
        code: searchParams.blueDistinct
      };
      recognition.blueDistinctionCodes.push(bdc);
    }
    if (searchParams.blueDistinctionCenter) {
      recognition.blueDistinctionCodes = recognition.blueDistinctionCodes ?? [];
      searchParams.blueDistinctionCenter.forEach((blueDistinctionCenter) => {
        if (Object.keys(blueDistinctionCenter).length) {
          const bdc: IBlueDistinctCode = {
            code: blueDistinctionCenter.code
          };
          if (blueDistinctionCenter.bd && !blueDistinctionCenter.bdcPlus) {
            bdc.name = blueDistinctionCenter.bd;
          } else if (!blueDistinctionCenter.bd && blueDistinctionCenter.bdcPlus) {
            bdc.name = blueDistinctionCenter.bdcPlus;
          }
          recognition.blueDistinctionCodes.push(bdc);
        }
      });
    }
    miscFilter.recognition = recognition;
    if (searchParams && Array.isArray(searchParams.recognitionCodes) && searchParams.recognitionCodes.length) {
      miscFilter.recognitionV2 = { recognitionCodes: searchParams.recognitionCodes };
    }
    return miscFilter;
  }

  static buildRXFilterRequest(searchParams: ISearchParameters): IRxFilter {
    const rxFilter: IRxFilter = {
      allPharmacyFeatures: searchParams?.allPharmacyFeatures?.toString(),
      bloodPressureScreening: searchParams.bloodPressureScreening,
      dispenseTypeCode: searchParams.dispenseTypeCode,
      dme: searchParams.dme,
      driveThru: searchParams.driveThru,
      languageCodes: searchParams.languages,
      onsiteMedicalClinic: searchParams.onsiteMedicalClinic,
      open24HoursADay: searchParams.open24HoursADay,
      open7DaysAWeek: searchParams.open7DaysAWeek,
      pharmacyLevel: searchParams.pharmacyLevel,
      prescriptionDeliveryService: searchParams.prescriptionDeliveryService,
      preferredPharmacy: searchParams.preferredPharmacy,
      preparesCompounds: searchParams.preparesCompounds,
      providesFluShots: searchParams.providesFluShots,
      homeDelivery: searchParams.homeDelivery,
      rxM90Pharmacy: searchParams.rxM90Pharmacy,
      vaccinationIndicator: searchParams.vaccinationIndicator,
      ninetyDayRetail: searchParams.ninetyDayRetail,
      pharmacyFeatures: searchParams.pharmacyFeatures
    };
    return rxFilter;
  }

  static buildSecureCptRequest(appSession: AppSession, pageNumber: number, pageSize: number): ICptMedicalRequest {
    const memberCriteria: IMemberCriteriaInRequest = {};
    if (!appSession.searchParams) {
      throw new Error('searchParams in appSession is mandatory');
    }
    const searchParams = appSession.searchParams;

    const location: IGeoLocationInRequest = {
      distance: searchParams.distanceNm,
      latitude: searchParams.coordinates?.latitude.toString(),
      longitude: searchParams.coordinates?.longitude.toString()
    };
    if (searchParams.city) {
      location.city = searchParams.countyCode ? '' : searchParams.city;
    }
    if (searchParams.countyCode) {
      location.countyCode = searchParams.countyCode;
    }
    if (searchParams.stateCd?.code) {
      location.state = searchParams.stateCd.code;
    }
    if (searchParams.zip) {
      location.postalCode = isNaN(Number(searchParams.zip.trim())) ? '' : searchParams.zip.trim();
    }
    memberCriteria.mbrUid = appSession.metaData.appContract?.mbrUid;
    memberCriteria.contractUid = appSession.metaData.appContract?.contractUid;
    const medicalCodeCriteria = {} as IMedicalCodeCriteria;
    if (appSession.medicalCode) {
      medicalCodeCriteria.code = appSession.medicalCode;
    }

    medicalCodeCriteria.modifierCd = !isEmpty(appSession.medicalModifierOptionCode) ? appSession.medicalModifierOptionCode : undefined;
    medicalCodeCriteria.posCd = !isEmpty(appSession.medicalPosCode) ? appSession.medicalPosCode : undefined;

    if (appSession.billingType) {
      medicalCodeCriteria.billingType = appSession.billingType;
    }

    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const searchCriteria = {
      brandCode: appSession?.metaData?.brandCd,
      cobrandCode: this.setCobrandCode(appSession),
      isInitialSearch: appSession?.initialSearch,
      demographicFilter: demographicFilter,
      miscFilter: miscFilter,
      alphaPrefix: appSession.searchParams.plan?.alphaPrefix
    } as ICptSearchCriteria;

    const searchRequest: ICptMedicalRequest = {
      locale: appSession?.metaData?.locale?.toString() || Locale.ENGLISH,
      location,
      memberCriteria,
      networks: searchParams.plan?.networkList ?? undefined,
      outputFlags: this.checkOutputFlags(appSession),
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortBy: searchParams.selectedSortCriteria ?? undefined,
      medicalCodeCriteria: medicalCodeCriteria,
      searchCriteria: searchCriteria
    };
    return searchRequest;
  }

  static getProductName<T extends { summaryComponent: IPlanNetworkPrefixContent }>(appSession: AppSession, content: T): string {
    if (appSession?.searchParams?.identificationNumber) {
      const planNameForHcidSearch = ResultsUtil.getPlanNetworkPrefixText(appSession, content);
      return planNameForHcidSearch || '';
    } else {
      return appSession?.filterChangePlan?.planType || '';
    }
  }

  static getApiName(apiName: API_NAME): string {
    switch (apiName) {
      case API_NAME.TCC_MEDICAL:
        return apiNameList.restApi.secureV1TccMedical;
      case API_NAME.TCC_DENTAL:
        return apiNameList.restApi.secureV1TccDental;
      case API_NAME.SECURE_SPECIALTY:
        return apiNameList.restApi.secureV1Specialty;
      case API_NAME.SECURE_RX_SPECIALTY:
        return apiNameList.restApi.secureV1RxSpecialty;
      case API_NAME.PUBLIC_SPECIALTY:
        return apiNameList.restApi.publicV1Specialty;
      case API_NAME.PUBLIC_TCC_MEDICAL:
        return apiNameList.restApi.publicV1TccMedical;
      case API_NAME.SECURE_CPT_MEDICAL:
        return apiNameList.restApi.secureV1CptMedical;
      case API_NAME.SECURE_SPECIALTY_V2:
        return apiNameList.restApi.secureSpecialtyV2;
      case API_NAME.PUBLIC_SPECIALITY_V2:
        return apiNameList.restApi.publicSpecialtyV2;
      default:
        return null;
    }
  }

  static setCobrandCode(appSession: AppSession): string {
    return appSession?.planRule?.cobrandRule?.object?.cobrand;
  }

  /**
   * Checks if the current session search is out of network.
   * This method inspects the `planTypeList` within the `searchParams` of the provided `appSession`.
   * It returns `true` if the list includes the `OUT_OF_NETWORK` plan type, otherwise `false`.
   * @param {AppSession} appSession - The current application session containing search parameters.
   * @returns {boolean} - `true` if the session is out of network, otherwise `false`.
   */
  static isOutOfNetwork(appSession: AppSession): boolean {
    return appSession?.searchParams?.planTypeList?.includes(OUT_OF_NETWORK) ?? false;
  }
}
